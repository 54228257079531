import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-factory-tag',
  templateUrl: './factory-tag.component.html',
  styleUrls: ['./factory-tag.component.css']
})
export class FactoryTagComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
