import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxLoadingModule } from 'ngx-loading';
import { SharedModule } from 'app/shared/shared.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { InventoriesReportComponent } from 'app/inventories/inventories-report/inventories-report.component';
import { InventoryIncheckComponent } from 'app/inventories/inventory-incheck/inventory-incheck.component';
import { FormulaPieChartTemplateComponent } from './formula-pie-chart-template/formula-pie-chart-template.component';

// const routes = [

//   {
//     path: 'reports',
//     component: InventoriesReportComponent
//   },
//   {
//     path: 'incheck/:id',
//     component: InventoryIncheckComponent
//   },
//   {
//     path: 'incheck',
//     component: InventoryIncheckComponent
//   }
// ]

@NgModule({
  declarations: [

    // InventoriesReportComponent,
    // InventoryIncheckComponent
    FormulaPieChartTemplateComponent
  ],
  imports: [
    CommonModule,
    // RouterModule.forChild(routes),
    TranslateModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    NgxLoadingModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    NgxChartsModule,
    SharedModule,
    MatTabsModule,
    MatMenuModule,
    MatSortModule,
    MatPaginatorModule,
    MatTableModule,
    MatSlideToggleModule,
    MatCardModule,
    MatGridListModule
  ],
  exports:[
    FormulaPieChartTemplateComponent
  ]

})
export class FormulaPieChartModule { }
