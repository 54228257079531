<div class="flex flex-col flex-auto min-w-0">
    <div class="flex-auto p-2 sm:p-10">

        <div class="content mt-4">
            <mat-tab-group mat-stretch-tabs class="mt-1"     (selectedTabChange)="onTabChanged($event.index)">
                <mat-tab label="Scivera ZDHC MRSL Level 1 Products Registry">
                    <!-- <div class="embed-container">
                        <iframe
                        id ="iframe"
                            [src]="sanitizedURL"
                            frameborder="0" 
                            allowfullscreen="" 
                            webkitallowfullscreen="true" 
                            mozallowfullscreen="true" 
                            oallowfullscreen="true" 
                            msallowfullscreen="true"
                        ></iframe>
                    </div> -->
                </mat-tab>
                <mat-tab label="Scivera Screened Chemistry Products Registry">
                    <!-- <div class="embed-container">
                        <iframe
                            [src]="sanitizedURL1"
                            frameborder="0" 
                            allowfullscreen="" 
                            webkitallowfullscreen="true" 
                            mozallowfullscreen="true" 
                            oallowfullscreen="true" 
                            msallowfullscreen="true"
                        ></iframe>
                    </div> -->
                </mat-tab>
            </mat-tab-group>

        </div>
    </div>