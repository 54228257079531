import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { GlobalsService } from 'app/globals-services/globals.service';

@Component({
  selector: 'app-scrivera',
  templateUrl: './scrivera.component.html',
  styleUrls: ['./scrivera.component.scss']
})
export class ScriveraComponent implements OnInit {

  iframeURL: string;
  sanitizedURL: SafeUrl;
  iframeURL1: string;
  sanitizedURL1: SafeUrl;
  links = ['https://www.enhesa.com/sustainable-chemistry/screened-chemistry-certification/', 'https://www.enhesa.com/sustainable-chemistry/screened-chemistry-certification/']
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.iframeURL = "https://www.scivera.com/certifications/zdhc-mrsl-conformance/";
    this.sanitizedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeURL);

    this.iframeURL1 = "https://www.scivera.com/certifications/screened-chemistry-certification/";
    this.sanitizedURL1 = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeURL1);
    
    setTimeout(()=>{ window.open(this.links[0], "_blank") }, 3000)
  }

  onTabChanged(index) {
    window.open(this.links[index], "_blank")
  }

}
