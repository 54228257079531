import { Component, Inject, OnInit, Directive, ElementRef, Output, HostListener, ChangeDetectorRef } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import { GlobalsService } from '../../globals-services/globals.service';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';


export interface MasterDataFormula {
  name?: string;
  masterID?: string;
  msdsIdentifier?: string;
  manufacturer?: string;
  lotNumber?: string;
  usedForCustomer?: string;
  stockVolume?: string;
  stockLocation?: string;
  quantity?: string;
  purchaseDate?: string;
  manufactureDate?: string;
  expiryDate?: string;
}

export interface FormulaDisplay {
  display?: string;
  name?: string;
  manufacturer?: string;
  masterID?: string;
  msdsIdentifier?: string;
}


@Component({
  selector: 'app-add-inventory-scan',
  templateUrl: './add-inventory-scan.component.html',
  styleUrls: ['./add-inventory-scan.component.css']
})
export class AddInventoryScanComponent implements OnInit {
  form: FormGroup;
  formArray: FormArray;
  activatedRouteSubscription: any;
  activatedRoute: any;
  creatorID: any;
  dataSourceFormula: any;
  paginator: any;
  choicesSource = [];
  locations = [];
  customers = [];
  inputData = [];
  formulaNames = [];
  displayedColumns  = ['name', 'manufacturer', 'stockVolume', 'consumption', 'stockLocation', 'lotNumber', 'usedForCustomer', 'purchaseDate', 'manufactureDate','expiryDate'];
  myDate = new Date();
  myFormulaControl = new FormControl();
  myLocationControl = new FormControl();
  myCustomerControl = new FormControl();
  myPurchaseDateControl = new FormControl();
  myManufactureDateControl = new FormControl();
  myExpiryDateControl = new FormControl();
  filteredDisplayArray = [];
  filteredLocationArray = [];
  filteredCustomerArray = [];
  connectedBrands  = [] ;
  isLoading: boolean = false;


  constructor(
    public dialogRef: MatDialogRef<AddInventoryScanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private globalsService: GlobalsService,
    private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.form = this.formBuilder.group({scan: this.formBuilder.array([this.createItem()])});
    this.creatorID = this.data.creatorID;
    this.updateLists();
    this.http.get<any>(this.globalsService.baseHref + `/getFormulaOptions/${this.creatorID}`).subscribe((response) => {
//      console.log(Formula.length);
      if(response['formulas']['content'].length === 0) {
        alert("You do not have any scanned formula for the quarter.");
        this.Cancel();
      }
      this.choicesSource = response;
    //  console.log(this.choicesSource);
      this.createList();
      },
      err => {
        alert(err);
    });
    this.dataSourceFormula = new MatTableDataSource(this.inputData);
    this.addRows();
  }


  createItem(): FormGroup {
    return this.formBuilder.group({
      name: '',
      stockVolume: '',
      quantity: '',
      location: '',
      lotNumber: '',
      customer: '',
      purchaseDate: '',
      manufactureDate:'',
      expiryDate:''
    });
  }

  createList(){
    this.formulaNames = this.choicesSource['formulas']['content']
    this.locations = this.choicesSource['location']
    for (const brand of this.globalsService.brands) {
        if (brand['sharedWith']) {
          this.customers.push(brand.name);
        }
      }


    this.customers.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase())});
  //  console.log(this.customers);
  //  console.log(this.locations);
  }


  addRows(){
    this.formArray = this.form.get('scan') as FormArray;
    let counter = 0
    while (counter < 5){
      this.inputData.push({'name': '', 
      'masterID': '',
      'msdsIdentifier': '',
      'manufacturer': '  ',
      'lotNumber': '',
      'usedForCustomer': '',
      'stockVolume': '',
      'stockLocation': '',
      'quantity': '',
      'nameError': false,
      'stockVolumeError': false,
      'quantityError': false,
      'purchaseDate': '',
      'manufactureDate':'',
      'expiryDate': ''})
      this.formArray.push(this.createItem());
      counter += 1;
    }
    for (let i = 0 ; i < this.formArray.length; i++) {
      const filteredDisplayOptions = this.formArray.controls[i].get('name').valueChanges.pipe(startWith(''),
      map(value => this.formulaNames_filter(value))
      );
      const filteredLocationOptions = this.formArray.controls[i].get('location').valueChanges.pipe(startWith(''),
      map(value => this.location_filter(value))
      );
      const filteredCustomerOptions = this.formArray.controls[i].get('customer').valueChanges.pipe(startWith(''),
      map(value => this.customer_filter(value))
      );
      this.filteredDisplayArray.push(filteredDisplayOptions);
      this.filteredLocationArray.push(filteredLocationOptions);
      this.filteredCustomerArray.push(filteredCustomerOptions);
    }
    this.dataSourceFormula.data = this.inputData;
  }

  afterEdit(index:number, element:any) {
    if (this.formulaNames.filter(item => item.name === element.name).length > 0) {
      const formula = this.formulaNames.find(item => item.name === element.name);
      this.inputData[index].manufacturer = formula.manufacturer
      this.inputData[index].masterID = formula.masterID;
      this.inputData[index].msdsIdentifier = formula.msdsIdentifier;
    }
  //  console.log(index+1);
  //  console.log(this.inputData.length);
    if (index + 1 === this.inputData.length) {
      this.addRows();
    }
  }

  private formulaNames_filter(value: string): string[] {
    const filterValue = value.toLowerCase();
      return this.formulaNames.filter(option => option.display.toLowerCase().indexOf(filterValue) === 0);
  }

  private location_filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.locations.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private customer_filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.customers.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private checkNullorEmpty(arg: any) {
  if (arg === null || arg === undefined || arg === '') {
      return true;
    } else{
      return false;
    }
  }

  Save() {
    this.isLoading = true;
    this.checkValidity();
    if (
      this.inputData.filter(item => item.nameError === true).length > 0 ||
      this.inputData.filter(item => item.stockVolumeError === true).length > 0 ||
      this.inputData.filter(item => item.quantityError === true).length > 0) {
      this.isLoading = false;
      alert('Data is not saved. There are invalid chemical names in the table.');
    } else {
      const scan = [];
      for (const formula of this.inputData){
        if (this.checkNullorEmpty(formula.stockVolume)) {
          formula.stockVolume = null;
        }
        if (this.checkNullorEmpty(formula.quantity)) {
          formula.quantity = null;
        }

        if (!this.checkNullorEmpty(formula.name)) {
          scan.push({'name': formula.name, 
          'masterID': formula.masterID,
          'msdsIdentifier': formula.msdsIdentifier,
          'manufacturer': formula.manufacturer,
          'lotNumber': formula.lotNumber,
          'usedForCustomer': formula.usedForCustomer,
          'stockVolume': formula.stockVolume,
          'stockLocation': formula.stockLocation,
          'quantity': formula.quantity,
          'expiryDate': formula.expiryDate,
          'purchaseDate': formula.purchaseDate,
          'manufactureDate': formula.manufactureDate})
        }
      }
      
      if (scan.length > 0) {
        this.http.post(this.globalsService.baseHref + '/saveFrontendScan', {"scan":scan}).subscribe(
          () => {
            this.isLoading = false;
            this.dialogRef.close(true);
          },
          (err) => {
            console.log(err);
            alert('Error: Data specific to chemical could not be saved!');
          }
        );
      } else {
        this.isLoading = false;
        this.dialogRef.close(false);
      }
    }
  }

  Cancel() {
    this.dialogRef.close(null);
  }

  checkPositive(element:any, index:number, attribute:string) {
    if ((element < 0 || typeof(element) === 'object') && !this.checkNullorEmpty(element)) {
    //  this.formArray.controls[index].get(attribute).setValue('');
        if (attribute === 'quantity'){
            this.inputData[index].quantityError = true;
        } else{
            this.inputData[index].stockVolumeError = true;
        }
    } else {
      if (attribute === 'quantity'){
        this.inputData[index].quantityError = false;
      } else {
        this.inputData[index].stockVolumeError = false;
      }
    }
  }

  addToAutoComplete() {
    for (let i = 0 ; i < this.inputData.length; i++){
        if (this.customers.indexOf(this.inputData[i].usedForCustomer) === -1){
            this.customers.push(this.inputData[i].usedForCustomer);
        }
        if (this.locations.indexOf(this.inputData[i].stockLocation) === -1){
          this.locations.push(this.inputData[i].stockLocation);
        }
    }
    this.locations.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase())});
    this.customers.sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase())});
  }

  checkValidity(){
  for (let i = 0 ; i < this.inputData.length; i++) {
      if((!this.checkNullorEmpty(this.inputData[i].stockVolume) ||
      !this.checkNullorEmpty(this.inputData[i].quantity) ||
      !this.checkNullorEmpty(this.inputData[i].stockLocation) ||
      !this.checkNullorEmpty(this.inputData[i].purchaseDate) ||
      !this.checkNullorEmpty(this.inputData[i].manufactureDateEdited) ||
      !this.checkNullorEmpty(this.inputData[i].expiryDate) ||
      !this.checkNullorEmpty(this.inputData[i].lotNumber) ||
      !this.checkNullorEmpty(this.inputData[i].usedForCustomer)) &&
      this.checkNullorEmpty(this.inputData[i].name)){
        this.inputData[i].nameError = true;
      } else if (this.formulaNames.filter(item => item.name === this.inputData[i].name).length < 1 &&         !this.checkNullorEmpty(this.inputData[i].name)) {
        //   this.formArray.controls[i].get('name').setValue('');
          this.inputData[i].manufacturer = '';
          this.inputData[i].nameError = true;
      } else {
          this.inputData[i].nameError = false;
      }
    }
  }
  updateLists(){
    this.connectedBrands = [];
    for (const brand of this.globalsService.brands) {
      if (brand['sharedWith']) {
        this.connectedBrands.push(brand);
      }
    }
  }
}
