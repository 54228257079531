<div *ngIf="isCubejsConnected" class="container">
    <div class="p-10">
        <div class="flex items-center justify-between w-full ">
            <h2 class="text-3xl font-semibold tracking-tight leading-8">
                {{ "APP_COMPONENT.ANALYTICS" | translate }}
            </h2>
        </div>
    </div>
<div *ngIf="tabIndex != 0 && tabIndex != 3">
    <div class="form-group flex flex-wrap items-center mb-0 m-3" style="margin-top:20px"  >
        <mat-form-field appearance="fill">
            <mat-label i18n="Inventories report date range label">{{
                'INVENTORIES_REPORT_COMPONENT.CHART.DATE_RANGE' | translate }}</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" [formControl]="startDate">
                <input matEndDate placeholder="End date" [formControl]="endDate" (dateChange)="changeEndDate($event, 'Not Activity')">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-form-field  appearance="fill">
            <mat-label i18n="Inventories report chemical name label">Chemical Name</mat-label>
            <input matInput type="text" [formControl]="chemicalNameControl" />
        </mat-form-field>
        <!-- <mat-form-field appearance="fill">
            <mat-label i18n="Inventories report standards label">Product Category</mat-label>
            <mat-select multiple [formControl]="productTypeControl">
                <mat-option *ngFor="let product of productTypeFilter" [value]="product">
                    {{product.name}}</mat-option>
            </mat-select>
        </mat-form-field> -->
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Used For</mat-label>
                <mat-select multiple [formControl]="usedForControl">
                    <mat-option *ngFor="let usedFor of usedForFilter" [value]="usedFor">
                        {{usedFor.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Inditex Category</mat-label>
                <mat-select [formControl]="inditexCategoryControl">
                    <!-- <mat-option [value]=""></mat-option> -->
                    <mat-option *ngFor="let inditexCategory of inditexCategoryList" [value]="inditexCategory">
                        {{inditexCategory.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">The List Level</mat-label>
                <mat-select multiple [formControl]="inditexLevelControl">
                    <mat-option *ngFor="let inditexlevel of inditexLevelList" [value]="inditexlevel"
                        (onSelectionChange)="inditexLevelChange($event)">
                        {{inditexlevel.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label i18n="Inventories report standards label">Factory Usage</mat-label>
                <mat-select multiple [formControl]="factoryUsageControl">
                    <mat-option *ngFor="let factory of factoryUsage" [value]="factory">
                        {{factory.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div appearance="fill" class="ml-2">
                <mat-label class="mr-3"><b>Commodities</b></mat-label>
                <mat-slide-toggle color="primary" [formControl]="commoditiesControl"
                    i18n="Inventories report standards label"></mat-slide-toggle>
            </div>
        </div>
        <!-- <div class="mb-3 pie-chart-form">
        <button mat-button class="mat-focus-indicator text-base mat-button mat-button-base mat-primary" color="primary" (click)="applyFilters()"><mat-icon [svgIcon]="'feather:filter'"></mat-icon>Apply filters</button>
        <button mat-button (click)="clearFilters()" class="mat-focus-indicator mat-button mat-button-base" ><mat-icon [svgIcon]="'mat_outline:clear'"></mat-icon>Clear filters</button>
    </div> -->
    <div _ngcontent-voc-c309="" class="mb-3 pie-chart-form">
        <button _ngcontent-voc-c309="" mat-button="" (click)="applyFilters()" class="mat-focus-indicator text-base mat-button mat-button-base mat-primary"><span class="mat-button-wrapper" style="color:var(--fuse-primary)!important"><mat-icon _ngcontent-voc-c309="" role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="filter" data-mat-icon-namespace="feather"><svg x="480" y="192" viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path style="color:var(--fuse-primary)!important" fill="none" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="currentColor" d="M22 3H2l8 9.46V19l4 2v-8.54L22 3z"></path>
    </svg></mat-icon>Apply filters</span><span matripple="" class="mat-ripple mat-button-ripple"></span><span class="mat-button-focus-overlay"></span></button>
    <button _ngcontent-voc-c309="" mat-button=""(click)="clearFilters()" class="mat-focus-indicator mat-button mat-button-base"><span class="mat-button-wrapper"><mat-icon _ngcontent-voc-c309="" role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="clear" data-mat-icon-namespace="mat_outline"><svg width="100%" height="100%" viewBox="0 0 24 24" x="200" y="880" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="M19 6.41l-1.41-1.41-5.59 5.59-5.59-5.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"></path>
    </svg></mat-icon>Clear filters</span><span matripple="" class="mat-ripple mat-button-ripple"></span><span class="mat-button-focus-overlay"></span></button></div>
</div> 
<div *ngIf="tabIndex == 3">
    <div class="form-group flex flex-wrap items-center mb-0" style="margin-top:20px"  >
        <mat-form-field appearance="fill" style="width: 17vw;">
            <mat-label i18n="Inventories report date range label">{{
                'INVENTORIES_REPORT_COMPONENT.CHART.DATE_RANGE' | translate }}</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" [formControl]="startDateActivity">
                <input matEndDate placeholder="End date" [formControl]="endDateActivity" (dateChange)="changeEndDate($event, 'Actvity')">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </div>
    <div _ngcontent-voc-c309="" class="mb-3 pie-chart-form">
        <button _ngcontent-voc-c309="" mat-button="" (click)="applyFilters()" class="mat-focus-indicator text-base mat-button mat-button-base mat-primary"><span class="mat-button-wrapper" style="color:var(--fuse-primary)!important"><mat-icon _ngcontent-voc-c309="" role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="filter" data-mat-icon-namespace="feather"><svg x="480" y="192" viewBox="0 0 24 24" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path style="color:var(--fuse-primary)!important" fill="none" stroke-linejoin="round" stroke-linecap="round" stroke-width="2" stroke="currentColor" d="M22 3H2l8 9.46V19l4 2v-8.54L22 3z"></path>
    </svg></mat-icon>Apply filters</span><span matripple="" class="mat-ripple mat-button-ripple"></span><span class="mat-button-focus-overlay"></span></button>
    <button _ngcontent-voc-c309="" mat-button=""(click)="clearFilters()" class="mat-focus-indicator mat-button mat-button-base"><span class="mat-button-wrapper"><mat-icon _ngcontent-voc-c309="" role="img" class="mat-icon notranslate mat-icon-no-color" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="clear" data-mat-icon-namespace="mat_outline"><svg width="100%" height="100%" viewBox="0 0 24 24" x="200" y="880" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
        <path d="M19 6.41l-1.41-1.41-5.59 5.59-5.59-5.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"></path>
    </svg></mat-icon>Clear filters</span><span matripple="" class="mat-ripple mat-button-ripple"></span><span class="mat-button-focus-overlay"></span></button></div>    
</div>
<div>
    <mat-tab-group mat-stretch-tabs (selectedTabChange)="selectedTabChanged($event);">
        <mat-tab label="Inventory Analytics">
            <ng-template matTabContent>
                    <app-inventories-report [hideTitle]="hide_Title"></app-inventories-report>
                </ng-template>
            </mat-tab>
            <mat-tab label="Factory Performance">
                <ng-template matTabContent>
                    <mat-grid-list cols="2" rowHeight="16vh">
                        <mat-grid-tile [colspan]="1" [rowspan]="2">
                            <mat-card>
                                <button mat-icon-button id="more_button" [matMenuTriggerFor]="scByCountMenu">
                                    <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                                </button>
                                <mat-menu #scByCountMenu="matMenu" id="more_menu">
                                    <button mat-menu-item
                                        (click)="exportData(this.rawDataSeriesByCount,'Overall Performance by Count')">
                                        <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                        <span>Export Data</span>
                                    </button>
                                </mat-menu>
                                <mat-card-subtitle>Overall Performance by Count</mat-card-subtitle>
                                <mat-card-content>
                                    <loading-spinner *ngIf="isLoadingTimeSeriesCompliance"></loading-spinner>
                                    <div class="Charts" style="height: 32vh;">
                                        <ngx-charts-line-chart (window:resize)="onResize($event)"
                                            [scheme]="{domain: ['#88bf4d']}" [results]="timeSeriesCompliance"
                                            [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="true"
                                            [showYAxisLabel]="true" [xAxisLabel]="'Period'"
                                            [yAxisLabel]="'Compliance(%)'" [showGridLines]="true" [roundDomains]="true"
                                            [timeline]="true">
                                        </ngx-charts-line-chart>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="1" [rowspan]="2">
                            <mat-card>
                                <button mat-icon-button id="more_button" [matMenuTriggerFor]="scByWeightMenu">
                                    <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                                </button>
                                <mat-menu #scByWeightMenu="matMenu" id="more_menu">
                                    <button mat-menu-item
                                        (click)="exportData(this.rawDataSeriesByWeight,'Overall Performance by Weight')">
                                        <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                        <span>Export Data</span>
                                    </button>
                                </mat-menu>
                                <mat-card-subtitle>Overall Performance by Weight</mat-card-subtitle>
                                <mat-card-content>
                                    <loading-spinner *ngIf="isLoadingTimeSeriesConsumption"></loading-spinner>
                                    <div class="Charts" style="height: 32vh;">
                                        <ngx-charts-line-chart (window:resize)="onResize($event)"
                                            [scheme]="{domain: ['#88bf4d']}" [results]="timeSeriesConsumption"
                                            [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="true"
                                            [showYAxisLabel]="true" [xAxisLabel]="'Period'"
                                            [yAxisLabel]="'Compliance(%)'" [showGridLines]="true" [roundDomains]="true"
                                            [timeline]="true">
                                        </ngx-charts-line-chart>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                        <!-- <mat-grid-tile [colspan]="2" [rowspan]="3">
                        <mat-card>
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="productCountMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #productCountMenu="matMenu" id="more_menu">
                                <button mat-menu-item
                                    (click)="exportData(this.rawDataCategoryByCount,'Product Category Performance by Count')">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Product Category Performance by Count</mat-card-subtitle>
                            <mat-card-content>
                                <loading-spinner *ngIf="isLoadingTimeSeriesCategoryByCount"></loading-spinner>
                                <div class="Charts" style="width: 85vw; height: 37vh;">
                                    <ngx-charts-bar-vertical-2d (window:resize)="onResize($event)" [scheme]="{
                                        domain: ['#88bf4d', '#509ee3', 'rgb(113, 114, 173)', 'rgb(239, 140, 140)', 'rgb(249, 212, 92)', 'rgb(242, 168, 111)',
                                        'rgb(152, 217, 217)', 'rgb(113, 114, 173)', 'rgb(76, 87, 115)']}"
                                        [results]="timeSeriesCategoryByCount" [xAxis]="true" [yAxis]="true" [legend]="true"
                                        [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxisLabel]="'Period'"
                                        [yAxisLabel]="'Compliance(%)'" [showGridLines]="true" [roundDomains]="true"
                                        groupPadding="4">
                                    </ngx-charts-bar-vertical-2d>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="3">
                        <mat-card>
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="productByWeightMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #productByWeightMenu="matMenu" id="more_menu">
                                <button mat-menu-item
                                    (click)="exportData(this.rawDataCategoryByWeight,'Product Category Performance by Weight')">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Product Category Performance by Weight</mat-card-subtitle>
                            <mat-card-content>
                                <loading-spinner *ngIf="isLoadingTimeSeriesCategoryByWeight"></loading-spinner>
                                <div class="Charts" style="width: 85vw; height: 37vh;">
                                    <ngx-charts-bar-vertical-2d (window:resize)="onResize($event)" [scheme]="{
                                        domain: ['#88bf4d', '#509ee3', 'rgb(113, 114, 173)', 'rgb(239, 140, 140)', 'rgb(249, 212, 92)', 'rgb(242, 168, 111)',
                                        'rgb(152, 217, 217)', 'rgb(113, 114, 173)', 'rgb(76, 87, 115)']}"
                                        [results]="timeSeriesCategoryByWeight" [xAxis]="true" [yAxis]="true" [legend]="true"
                                        [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxisLabel]="'Period'"
                                        [yAxisLabel]="'Compliance(%)'" [showGridLines]="true" [roundDomains]="true"
                                        groupPadding="4">
                                    </ngx-charts-bar-vertical-2d>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile> -->
                    </mat-grid-list>
                </ng-template>
            </mat-tab>
            <mat-tab label="Chemical Information">
                <ng-template matTabContent>
                    <mat-grid-list cols="6" rowHeight="16vh">
                        <mat-grid-tile class="dates-tile" [colspan]="6" [rowspan]="1">
                            <mat-card class="compliance">
                                <div class="info-cards">
                                    <!-- <loading-spinner *ngIf="isLoadingConnectedBrandsCount"></loading-spinner> -->
                                    <mat-card-title class="text-xl">{{connectedPartnersCount}}</mat-card-title>
                                    <mat-card-subtitle>Connected Partners</mat-card-subtitle>
                                </div>
                            </mat-card>
                            <mat-card class="compliance">
                                <div class="info-cards">
                                    <!-- <loading-spinner *ngIf="isLoadingCompletedInventory"></loading-spinner> -->
                                    <mat-card-title class="text-xl">{{completedInventory}}</mat-card-title>
                                    <mat-card-subtitle>Completed Inventory</mat-card-subtitle>
                                </div>
                            </mat-card>
                            <mat-card class="compliance">
                                <div class="info-cards">
                                    <!-- <loading-spinner *ngIf="isLoadingLastScanDate"></loading-spinner> -->
                                    <mat-card-title class="text-xl">{{lastScanDate}}</mat-card-title>
                                    <mat-card-subtitle>Last Scan Date</mat-card-subtitle>
                                </div>
                            </mat-card>
                            <mat-card>
                                <div class="info-cards">
                                    <!-- <loading-spinner *ngIf="isLoadingLastGeneratedIncheck"></loading-spinner> -->
                                    <mat-card-title class="text-xl">{{lastGeneratedIncheck}}</mat-card-title>
                                    <mat-card-subtitle>Last Generated Incheck</mat-card-subtitle>
                                </div>
                            </mat-card>
                            <mat-card class="compliance">
                                <div class="info-cards">
                                    <!-- <loading-spinner *ngIf="isLoadingWasteWater"></loading-spinner> -->
                                    <mat-card-title class="text-xl">{{lastWasteWater}}</mat-card-title>
                                    <mat-card-subtitle>Latest Wastewater v1.1</mat-card-subtitle>
                                </div>
                            </mat-card>
                            <mat-card class="compliance">
                                <div class="info-cards">
                                    <!-- <loading-spinner *ngIf="isLoadingLastVerfHigg"></loading-spinner> -->
                                    <mat-card-title class="text-xl">{{lastVerHigg}}</mat-card-title>
                                    <mat-card-subtitle>Last Verif Higg Level</mat-card-subtitle>
                                </div>
                            </mat-card>
                            <mat-card class="compliance">
                                <div class="info-cards">
                                    <!-- <loading-spinner *ngIf="isLoadingLastVerfHigg"></loading-spinner> -->
                                    <mat-card-title class="text-xl">{{lastSelfHigg}}</mat-card-title>
                                    <mat-card-subtitle>Last Self Higg Level</mat-card-subtitle>
                                </div>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="6" [rowspan]="4">
                            <mat-card class="h-fit">
                                <button mat-icon-button id="more_button" [matMenuTriggerFor]="chemicalMenu">
                                    <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                                </button>
                                <mat-menu #chemicalMenu="matMenu" id="more_menu">
                                    <button mat-menu-item
                                        (click)="exportData(this.chemicalInfoDataList.data,'Chemical Information')">
                                        <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                        <span>Export Data</span>
                                    </button>
                                </mat-menu>
                                <mat-card-subtitle>Chemical Information</mat-card-subtitle>
                                <mat-card-content class="h-5/6">
                                    <div class="h-full unverified_chemicals_with_CAP">
                                        <table mat-table [dataSource]="chemicalInfoDataList"
                                            #chemicalInfoMatSort="matSort" matSort>

                                            <ng-container matColumnDef="chemicalName">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Name</th>
                                                <td mat-cell *matCellDef="let formula">
                                                    {{formula.chemicalName}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="manufacturer">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
                                                <td mat-cell *matCellDef="let formula">
                                                    {{formula.manufacturer}}
                                                    <mat-icon *ngIf="formula.isGateway==1"
                                                        [ngStyle]="{'color':'#05AFF0', 'vertical-align':'middle', 'transform': 'scale(0.9)'}"
                                                        matTooltip="This manufacturer is on gateway"
                                                        svgIcon="mat_outline:verified">
                                                    </mat-icon>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="consumption">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption</th>
                                                <td mat-cell *matCellDef="let formula">
                                                    {{formula.consumption | number: "1.2-2"}}
                                                </td>
                                            </ng-container>

                                            <!-- <ng-container matColumnDef="endingStock">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header  >Ending Stock</th>
                                            <td mat-cell *matCellDef="let formula" >
                                                {{formula.endingStock | number: "1.2-2"}}
                                            </td>
                                        </ng-container> -->

                                            <ng-container matColumnDef="complianceStatus">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    matTooltip="According to ZDHC MRSL">Compliance Status</th>
                                                <td mat-cell *matCellDef="let formula"
                                                    [ngClass]="{'make-cell-red': formula.complianceStatus === 'Unverified'}">
                                                    {{formula.complianceStatus }}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="factoryUsage">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Usage in Factory
                                                </th>
                                                <td mat-cell *matCellDef="let formula">
                                                    {{formula.factoryUsage }}
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="chemicalInfo; sticky: true;"></tr>
                                            <tr mat-row *matRowDef="let row; columns: chemicalInfo;"
                                                [ngClass]="{'make-red': row.complianceStatus === 'Unverified'}"></tr>
                                        </table>
                                        <loading-spinner *ngIf="isLoadingChemicalInfo"></loading-spinner>
                                    </div>
                                    <mat-paginator #chemicalInfoMatPaginator="matPaginator" [pageSizeOptions]="[15]"
                                        showFirstLastButtons></mat-paginator>
                                </mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="3" [rowspan]="3">
                            <mat-card class="h-fit">
                                <button mat-icon-button id="more_button" [matMenuTriggerFor]="topUsedMenu">
                                    <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                                </button>
                                <mat-menu #topUsedMenu="matMenu" id="more_menu">
                                    <button mat-menu-item
                                        (click)="exportData(this.topChemicalsDataList.data,'Overall Top Chemicals Used')">
                                        <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                        <span>Export Data</span>
                                    </button>
                                </mat-menu>
                                <mat-card-subtitle
                                    matTooltip="The table shows data independent from Inditex Filters">Overall Top
                                    Chemicals Used</mat-card-subtitle>
                                <mat-card-content class="h-5/6">
                                    <div class="h-full tops_table unverified_chemicals_with_CAP">
                                        <table mat-table [dataSource]="topChemicalsDataList"
                                            #topChemicalsMatSort="matSort" matSort>

                                            <ng-container matColumnDef="chemicalName">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Name</th>
                                                <td mat-cell *matCellDef="let formula">
                                                    {{formula.chemicalName}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="manufacturer">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
                                                <td mat-cell *matCellDef="let formula">
                                                    {{formula.manufacturer}}
                                                    <mat-icon *ngIf="formula.isGateway==1"
                                                        [ngStyle]="{'color':'#05AFF0', 'vertical-align':'middle', 'transform': 'scale(0.9)'}"
                                                        matTooltip="This manufacturer is on gateway"
                                                        svgIcon="mat_outline:verified">
                                                    </mat-icon>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="usage">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Usage (KG)</th>
                                                <td mat-cell *matCellDef="let formula">
                                                    {{formula.usage | number: "1.2-2"}}
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="topChemicals; sticky: true;"></tr>
                                            <tr mat-row *matRowDef="let row; columns: topChemicals;"></tr>
                                        </table>
                                        <loading-spinner *ngIf="isLoadingTopChemicals"></loading-spinner>
                                    </div>
                                    <mat-paginator #topChemicalsMatPaginator="matPaginator" [pageSizeOptions]="[10]"
                                        showFirstLastButtons></mat-paginator>
                                </mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="3" [rowspan]="3">
                            <mat-card class="h-fit">
                                <button mat-icon-button id="more_button" [matMenuTriggerFor]="topManufacturerMenu">
                                    <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                                </button>
                                <mat-menu #topManufacturerMenu="matMenu" id="more_menu">
                                    <button mat-menu-item
                                        (click)="exportData(this.topManufacturersDataList.data,'Overall Top Manufacturers')">
                                        <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                        <span>Export Data</span>
                                    </button>
                                </mat-menu>
                                <mat-card-subtitle
                                    matTooltip="The table shows data independent from Inditex Filters">Overall Top
                                    Manufacturers</mat-card-subtitle>
                                <mat-card-content class="h-5/6">
                                    <div class="h-full tops_table unverified_chemicals_with_CAP">
                                        <table mat-table [dataSource]="topManufacturersDataList"
                                            #topManufacturersMatSort="matSort" matSort>

                                            <ng-container matColumnDef="manufacturer">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
                                                <td mat-cell *matCellDef="let formula">
                                                    {{formula.manufacturer}}
                                                    <mat-icon *ngIf="formula.isGateway==1"
                                                        [ngStyle]="{'color':'#05AFF0', 'vertical-align':'middle', 'transform': 'scale(0.9)'}"
                                                        matTooltip="This manufacturer is on gateway"
                                                        svgIcon="mat_outline:verified">
                                                    </mat-icon>
                                                </td>
                                            </ng-container>

                                            <!-- <ng-container matColumnDef="partner">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>BHive Partner</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.partner == 1 ? 'Yes' : 'No'}}
                                            </td>
                                        </ng-container> -->

                                            <ng-container matColumnDef="chemicalCount">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Count
                                                </th>
                                                <td mat-cell *matCellDef="let formula">
                                                    {{formula.chemicalCount}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="usage">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Usage (KG)</th>
                                                <td mat-cell *matCellDef="let formula">
                                                    {{formula.usage | number: "1.2-2"}}
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="topManufacturers; sticky: true;"></tr>
                                            <tr mat-row *matRowDef="let row; columns: topManufacturers;"></tr>
                                        </table>
                                        <loading-spinner *ngIf="isLoadingTopManufacturers"></loading-spinner>
                                    </div>
                                    <mat-paginator #topManufacturersMatPaginator="matPaginator" [pageSizeOptions]="[10]"
                                        showFirstLastButtons></mat-paginator>
                                </mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="3" [rowspan]="3">
                            <mat-card class="h-fit">
                                <button mat-icon-button id="more_button" [matMenuTriggerFor]="topUnverifiedChemsMenu">
                                    <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                                </button>
                                <mat-menu #topUnverifiedChemsMenu="matMenu" id="more_menu">
                                    <button mat-menu-item
                                        (click)="exportData(this.topUnverifiedChemicalsDataList.data,'Top Unverified Chemicals')">
                                        <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                        <span>Export Data</span>
                                    </button>
                                </mat-menu>
                                <mat-card-subtitle>Top Unverified Chemicals</mat-card-subtitle>
                                <mat-card-content class="h-5/6">
                                    <div class=" h-full tops_table unverified_chemicals_with_CAP">
                                        <table mat-table [dataSource]="topUnverifiedChemicalsDataList"
                                            #topUnverifiedChemicalsMatSort="matSort" matSort>

                                            <ng-container matColumnDef="manufacturer">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
                                                <td mat-cell *matCellDef="let formula">
                                                    {{formula.manufacturer}}
                                                    <mat-icon *ngIf="formula.isGateway==1"
                                                        [ngStyle]="{'color':'#05AFF0', 'vertical-align':'middle', 'transform': 'scale(0.9)'}"
                                                        matTooltip="This manufacturer is on gateway"
                                                        svgIcon="mat_outline:verified">
                                                    </mat-icon>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="chemicalName">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Name</th>
                                                <td mat-cell *matCellDef="let formula">
                                                    {{formula.chemicalName}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="usage">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Usage (KG)</th>
                                                <td mat-cell *matCellDef="let formula">
                                                    {{formula.usage | number: "1.2-2" }}
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row *matHeaderRowDef="topUnverifiedChemicals; sticky: true;">
                                            </tr>
                                            <tr mat-row *matRowDef="let row; columns: topUnverifiedChemicals;"></tr>
                                        </table>
                                        <loading-spinner *ngIf="isLoadingTopUnverifiedChemicals"></loading-spinner>
                                    </div>
                                    <mat-paginator #topUnverifiedChemicalsMatPaginator="matPaginator"
                                        [pageSizeOptions]="[10]" showFirstLastButtons></mat-paginator>
                                </mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="3" [rowspan]="3">
                            <mat-card class="h-fit">
                                <button mat-icon-button id="more_button"
                                    [matMenuTriggerFor]="unverifiedManufacturerMenu">
                                    <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                                </button>
                                <mat-menu #unverifiedManufacturerMenu="matMenu" id="more_menu">
                                    <button mat-menu-item
                                        (click)="exportData(this.topUnverifiedManufacturersDataList.data,'Top Unverified Manufacturer')">
                                        <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                        <span>Export Data</span>
                                    </button>
                                </mat-menu>
                                <mat-card-subtitle>Top Unverified Manufacturer</mat-card-subtitle>
                                <mat-card-content class="h-5/6">
                                    <div class="h-full tops_table unverified_chemicals_with_CAP">
                                        <table mat-table [dataSource]="topUnverifiedManufacturersDataList"
                                            #topUnverifiedManufacturersMatSort="matSort" matSort>

                                            <ng-container matColumnDef="manufacturer">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
                                                <td mat-cell *matCellDef="let formula">
                                                    {{formula.manufacturer}}
                                                    <mat-icon *ngIf="formula.isGateway==1"
                                                        [ngStyle]="{'color':'#05AFF0', 'vertical-align':'middle', 'transform': 'scale(0.9)'}"
                                                        matTooltip="This manufacturer is on gateway"
                                                        svgIcon="mat_outline:verified">
                                                    </mat-icon>
                                                </td>
                                            </ng-container>

                                            <!-- <ng-container matColumnDef="partner">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>BHive Partner</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.partner == 1 ? 'Yes' : 'No'}}
                                            </td>
                                        </ng-container> -->

                                            <ng-container matColumnDef="usage">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Usage (KG)</th>
                                                <td mat-cell *matCellDef="let formula">
                                                    {{formula.usage | number: "1.2-2" }}
                                                </td>
                                            </ng-container>

                                            <tr mat-header-row
                                                *matHeaderRowDef="topUnverifiedManufacturers; sticky: true;">
                                            </tr>
                                            <tr mat-row *matRowDef="let row; columns: topUnverifiedManufacturers;"></tr>
                                        </table>
                                        <loading-spinner *ngIf="isLoadingTopUnverifiedManufacturers"></loading-spinner>
                                    </div>
                                    <mat-paginator #topUnverifiedManufacturersMatPaginator="matPaginator"
                                        [pageSizeOptions]="[10]" showFirstLastButtons></mat-paginator>
                                </mat-card-content>
                            </mat-card>
                        </mat-grid-tile>
                        <mat-grid-tile [colspan]="6" [rowspan]="3">
                            <mat-card class="h-fit">
                                <button mat-icon-button id="more_button" [matMenuTriggerFor]="topVerifiedChemsMenu">
                                    <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #topVerifiedChemsMenu="matMenu" id="more_menu">
                                <button mat-menu-item
                                    (click)="exportData(this.topVerifiedChemicalsDataList.data,'Top Verified Chemicals')">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Top Verified Chemicals</mat-card-subtitle>
                            <mat-card-content class="h-5/6">
                                <div class="h-full unverified_chemicals_with_CAP">
                                    <table mat-table [dataSource]="topVerifiedChemicalsDataList"
                                        #topVerifiedChemicalsMatSort="matSort" matSort>
    
                                        <ng-container matColumnDef="manufacturer">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacturer</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.manufacturer}}
                                            <mat-icon *ngIf="formula.isGateway==1" [ngStyle]="{'color':'#05AFF0', 'vertical-align':'middle', 'transform': 'scale(0.9)'}" matTooltip="This manufacturer is on gateway"
                                                svgIcon="mat_outline:verified" >
                                           </mat-icon>
                                            </td>
                                        </ng-container>
    
                                        <ng-container matColumnDef="chemicalName">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Name</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.chemicalName}}
                                            </td>
                                        </ng-container>
    
                                        <ng-container matColumnDef="usage">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usage (KG)</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.usage | number: "1.2-2" }}
                                            </td>
                                        </ng-container>
    
                                        <tr mat-header-row *matHeaderRowDef="topVerifiedChemicals; sticky: true;"></tr>
                                        <tr mat-row *matRowDef="let row; columns: topVerifiedChemicals;"></tr>
                                    </table>
                                    <loading-spinner *ngIf="isLoadingTopVerifiedChemicals"></loading-spinner>
                                </div>
                                <mat-paginator #topVerifiedChemicalsMatPaginator="matPaginator" [pageSizeOptions]="[10]"
                                    showFirstLastButtons></mat-paginator>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                </mat-grid-list>
            </ng-template>
        </mat-tab>
        <mat-tab label="Raw Data">
            <ng-template matTabContent>
                <mat-grid-list cols="6" rowHeight="16vh">
                    <mat-grid-tile [colspan]="6" [rowspan]="4">
                        <mat-card class = "h-fit">
                            <button mat-icon-button id="more_button" [matMenuTriggerFor]="activityMenu">
                                <mat-icon svgIcon="mat_outline:more_vert"></mat-icon>
                            </button>
                            <mat-menu #activityMenu="matMenu" id="more_menu">
                                <button mat-menu-item
                                    (click)="exportData(this.rowDataInfoDataList.data,'Raw Data')">
                                    <mat-icon svgIcon="mat_outline:file_download"></mat-icon>
                                    <span>Export Data</span>
                                </button>
                            </mat-menu>
                            <mat-card-subtitle>Raw Data</mat-card-subtitle>
                            <mat-card-content class="h-5/6">
                                <div class="h-full unverified_chemicals_with_CAP raw-data">
                                    <table mat-table [dataSource]="rowDataInfoDataList" #rawInfoMatSort="matSort" matSort>
            
                                        <ng-container matColumnDef="organization_name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory Name</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.organization_name}}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="scan_date">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Scan Date</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.scan_date | date:'yyyy-MM-dd'}}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="chemical_name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Name</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.chemical_name}}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="chemical_manufacturer">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Manufacturer</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.chemical_manufacturer}}
                                            </td>
                                        </ng-container>

                                          <ng-container *ngIf="isFactoryZdhc" matColumnDef="gateway_name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gateway Name</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.gateway_name}}
                                            </td>
                                        </ng-container>
            
                                        <ng-container *ngIf="isFactoryZdhc" matColumnDef="gateway_manufacturer">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gateway Manufacturer</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.gateway_manufacturer }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container *ngIf="isFactoryZdhc" matColumnDef="gateway_level">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gateway Level</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.gateway_level}}
                                            </td>
                                        </ng-container>


                                        <ng-container matColumnDef="is_compliant">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Compliance Status</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.is_compliant }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="application">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Application</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.application }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="user_application">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>User Application</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.user_application }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="category">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.category}}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="delivered_quantity">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivered Quantity</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.delivered_quantity }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="consumption">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.consumption }}
                                        </ng-container>
            
                                        <ng-container matColumnDef="stock_volume">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Stock Volume</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.stock_volume }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="basic_chemical">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Basic Chemical</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.basic_chemical }}
                                            </td>
                                        </ng-container>
            
                                        <!-- <ng-container matColumnDef="productType">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Product Type</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.productType }}
                                        </ng-container> -->
            
                                        <ng-container matColumnDef="lot_number">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Lot Number</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.lot_number }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="cas_nr">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cas Nr</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.cas_nr }}
                                        </ng-container>
            
                                        <ng-container matColumnDef="notes">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.notes }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="use_of_ppe">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Use of PPE</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.use_of_ppe }}
                                        </ng-container>
            
                                        <ng-container matColumnDef="ghs_classification">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>GHS Classification</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.ghs_classification }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="precautionary_statement">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Precautionary Statement</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.precautionary_statement }}
                                        </ng-container>
            
                                        <ng-container matColumnDef="ghs_msds_available">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>GHS MSDS Available</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.ghs_msds_available }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="stock_location">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Stock Location</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.stock_location }}
                                        </ng-container>
            
                                        <ng-container matColumnDef="storage_condition">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Storage Condition</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.storage_condition }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="minimum_stock">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Minimum Stock</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.minimum_stock }}
                                        </ng-container>
            
                                        <ng-container matColumnDef="maximum_stock">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Maximum Stock</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.maximum_stock }}
                                        </ng-container>
            
                                        <ng-container matColumnDef="expiry_date">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Expiry Date</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.expiry_date | date:'yyyy-MM-dd'  }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="manufacture_date">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacture Date</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.manufacture_date | date:'yyyy-MM-dd'  }}
                                        </ng-container>
            
                                        <ng-container matColumnDef="purchase_date">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Purchase Date</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.purchase_date | date:'yyyy-MM-dd' }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="zdhc_mrsl">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC MRSL</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.zdhc_mrsl }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="zdhc_grading">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC Grading</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.zdhc_grading }}
                                        </ng-container>
            
                                        <ng-container matColumnDef="zdhc_mrsl_self_declaration">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC MRSL Self Declaration</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.zdhc_mrsl_self_declaration }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="gots">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>GOTS</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.gots }}
                                        </ng-container>
            
                                        <ng-container matColumnDef="gots_version">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>GOTS Version</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.gots_version }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="eco_passport">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Eco Passport</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.eco_passport }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="bluesign">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Bluesign</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.bluesign }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="bluesign_system_partner">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Bluesign System Partner</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.bluesign_system_partner }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="green_screen">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Green Screen</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.green_screen }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="green_screen_level">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Green Screen Level</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.green_screen_level }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="c2c">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>C2C</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.c2c }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="inditex_AboveThreeYears">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Inditex Above Three Years</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.inditex_AboveThreeYears }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="inditex_BelowThreeYears">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Inditex Below Three Years</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.inditex_BelowThreeYears }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="scivera">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Scivera</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.scivera }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="toxfmd">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Toxfmd</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.toxfmd }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="test_report">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Test Report</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.test_report }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="testing_facility">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Testing Facility</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.testing_facility }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="svhc">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>SVHC</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.svhc }}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="hazard_statement">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>GHS Hazard Statement</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.hazard_statement}}
                                            </td>
                                        </ng-container>
            
                                        <ng-container matColumnDef="_type">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Formula Type</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula._type }}
                                            </td>
                                        </ng-container>
            
                                        <!-- <ng-container matColumnDef="gateway_name">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gateway Name</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.gateway_name}}
                                            </td>
                                        </ng-container>
             -->
                                        <!-- <ng-container matColumnDef="gateway_manufacturer">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gateway Manufacturer</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.gateway_manufacturer }}
                                            </td>
                                        </ng-container> -->
<!--             
                                        <ng-container matColumnDef="gateway_level">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gateway Level</th>
                                            <td mat-cell *matCellDef="let formula"
                                                >
                                                {{formula.gateway_level}}
                                            </td>
                                        </ng-container> -->
            
                                        <!-- <ng-container matColumnDef="zdhc_mrsl_version">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC MRSL Version</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.zdhc_mrsl_version }}
                                            </td>
                                        </ng-container> -->
            
                                        <!-- <ng-container matColumnDef="count_used_for_brand">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Count Used for Brand</th>
                                            <td mat-cell *matCellDef="let formula">
                                                {{formula.count_used_for_brand }}
                                            </td>
                                        </ng-container> -->
    
                                        <!-- <ng-container matColumnDef="noData">
                                            <td *matFooterCellDef [attr.colspan]="rowDataInfoDataList.length">
                                              No records found.
                                            </td>
                                          </ng-container> -->
            
                                        <tr mat-header-row *matHeaderRowDef="rowData; sticky: true;"></tr>
                                        <tr mat-row *matRowDef="let row; columns: rowData;"></tr>
                                        <!-- <tr mat-footer-row *matFooterRowDef="['noData']" [hidden]="rowData && rowData.data.length > 0"></tr> -->
    
                                    </table>
                                    <div *ngIf="hasNotData" style="text-align:center;">
                                        No records found
                                      </div>
                                    <loading-spinner *ngIf="isLoadingrowDataInfo"></loading-spinner>
                                </div>
                                <mat-paginator #rawMatPaginator="matPaginator" [pageSizeOptions]="[15]"
                                    showFirstLastButtons></mat-paginator>
                            </mat-card-content>
                        </mat-card>
                    </mat-grid-tile>
                </mat-grid-list>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
</div>

<div *ngIf="!isCubejsConnected" class="flex flex-col flex-auto min-w-0">
    <div class="flex-auto p-6 sm:p-10">
        <div class="content">
            <div class="flex flex-auto items-center justify-center w-full mb-2 min-h-32">
                <span class="text-3xl font-bold leading-8">
                    Analytics are currently not available due to maintenance. Will be back soon!
                </span>
            </div>
        </div>
    </div>
</div>