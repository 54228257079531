import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { GlobalsService } from './globals.service';



export interface JWT_AuthenticationToken {

  username: string;
  roles: string[];
  token_type: string;
  access_token: string;
  expires_in: number;
  refresh_token: string;

}

export class AuthenticationState {
  
  isAuthenticated: boolean;

  jwtAuthenticationToken: JWT_AuthenticationToken;


  static storeToken(token: JWT_AuthenticationToken): AuthenticationState {

    const authenticationState = new AuthenticationState();

    authenticationState.isAuthenticated = true;

    authenticationState.jwtAuthenticationToken = token;

    return authenticationState;

  }

  public get username() {
    return this.jwtAuthenticationToken.username;
  }

}

@Injectable()
export class AuthenticationService {

  public authenticationState: AuthenticationState;

  constructor(
    private router: Router,
    private http: HttpClient,
    private globalsService: GlobalsService
  ) {}

  get isAuthenticated(): boolean {

    if (!this.authenticationState) {  //if authenticationState doesnt exist
      
      return false;

    }
    
    return this.authenticationState.isAuthenticated;
  }

  displayName(): string {
    return this.authenticationState.username;
  }

  getOrganizationType(): string {
    return this.globalsService.profile["organization_type"];
  }

  login(targetPath: string) {

    if (!this.isAuthenticated) {

        this.authenticate(targetPath);

    }
  }

  private authenticate(targetPath: string) {

    if (environment.authentication.enabled) {

      this.router.navigate(['/login'], {

        queryParams: {
          'redirect_path': targetPath
        },

        skipLocationChange: true,

      });

    } else {
      
      this.authenticationState = {isAuthenticated: true, username: 'Max Mustermann', jwtAuthenticationToken: null};
    }
  }

  //retruns a PRomise
  validateUser(username: string, password: string): Promise<string> {

    return this.get_JWT_Token(username, password).then(token => {

      this.authenticationState = AuthenticationState.storeToken(token);

      return Promise.resolve(username);

    } ).catch(error => {

      if (error.status === 401) {
        return Promise.reject('Username or Password is invalid.');
      }
      return Promise.reject(error);
    });
  }

  logout() {
    this.authenticationState = null;
    setTimeout(() => {
      window.location.href = this.globalsService.homeUrl;
    }, 500);
  }

  private get_JWT_Token(username: string, password: string): Promise<JWT_AuthenticationToken> {

    return this.http.post<JWT_AuthenticationToken>(this.globalsService.baseHref + '/api/login',  {username: username, password: password}
    ).toPromise();

  }

  getToken(): Promise<JWT_AuthenticationToken> {
    // TODO implement token refresh
    if (this.authenticationState.jwtAuthenticationToken !== undefined) {
      return Promise.resolve(
        this.authenticationState.jwtAuthenticationToken
      );
    } else {
      return Promise.reject('NOT_LOGGED_IN');
    }
  }

  resetPassword(newPassword: string): Promise<string> {
    return this.http.post<any>(
      this.globalsService.baseHref + '/user/updatePassword',
      {newPassword: newPassword}
    ).toPromise()
  }

}
