<div class="header-general  flex justify-between">

  <h1 class="h3 m-0 p-2  ml-4" mat-dialog-title i18n="Add Inventory Component Dialog Title"><b>{{data.creatorName}} |
      {{"ADD_INVENTORY_SCAN_COMPONENT.HEADER" | translate}} - {{myDate | date}}</b> </h1>


  <div class="w3-large mr-4">
    <a (click)="Cancel()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
</div>

<div class="content" style="overflow-x: auto !important;">


  <div class="bigDiv" i18n="Add Inventory Component Input Div">
    <div appMakeSticky class="sticky-header-div">
      <table class="table" style="overflow-x:scroll">
        <thead>
          <tr>
            <th>{{"ADD_INVENTORY_SCAN_COMPONENT.TABLE.HEADER.NAME" | translate}}</th>
            <th>{{"ADD_INVENTORY_SCAN_COMPONENT.TABLE.HEADER.MANUFACTURER" | translate}}</th>
            <th>{{"ADD_INVENTORY_SCAN_COMPONENT.TABLE.HEADER.STOCK_VOLUME" | translate}}</th>
            <th>{{"ADD_INVENTORY_SCAN_COMPONENT.TABLE.HEADER.CONSUMPTION" | translate}}</th>
            <th>{{"ADD_INVENTORY_SCAN_COMPONENT.TABLE.HEADER.STOCK_LOCATION" | translate}}</th>
            <th>{{"ADD_INVENTORY_SCAN_COMPONENT.TABLE.HEADER.BATCH_LOT_NUMBER" | translate}}</th>
            <th>{{"ADD_INVENTORY_SCAN_COMPONENT.TABLE.HEADER.USED_FOR_CUSTOMER" | translate}}</th>
            <th>{{"ADD_INVENTORY_SCAN_COMPONENT.TABLE.HEADER.PURCHASE_DATE" | translate}}</th>
            <th>{{"ADD_INVENTORY_SCAN_COMPONENT.TABLE.HEADER.MANUFACTURE_DATE" | translate}}</th>
            <th>{{"ADD_INVENTORY_SCAN_COMPONENT.TABLE.HEADER.EXPIRY_DATE" | translate}}</th>
          </tr>
        </thead>
      </table>
    </div>
    <div mat-dialog-content>
      <table mat-table [dataSource]="dataSourceFormula">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="hidden"></th>
          <td mat-cell *matCellDef="let element; let index = index">
            <mat-hint *ngIf="element.nameError">
              {{"ADD_INVENTORY_SCAN_COMPONENT.TABLE.BODY.VALID_CHEMICAL_HINT" | translate}}
            </mat-hint>
            <mat-form-field appearance="none">
              <input matInput placeholder="Name" [matAutocomplete]="auto" [(ngModel)]="element.name"
                (focus)="checkValidity();afterEdit(index, element);"
                [formControl]="formArray.controls[index].get('name')">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" panelWidth="400px"
                (optionSelected)="afterEdit(index, element)">
                <mat-option *ngFor="let option of filteredDisplayArray[index] | async" [value]="option.name">
                  <b>{{option.name}}</b>: <i>{{option.manufacturer}}</i>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="manufacturer">
          <th mat-header-cell *matHeaderCellDef class="hidden"></th>
          <td mat-cell *matCellDef="let element;" style="width: 180px;">{{element.manufacturer}}</td>
        </ng-container>
        <ng-container matColumnDef="stockVolume">
          <th mat-header-cell *matHeaderCellDef class="hidden"></th>
          <td mat-cell *matCellDef="let element;let index = index">
            <mat-hint *ngIf="element.stockVolumeError">
              {{"ADD_INVENTORY_SCAN_COMPONENT.TABLE.BODY.VALID_QUANTITY_HINT" | translate}}
            </mat-hint>
            <mat-form-field appearance="none">
              <input type="Number" placeholder="Stock Volume"  onkeypress="return event.charCode >= 48" min="0.0001"
                aria-label="Number"  matInput (focus)="checkValidity();afterEdit(index, element);"
                (blur)="checkPositive(element.stockVolume, index, 'stockVolume');checkValidity();"  
                [formControl]="formArray.controls[index].get('stockVolume')"  [(ngModel)]="element.stockVolume"
                step="0.01">
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="consumption">
          <th mat-header-cell *matHeaderCellDef class="hidden"></th>
          <td mat-cell *matCellDef="let element;let index = index">
            <mat-hint *ngIf="element.quantityError">
              {{"ADD_INVENTORY_SCAN_COMPONENT.TABLE.BODY.VALID_QUANTITY_HINT" | translate}}
            </mat-hint>
            <mat-form-field appearance="none">
              <input type="Number" placeholder="Consumption"  onkeypress="return event.charCode >= 48" min="0.0001"
                aria-label="Number"  [(ngModel)]="element.quantity" (focus)="checkValidity();afterEdit(index, element);"
                matInput (blur)="checkPositive(element.quantity, index, 'quantity'); checkValidity();"  
                [formControl]="formArray.controls[index].get('quantity')" step="0.01">
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="stockLocation">
          <th mat-header-cell *matHeaderCellDef class="hidden"></th>
          <td mat-cell *matCellDef="let element;let index = index">
            <mat-form-field appearance="none">
              <input type="text" placeholder="Location" aria-label="Number" matInput [formControl]="formArray.controls[index].get('location')" (focus)="checkValidity();afterEdit(index,
                element);" (blur)="addToAutoComplete();checkValidity();" [matAutocomplete]="auto"
                [(ngModel)]="element.stockLocation">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredLocationArray[index] | async" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="lotNumber">
          <th mat-header-cell *matHeaderCellDef class="hidden"></th>
          <td mat-cell *matCellDef="let element;let index = index">
            <mat-form-field appearance="none">
              <input type="text" placeholder="Lot /Batch Number" aria-label="Number"
                 matInput (focus)="checkValidity();afterEdit(index, element);" (blur)="checkValidity();"
                [(ngModel)]="element.lotNumber">
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="usedForCustomer">
          <th mat-header-cell *matHeaderCellDef class="hidden"></th>
          <td mat-cell *matCellDef="let element;let index = index">
            <mat-form-field appearance="none">
              <mat-select multiple [formControl]="formArray.controls[index].get('customer')"
                 (focus)="checkValidity();afterEdit(index, element);" (blur)="checkValidity();"
                [(ngModel)]="element.usedForCustomer">
                <mat-option *ngFor="let brand of connectedBrands" [value]="brand.id">
                  {{brand.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="purchaseDate">
          <th mat-header-cell *matHeaderCellDef class="hidden"></th>
          <td mat-cell *matCellDef="let element;let index = index">
            <mat-form-field appearance="none">
              <input type="date" placeholder="Purchase Date" aria-label="Number"
                 matInput [formControl]="formArray.controls[index].get('purchaseDate')"
                 (focus)="checkValidity();afterEdit(index, element);" (blur)="addToAutoComplete();checkValidity();"
                [matAutocomplete]="auto" [(ngModel)]="element.purchaseDate" class="date">
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="manufactureDate">
          <th mat-header-cell *matHeaderCellDef class="hidden"></th>
          <td mat-cell *matCellDef="let element;let index = index">
            <mat-form-field appearance="none">
              <input type="date" placeholder="Manufacture Date" aria-label="Number"
                 matInput [formControl]="formArray.controls[index].get('manufactureDate')"
                 (focus)="checkValidity();afterEdit(index, element);" (blur)="addToAutoComplete();checkValidity();"
                [matAutocomplete]="auto" [(ngModel)]="element.manufactureDate" class="date">
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="expiryDate">
          <th mat-header-cell *matHeaderCellDef class="hidden"></th>
          <td mat-cell *matCellDef="let element;let index = index">
            <mat-form-field appearance="none">
              <input type="date" placeholder="Expiry Date" aria-label="Number"
                 matInput [formControl]="formArray.controls[index].get('expiryDate')"
                 (focus)="checkValidity();afterEdit(index, element);" (blur)="addToAutoComplete();checkValidity();"
                [matAutocomplete]="auto" [(ngModel)]="element.expiryDate" class="date">
            </mat-form-field>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>

  <div mat-dialog-actions i18n="Add Inventory Component Save-Cancel Buttons">
    <button class="btn btn-primary" (click)="checkValidity();Save();">{{"ADD_INVENTORY_SCAN_COMPONENT.SAVE_BUTTON" |
      translate}}</button>
    <button class="ml-2 btn btn-secondary" (click)="Cancel()">{{"ADD_INVENTORY_SCAN_COMPONENT.CANCEL_BUTTON" |
      translate}}</button>
  </div>
</div>
<ngx-loading [show]="isLoading"></ngx-loading>