import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalsService } from 'app/globals-services/globals.service';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class KeycloakGuard extends KeycloakAuthGuard {

  unprotectedURL = this.globalsService.unprotectedURL
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    public globalsService: GlobalsService,
    private translateService: TranslateService,
    private http: HttpClient,
  ) {
    super(router, keycloak);
  }


  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {


    // console.log(route.url);

    const isUnportectedUrl = route.url.filter((path)=>this.unprotectedURL.includes(path.path)).length >0
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated && !isUnportectedUrl) {
        this.keycloak.login({
        redirectUri: window.location.origin + '/dashboard',
        // redirectUri: window.location.origin + state.url,
      })
    }


    const loggedIn = await this.keycloak.isLoggedIn()

    if (loggedIn){
        this.globalsService.isLoggedIn=true
        this.http.get(this.globalsService.baseHref+ "/validateUser").subscribe((currentServerRes)=>{
            if(currentServerRes['STATUS']=='FAILURE'){
                this.http.get(this.globalsService.otherServer+ "/validateUser").subscribe((otherServerRes)=>{
                    // if the user is not recognised by the current server and is recognize by the other, redirect the user to the other otherwise send an alert and redirect to the login page.
                    if(otherServerRes['STATUS'] =='SUCCESS'){
                        document.location.href = this.globalsService.otherHomeUrl+'/dashboard';
                    } else {
                        alert('Your account cannot be recognize by our system. Please contact us via members@thebhive.net to fix your account.')

                        //need to logout the user otherwise, he/she cannot input new credentials because the cookies will always kick in and log in the user.
                        this.keycloak.logout(window.location.origin + '/login');
                    }

                });

            }
        });

    } else {
        this.globalsService.isLoggedIn=false
    }

    // // Get the roles required from the route.
    // const requiredRoles = route.data.roles;

    // // Allow the user to to proceed if no additional roles are required to access the route.
    // if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
    //   return true;
    // }

    // // Allow the user to proceed if all the required roles are present.
    // return requiredRoles.every((role) => this.roles.includes(role));

    return loggedIn;
  }
}
