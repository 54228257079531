import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CubejsApi, ResultSet } from '@cubejs-client/core';
import cubejs from '@cubejs-client/core';
import { EMPTY, Observable, Subject, Subscription, catchError, from, map, subscribeOn, switchMap, takeUntil, throwError, forkJoin, of } from 'rxjs';
import { DashboardQueryInterface, DashboardFilterInterface, VisualType, Configs } from './bhive-dashboard-config.interface';
import { DashboardBuilderJson, DashboardJSonModel } from './bhive-dashboard.interface';
import * as XLSX from 'xlsx';
import { tableHeadersTranslations, FilterKeys, SeriesColorMap } from './keyword-mapping'
import { MatDialog } from '@angular/material/dialog';
import { BhiveDashboardEnvironmentService } from './bhive-dashboard-environment.service';
import { DrillDownDialog } from './drilldown-dialog.component';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root'
})
export class BhiveDashboardService {

    private cubejs: CubejsApi
    public baseHref = this.environment.getApiUrl();;
    //private baseHref = 'http://localhost:8085';
    private cubeAPI = this.environment.getCubeApiUrl();
    // private cubeAPI = "http://localhost:4000/cubejs-api/v1";
    private complianceCriteria = null;
    private subscribe$ = new Subscription();
    private brandid: number | undefined;
    private filterSubscribe$ = new Subscription();
    public filterHistory: any[] = [];
    public translations: any

    constructor(private http: HttpClient, public dialog: MatDialog, private environment: BhiveDashboardEnvironmentService) { }
    cancelRequests() {
        this.subscribe$.unsubscribe();
        this.filterSubscribe$.unsubscribe();
    }
    private getAuthorization(factoryId?: number, brandId?: number, onlyScope?: boolean, includeCommodities?: boolean): Promise<void> {
        return new Promise<void>((resolve) => {
            let req = this.http.get<string>(this.baseHref + '/getFactoryCubeToken/' + factoryId + '/' + brandId + '/' + onlyScope + '/' + includeCommodities);
            // this.unsubscribe$ = req;
            this.subscribe$ = req.subscribe((res: any) => {
                this.cubejs = cubejs(
                    async () => res['token'],
                    { apiUrl: this.cubeAPI }
                );
                resolve();
            },
                (err) => {
                    throwError(new Error('An error ocurred while getting the Cube token!'));
                });
        });
    }
    async getCubeJSData(query: Object, factoryId?: number, brandId?: number, onlyScope?: boolean, includeCommodities?: boolean): Promise<any> {
        return this.getAuthorization(factoryId, brandId, onlyScope, includeCommodities).then(() => this.cubejs.load(query))
            .then(resultSet => resultSet)
            .catch(error => Promise.reject(error));
    }



    private changeKey(originalKey: string, newKey: string, arr: Array<Object>) {
        var newArr = [];
        for (var i = 0; i < arr.length; i++) {
            var obj: any = arr[i];
            obj[newKey] = (newKey == "value" && obj[originalKey] === null) ? 0 : obj[originalKey];
            delete (obj[originalKey]);
            newArr.push(obj);
        }
        return newArr;
    }

    getDashboardConfigs(): Observable<Configs> {
        return this.http.get(this.baseHref + '/getDashboardConfigs')
            .pipe(
                map((res: any) => {
                    const configs: Configs = {
                        queries: res['queries'].map((element: any) => ({
                            Id: element['id'],
                            Name: element['name'],
                            Description: element['description'],
                            QueryJSON: element['query_json'],
                            Active: element['active'],
                            VisualType: element['default_visual_type_id'],
                            NeedsCompliance: element['needs_compliance'],
                            RawQueryId: element['raw_query_id'],
                            DrillQueryId: element['drill_query_id'],
                            HasTarget: element['has_target'],
                            CanCompare: element['can_compare'],
                            GetLatestChemicals: element['get_latest_chemicals'],
                            FilterConfig: JSON.parse(element['filter_config']),
                            Configs: element['configs'].map((el: any) => ({
                                Code: el['Code'],
                                QueryPivotConfig: JSON.parse(el['QueryPivotConfig']),
                                Groupings: JSON.parse(el['Groupings']),
                                InteractionConfig: JSON.parse(el['InteractionConfig'])
                            }))
                        })),
                        filters: res['filters'].map((element: any) => ({
                            Id: element['id'],
                            Type: element['type'],
                            ShowName: element['name'],
                            Name: element['cube_filter_name'],
                            QueryJSON: element['query_json']
                        })),
                        standards: res['standards'].map((element: any) => ({
                            Name: element['name'],
                            Id: element['id'],
                            DisplayName: element['displayName']
                        })),
                        visuals: res['visuals'].map((element: any) => ({
                            Id: element['id'],
                            Name: element['name'],
                            Code: element['code']
                        }))
                    };

                    return configs;
                })
            );
    }
    async getChartData(queryObj: DashboardQueryInterface, visualCode: string, filters: Array<any>, organization_id: any): Promise<any> {
        if (organization_id)
            this.brandid = organization_id;
        const pivotConfig: Object = queryObj.Configs.find(el => el.Code === visualCode)!.QueryPivotConfig;
        let query = JSON.parse(queryObj.QueryJSON);
        if (query.filters)
            query.filters.push(...this.prepareCubeJsFilters(filters, queryObj));
        try {
            if (queryObj.GetLatestChemicals) {
                query = await this.getFormulasFirst(query);
            }
            const data = await this.getCubeJSData(query, undefined, undefined, true, false);
            const result = await this.transformData(pivotConfig, visualCode, data, queryObj);
            const meta = data.tableColumns()
            return { result, data, meta };
        } catch (error) {
            console.error('Error in getChartData:', error);
            throw error; // Rethrow the error for further handling
        }
    }
    getChartTarget(queryObj: DashboardQueryInterface): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.http.get(this.baseHref + '/getQueryTarget/' + queryObj.Id)
                .subscribe(
                    data => {
                        // Resolve the promise with the received data
                        resolve(data);
                    }
                );
        });
    }
    async changeCompliance(newCompliance: any) {
        if (newCompliance.hasOwnProperty('id')) {
            this.complianceCriteria = newCompliance['id'];
        }
        else {
            this.complianceCriteria = newCompliance['name'];
        }
    }
    getVisualPreview(queryObj: DashboardQueryInterface, visualCode: string): Promise<any> {
        return this.http.get<string>(this.baseHref + '/getVisualPreview/' + queryObj.Id + '/' + visualCode)
            .toPromise();
    }

    fillFilterWithData(filter: DashboardFilterInterface): Observable<any[]> {
        if (filter.ShowName === "Standards") {
            return this.http.get<any>(this.baseHref + '/report/standards').pipe(
                map((response: any) => response['standards']),
                // catchError(this.handleError)
            );
        } else {
            return from(this.getCubeJSData(filter.QueryJSON, undefined, undefined, true, false)).pipe(
                map((result: any) => {
                    let data = result.tablePivot();
                    return this.changeKey(filter.Name, filter.ShowName, data)
                }),
                // catchError(this.handleError)
            );
        }
    }


    saveDashboard(dashboard: DashboardBuilderJson): Observable<any> {
        const dashJson = {
            items: dashboard.items,
            filters: dashboard.filters
        };

        const reqObj = {
            id: dashboard.id,
            name: dashboard.name,
            dashboardJSon: dashJson,
            isHome: dashboard.isHome
        };

        return this.http.post(this.baseHref + '/saveDashboard', reqObj);
    }

    saveQueryTarget(queryId: number, target: number): Observable<any> {
        const reqObj = {
            queryId: queryId,
            target: target
        };

        return this.http.post(this.baseHref + '/saveOrganizationQueryTarget', reqObj);
    }

    getDashboard(isHome: boolean, dashboardID?: number): Observable<any> {
        return this.http.get(this.baseHref + '/getDashboard/' + isHome + '/' + (dashboardID || ''))
            // No need to create a Subject, you can return the HTTP request as an observable
            // Also, use a ternary operator to handle optional dashboardID
            // Handle error cases as well
            .pipe(
                catchError(error => {
                    // Handle the error (e.g., log it or show a message)
                    console.error('Error in getDashboard:', error);
                    throw error; // Rethrow the error to the caller
                })
            );
    }

    getAllDashboards(): Observable<any> {
        return this.http.get(this.baseHref + '/getAllDashboards')
            .pipe(
                catchError(error => {
                    // Handle the error (e.g., log it or show a message)
                    console.error('Error in getDashboard:', error);
                    throw error; // Rethrow the error to the caller
                })
            );
    }

    drillDown(parentQuery: DashboardQueryInterface, visualCode: string, filters: Array<any>): Observable<any[]> {
        return this.getNewQueryObject(parentQuery.DrillQueryId!).pipe(
            switchMap(response => {
                return this.getChartData(response, visualCode, filters, this.brandid);
            })
        );
    }
    exportData(type: string, queryObj?: DashboardQueryInterface, filters?: Array<any>, dataPopUp?: Array<any>) {
        var subject = new Subject<Array<any>>();
        if (queryObj && filters) {
            switch (type) {
                case 'RAW':
                    let queryId: number = queryObj.RawQueryId || 0;
                    if (queryId !== 0) {
                        this.getNewQueryObject(queryId).subscribe(response => {
                            this.getChartData(response, "table", filters, this.brandid)
                                .then((data: any) => {

                                    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
                                    const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
                                    XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
                                    XLSX.writeFile(myworkbook, 'test' + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });

                                })
                        });

                    }
                    else {
                        this.getChartData(queryObj, "table", filters, this.brandid)
                            .then(async (data: any) => { // Marking the function as async
                                if (this.isSpecialQuery(queryObj)) {
                                    if (queryObj.Name == 'Otto Activity Monitoring') {
                                        let excelData = [];
                                        excelData = data.result.map((obj: any) => {
                                            let commentDetails = obj.comment.commentDetails.map((comment: any) => {
                                                const formattedDate = new Date(comment.createdOn).toISOString().slice(0, 10);
                                                return `${formattedDate} - ${comment.userName}: ${comment.comment}`;
                                            });
                                            return {
                                                bhiveid: obj['BrandActivityWithCompliance.supplier_id'],
                                                zlkz: obj['BrandActivityWithCompliance.zlzk'],
                                                fid: obj['BrandActivityWithCompliance.fid'],
                                                vpid: obj['BrandActivityWithCompliance.vp_id'],
                                                factory_name: obj['BrandActivityWithCompliance.supplier_name'],
                                                country: obj['BrandActivityWithCompliance.country'],
                                                otto_scope: obj['BrandActivityWithCompliance.brand_scope'],
                                                last_scan_date: obj['BrandActivityWithCompliance.last_scan_date'] ? new Date(obj['BrandActivityWithCompliance.last_scan_date']).toISOString().slice(0, 10) : null,
                                                active: obj['BrandActivityWithCompliance.active'],
                                                connected: obj['BrandActivityWithCompliance.connected'],
                                                year: obj['BrandActivityWithCompliance.year'],
                                                nominated_by_ogc: obj['BrandActivityWithCompliance.nominated_by_vendor'],
                                                agency: obj['BrandActivityWithCompliance.nominated_by_agency'],
                                                q1_count: obj['BrandActivityWithCompliance.q1_count'],
                                                q1: obj['BrandActivityWithCompliance.q1'],
                                                q2_count: obj['BrandActivityWithCompliance.q2_count'],
                                                q2: obj['BrandActivityWithCompliance.q2'],
                                                q3_count: obj['BrandActivityWithCompliance.q3_count'],
                                                q3: obj['BrandActivityWithCompliance.q3'],
                                                q4_count: obj['BrandActivityWithCompliance.q4_count'],
                                                q4: obj['BrandActivityWithCompliance.q4'],
                                                comment: commentDetails.join(', ') // Combine comment details into a string
                                            };
                                        });
                                        const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
                                        const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
                                        const headers = [
                                            "Bhive ID",
                                            "ZLKZ",
                                            "FID",
                                            "VPID",
                                            "Factory Name",
                                            "Country",
                                            "Otto Scope",
                                            "Last Scan Date",
                                            "Active",
                                            "Connected",
                                            "Year",
                                            "Nominated by OGC",
                                            "Agency",
                                            "CIL uploaded Q1",
                                            "Q1",
                                            "CIL uploaded Q2",
                                            "Q2",
                                            "CIL uploaded Q3",
                                            "Q3",
                                            "CIL uploaded Q4",
                                            "Q4",
                                            "Comment"
                                        ];
                                        XLSX.utils.sheet_add_aoa(myworksheet, [headers], { origin: "A1" });
                                        XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
                                        XLSX.writeFile(myworkbook, queryObj.Name + ' ' + new Date().toLocaleDateString() + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
                                    }

                                    else {
                                        if (queryObj.Name == "Quarterly Inventory Overview" || queryObj.Name == "Monthly Inventory Overview") {
                                            let excelData = [];
                                            excelData = data.result.map((obj: any) => {
                                                let commentDetails = obj.comment.commentDetails.map((comment: any) => {
                                                    const formattedDate = new Date(comment.createdOn).toISOString().slice(0, 10);
                                                    return `${formattedDate} - ${comment.userName}: ${comment.comment}`;
                                                });

                                                return {
                                                    ...obj, // spread the original object properties
                                                    comment: commentDetails.join(', ') // add the comment field with joined comment details
                                                };
                                            });
                                            const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
                                            const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
                                            const headers = queryObj.Name != "Quarterly Inventory Overview" ? [
                                                "Factory",
                                                "Scanned",
                                                "Marked as complete",
                                                "Total Number of Chemicals",
                                                "No. of Compliant Chemicals",
                                                "Compliance by Count (%)",
                                                "Compliance by consumption (%)",
                                                "Comment"

                                            ] : [
                                                "Factory",
                                                "Last Scan Date for selected Quarter",
                                                "Marked as complete",
                                                "Total Number of Chemicals",
                                                "No. of Compliant Chemicals",
                                                "Compliance by Count (%)",
                                                "Compliance by consumption (%)",
                                                "Comment"
                                            ];
                                            XLSX.utils.sheet_add_aoa(myworksheet, [headers], { origin: "A1" });
                                            XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
                                            XLSX.writeFile(myworkbook, queryObj.Name + ' ' + new Date().toLocaleDateString() + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
                                        }
                                        else {   
                                            if (queryObj.Name == "Higg Overview" || queryObj.Name == "Wastewater Overview" || queryObj.Name == "Wastewater Test Reports (v2.1) - Result Analysis") {
                                                let excelData = [];
                                                excelData = data.result.map((obj: any) => {
                                                    let commentDetails = obj.comment.commentDetails.map((comment: any) => {
                                                        const formattedDate = new Date(comment.createdOn).toISOString().slice(0, 10);
                                                        return `${formattedDate} - ${comment.userName}: ${comment.comment}`;
                                                    });

                                                    return {
                                                        ...obj, // spread the original object properties
                                                        comment: commentDetails.join(', ') // add the comment field with joined comment details
                                                    };
                                                });
                                                const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
                                                const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
                                                XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
                                                XLSX.writeFile(myworkbook, queryObj.Name + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' }); 

                                            }
                                            else {
                                                const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.result);
                                                const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
                                                XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
                                                XLSX.writeFile(myworkbook, queryObj.Name + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
                                            }
                                        }
                                    }

                                }
                                else {
                                    data.result = this.translateColumns(data.result, [], queryObj);
                                    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.result.data);
                                    const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
                                    XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
                                    XLSX.writeFile(myworkbook, queryObj.Name + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
                                }
                            })
                    }
                    break;
                default:
                    break;
            }
        }
        else {
            if (dataPopUp) {
                const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataPopUp);
                const myworkbook: XLSX.WorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(myworkbook, myworksheet, 'Sheet1');
                XLSX.writeFile(myworkbook, 'test' + EXCEL_EXTENSION, { bookType: 'xlsx', type: 'file' });
            }
        }
    }
    getNewQueryObject(queryId: number): Observable<DashboardQueryInterface> {
        return this.http.get(this.baseHref + '/getQueryObject/' + queryId)
            .pipe(map((res: any) => {
                return {
                    Id: res['queries'][0]['id'],
                    Name: res['queries'][0]['name'],
                    Description: res['queries'][0]['description'],
                    QueryJSON: res['queries'][0]['query_json'],
                    Active: res['queries'][0]['active'],
                    VisualType: res['queries'][0]['default_visual_type_id'],
                    NeedsCompliance: res['queries'][0]['needs_compliance'],
                    RawQueryId: res['queries'][0]['raw_query_id'],
                    DrillQueryId: res['queries'][0]['drill_query_id'],
                    HasTarget: res['queries'][0]['has_target'],
                    CanCompare: res['queries'][0]['can_compare'],
                    GetLatestChemicals: res['queries'][0]['get_latest_chemicals'],
                    FilterConfig: JSON.parse(res['queries'][0]['filter_config']),
                    Configs: res['queries'][0]['configs'].map((el: any) => ({
                        Code: el['Code'],
                        QueryPivotConfig: JSON.parse(el['QueryPivotConfig']),
                        Groupings: JSON.parse(el['Groupings']),
                        InteractionConfig: JSON.parse(el['InteractionConfig'])
                    }))
                };
            }));
    }
    private prepareCubeJsFilters(filters: Array<any>, query: DashboardQueryInterface): Array<Object> {

        const replacedFilters = filters.map(filter => {
            const { meta, ...modifiedObject } = filter;
            return this.replaceKeys(modifiedObject, FilterKeys)
        });
        let newFilters: Array<any> = [];
        replacedFilters.forEach((f) => {
            const objKeyName: string = Object.keys(f)[0];
            const alternativeName = f.hasOwnProperty('meta') ? f['meta' as keyof typeof f] : objKeyName;
            if (query.FilterConfig) {
                const cubeName = query.FilterConfig.hasOwnProperty(objKeyName) ? query.FilterConfig[objKeyName as keyof typeof query.FilterConfig] : alternativeName;
                const objValue = f[objKeyName as keyof typeof f];
                if (query.FilterConfig[objKeyName]) {
                    if (objKeyName.includes('date') && Object.keys(query.FilterConfig).some(value => value.toLowerCase().includes('date'))) {
                        if (cubeName.includes('BrandActivityWithCompliance.year')) {
                            let std = new Date(objValue[0]);
                            let edd = new Date(objValue[1]);
                            let years = [];
                            for (let year = std.getFullYear(); year <= edd.getFullYear(); year++) {
                                years.push(year.toString());
                            }
                            newFilters.push({
                                "member": cubeName,
                                "operator": "equals",
                                "values": years
                            })
                        }
                        else {
                            newFilters.push({
                                "member": cubeName,
                                "operator": "inDateRange",
                                "values": objValue
                            })
                        }
                    }
                    else {
                        if (objKeyName.includes('fabricRollId') && 'fabricRollId' in query.FilterConfig) {
                            newFilters.push({
                                "member": cubeName,
                                "operator": "contains",
                                "values": [objValue]
                            })

                        }
                        else {
                            if (objKeyName.includes('identification_supplier_id') && 'identification_supplier_id' in query.FilterConfig) {
                                if (query.FilterConfig['identification_supplier_id'] == 'SupplierIdentification.identification_supplier_id') {
                                    newFilters.push(
                                        {
                                            "member": "SupplierIdentification.identification_name",
                                            "operator": "equals",
                                            "values": ['SCOPE']
                                        },
                                        {
                                            "member": cubeName,
                                            "operator": "equals",
                                            "values": objValue
                                        },
                                        {
                                            "member": "SupplierIdentification.brand_id",
                                            "operator": "equals",
                                            "values": [this.brandid?.toString()]
                                        },
                                    )
                                }
                                else {
                                    newFilters.push({
                                        "member": cubeName,
                                        "operator": "equals",
                                        "values": objValue
                                    })
                                }

                            }
                            else {
                                if (objKeyName.includes('usedFor') && 'usedFor' in query.FilterConfig) {
                                    if (query.FilterConfig['usedFor'] == 'UsedFor.brand_name') {
                                        newFilters.push({
                                            "member": "UsedFor.used_for_brand_id",
                                            "operator": "equals",
                                            "values": [this.brandid?.toString()],

                                        },
                                            {
                                                "member": cubeName,
                                                "operator": "equals",
                                                "values": objValue
                                            }
                                        )
                                    }
                                    else {
                                        newFilters.push({
                                            "member": "Activity.used_for_brand",
                                            "operator": "equals",
                                            "values": [this.brandid?.toString()],

                                        })
                                    }
                                }

                                else {
                                    if (!objKeyName.includes('date'))
                                        newFilters.push({
                                            "member": cubeName,
                                            "operator": "equals",
                                            "values": objValue
                                        })
                                }
                            }
                        }
                    }
                }
            }
        })
        return newFilters;

    }

    private transformData(pivotConfig: Object, visualType: string, data: ResultSet<any>, queryObj: DashboardQueryInterface) {
        let transformedData: any = [];
        switch (visualType) {
            case VisualType.BarChart: {
                transformedData = this.transformToGroupBarChart(pivotConfig, data, queryObj)
                break;
            }
            case VisualType.GroupBarChart: {
                transformedData = this.transformToGroupBarChart(pivotConfig, data, queryObj)

                break;
            }
            case VisualType.StackedBarChart: {
                transformedData = this.transformToGroupBarChart(pivotConfig, data, queryObj)

                break;
            }
            case VisualType.LineChart: {
                transformedData = this.transformToLineChart(pivotConfig, data, queryObj)
                break;
            }
            case VisualType.MultilineChart: {
                transformedData = this.transformToMultiLineChart(pivotConfig, data, queryObj)

                break;
            }
            case VisualType.PieChart: {
                transformedData = this.transformToPieChart(pivotConfig, data, queryObj)
                break;
            }
            case VisualType.Gauge: {
                transformedData = this.transformToGaugeChart(pivotConfig, data, queryObj)
                break;
            }
            case VisualType.WorldMap: {
                transformedData = this.transformToWorldMapChart(pivotConfig, data, queryObj)
                break;
            }
            case VisualType.Table: {
                transformedData = this.transformToTableChart(pivotConfig, data, queryObj)
                    .then(result => {
                        return result;
                    })
                    .catch(error => {
                        console.error("Error transforming data:", error);
                        return [];
                    });
                break;
            }
            case VisualType.NumberCard: {
                transformedData = this.transformToNumberChart(pivotConfig, data, queryObj);
                break;
            }
            case VisualType.AreaChart: {
                transformedData = this.transformToAreaChart(pivotConfig, data, queryObj);
                break;
            }
            default:
                transformedData = data;
                break;
        }
        return transformedData;
    }

    private transformToGroupBarChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {
        // Extract unique titles from the series
        const uniqueTitles: string[] = data.series(pivotConfig).map((s: any) => s.title.split(',')[0]).filter((title: string, index: number, self: string[]) => self.indexOf(title) === index);

        // Create a map to store the stack levels for each unique title
        const stackLevels: { [title: string]: number } = {};
        uniqueTitles.forEach((title: string, index: number) => {
            stackLevels[title] = index;
        });
        let result: Object = {
            chartType: 'bar',
            chartLabels: data.chartPivot(pivotConfig).map((c: any) => {
                let label = c.x;
                let shortLabel: any;
                if (this.isValidISODateTime(label))
                    shortLabel = label.slice(0, 10);
                else {
                    if (label.length > 15) {
                        shortLabel = label.substring(0, 15) + '...';
                    }
                }
                const granularity = data.loadResponse.results[0].query.timeDimensions[0]?.granularity;
                if (granularity === 'quarter') {
                    const date = new Date(label);
                    const quarter = Math.floor((date.getMonth() / 3) + 1);
                    const year = date.getFullYear();
                    return { short: `${year}-Q${quarter}`, long: `${year}-Q${quarter}` };
                } else {
                    // Return the original label if granularity is not 'quarter'
                    return { short: shortLabel, long: label };
                }


            }),
            chartData: data.series(pivotConfig).map((s: any, index: number) => {
                const color = this.getColorBySeriesName(s.key);
                const stack = stackLevels[s.key.split(',')[0]] || null;
                return stack ? {
                    label: this.findTranslation(s.ke, queryObj.Id),
                    backgroundColor: color[index % color.length],
                    borderColor: color[index % color.length],
                    data: s.series.map((r: { value: any; }) => Number(r.value?.toFixed(1))),
                    yValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((y: any) => y.yValues),
                    xValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((x: any) => x.xValues),
                    stack: stack
                } : {
                    label: this.findTranslation(s.key, queryObj.Id),
                    backgroundColor: color[index % color.length],
                    borderColor: color[index % color.length],
                    data: s.series.map((r: { value: any; }) => Number(r.value?.toFixed(1))),
                    yValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((y: any) => y.yValues),
                    xValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((x: any) => x.xValues)
                };

            })
        };
        return result;
    }

    private transformToLineChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {

        let result: Object = {
            chartType: 'line',
            chartLabels: data.chartPivot(pivotConfig).map((c: any) => c.x),
            chartData: data.series(pivotConfig).map((s: any, index: number) => {
                const color = this.getColorBySeriesName(s.key);
                return {
                    label: s.title,
                    backgroundColor: color[index % color.length],
                    borderColor: color[index % color.length],
                    data: s.series.map((r: { value: any; }) => r.value),
                    yValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((y: any) => y.yValues),
                    xValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((x: any) => x.xValues)
                }
            })
        };
        return result;
    }

    private transformToMultiLineChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {
        let result: Record<string, any> = {
            chartType: 'bar',
            chartLabels: data.chartPivot(pivotConfig).map((c: any) => {
                let label = c.x;
                let shortLabel = label;

                if (label.length > 15) {
                    shortLabel = label.substring(0, 15) + '...';
                }

                // Return an array with both the short and long labels
                return { short: shortLabel, long: label }
            }),
            chartData: data.series(pivotConfig).map((s: any, index: number) => {
                const color = this.getColorBySeriesName(s.key);
                return {
                    label: s.title,
                    data: s.series.map((r: { value: any; }) => r.value),
                    backgroundColor: color[index % color.length],
                    borderColor: color[index % color.length],
                    pointBackgroundColor: color[index % color.length],
                    pointBorderColor: color[index % color.length],
                    yValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((y: any) => y.yValues),
                    // xValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((x: any) => x.xValues)
                }
            })
        };
        result['chartData'][0].order = 1;
        result['chartData'][1].order = 0;
        result['chartData'][1].type = 'line';
        if (result['chartData'].length > 2) {
            result['chartData'][3].order = 0;
            result['chartData'][3].type = 'line';
        }
        return result;
    }

    private async transformToPieChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {
        let transformedData: any[];
        if (this.isSpecialQuery(queryObj)) {
            transformedData = await this.specialQueries(queryObj, data.tablePivot()) as any[];
        } else {
            transformedData = await this.prepareDataForPieChart(data.series(pivotConfig), queryObj);
        }


        let datasets = [];
        let allLabels: any[] = [];

        if (transformedData.some((s: any) => Array.isArray(s.value))) {
            // Handle nested data by creating a separate dataset for each date or category
            transformedData.forEach((item: any) => {
                let labels = item.value.map((v: any) => v.label);
                let values = item.value.map((v: any) => v.value);

                // Collect all labels to ensure colors match across datasets
                allLabels = allLabels.concat(labels.filter((label: any) => !allLabels.includes(label)));

                datasets.push({
                    label: item.label,
                    backgroundColor: labels.map((key: any, index: number) => { const color = this.getColorBySeriesName(key); return color[index % color.length] }),
                    // borderColor: labels.map((key: any, index: number) => { const color = this.getColorBySeriesName(key); return color[index % color.length] }),
                    // backgroundColor: labels.map((label:any) => this.getColorBySeriesName(label)),
                    data: values
                });
            });
        } else {
            // Handle flat data by creating a single dataset
            let labels = transformedData.map((s: any) => s.label);
            let values = transformedData.map((s: any) => s.value);
            allLabels = labels;

            datasets.push({
                label: queryObj.Name,
                backgroundColor: labels.map((key: any, index: number) => { const color = this.getColorBySeriesName(key); return color[index % color.length] }),
                borderColor: labels.map((key: any, index: number) => { const color = this.getColorBySeriesName(key); return color[index % color.length] }),
                data: values
            });
        }

        let result = {
            chartType: 'pie',
            chartLabels: allLabels.map(label => {
                let shortLabel = label.length > 15 ? label.substring(0, 15) + '...' : label;
                return { short: shortLabel, long: label };
            }),
            chartData: datasets
        };

        return result;
    }

    private prepareDataForPieChart(chartData: any, queryObj: any) {
        const transformedArray: { label: string; value: number }[] = [];
        chartData.forEach((item: any) => {
            if (item.series) {
                item.series.forEach((serie: any) => {
                    const label = serie.x == "" ? this.findTranslation(item.key, queryObj.Id) : this.findTranslation(serie.x, queryObj.Id);
                    if (label != undefined)
                        transformedArray.push({ label, value: serie.value });
                });
            } else {
                // Handle flat data case
                transformedArray.push({ label: item.label, value: item.value });
            }
        });
        return transformedArray;
    }



    private transformToGaugeChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {

        let result: Object = {
            chartType: 'doughnut',
            chartLabels: data.chartPivot(pivotConfig).map((c: any) => c.x),
            chartData: data.series(pivotConfig).map((s: any, index: number) => {
                const color = this.getColorBySeriesName(s.key);
                return {
                    label: s.title,
                    backgroundColor: color[index % color.length],
                    borderColor: color[index % color.length],
                    data: s.series.map((r: { value: any; }) => r.value),
                    yValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((y: any) => y.yValues),
                    xValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((x: any) => x.xValues)
                }
            })
        };
        return result;
    }

    private transformToWorldMapChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {
        let result: any = {
            chartType: 'map',
            chartLabels: data.chartPivot(pivotConfig).map((c: any) => {
                let label = c.x;
                let shortLabel = label.length > 15 ? label.substring(0, 15) + '...' : label;

                // Return an array with both the short and long labels
                return { short: shortLabel, long: label };
            }),
            chartData: {}
        };

        let aggData = this.isSpecialQuery(queryObj) ? this.specialQueries(queryObj, data.tablePivot()) : data.chartPivot(pivotConfig);

        result.chartData = aggData;

        return result;
    }


    private async transformToTableChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {
        let result: any;
        if (this.isSpecialQuery(queryObj)) {
            result = await this.specialQueries(queryObj, data.tablePivot(pivotConfig));
        } else {
            result = data.tablePivot(pivotConfig);
        }
        return result;
    }

    private transformToNumberChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {

        let numericValues = data
            .seriesNames()
            .map((s: any) => {
                return {
                    numericValues: data.totalRow()[s.key],
                    yValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((y: any) => y.yValues)
                }
            })
        return numericValues;
    }
    private transformToAreaChart(pivotConfig: Object, data: any, queryObj: DashboardQueryInterface) {
        let result: Object = {
            chartType: 'line',
            chartLabels: data.chartPivot(pivotConfig).map((c: any) => {
                let label = c.x;
                let shortLabel = label;

                if (label.length > 15) {
                    shortLabel = label.substring(0, 15) + '...';
                }

                // Return an array with both the short and long labels
                return { short: shortLabel, long: label }
            }),
            chartData: data.series(pivotConfig).map((s: any, index: number) => {
                const color = this.generateRandomColors();
                return {
                    label: s.title,
                    backgroundColor: color[index % color.length],
                    borderColor: color[index % color.length],
                    pointBackgroundColor: color[index % color.length],
                    pointBorderColor: color[index % color.length],
                    fill: true,
                    data: s.series.map((r: { value: any; }) => r.value),
                    yValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((y: any) => y.yValues),
                    // xValue: data.seriesNames(pivotConfig).filter((el: { key: any; }) => el.key === s.key).map((x: any) => x.xValues)
                }
            })
        };
        return result;
    }

    calculateFontSize(width: number | undefined, height: number | undefined, dimension: number, max: number, min: number, perOrpx: string) {
        if (width && height) {
            const minDimension = Math.min(width, height);
            const basePercentage = minDimension * dimension;

            const maxPercentage = max;
            const minPercentage = min;

            let fontSizePercentage = basePercentage;

            if (fontSizePercentage > maxPercentage) {
                fontSizePercentage = maxPercentage;
                return `${fontSizePercentage}` + perOrpx
            }
            else if (fontSizePercentage < minPercentage) {
                fontSizePercentage = minPercentage;
                return `${fontSizePercentage}` + perOrpx
            }

            return `${fontSizePercentage}px`;
        } else {
            return '100%';
        }
    }
    private getColorBySeriesName(seriesName: string): Array<string> {
        const matchingSerieName = Object.keys(SeriesColorMap).find(key => seriesName.includes(key)) || '';
        return SeriesColorMap[matchingSerieName] || ['gray']; // Default color for unknown series names
    }
    private generateRandomColors() {
        let color = '';
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        color = `rgba(${r},${g},${b}, 0.6)`;
        return color;
    }
    // TODO:Generilize these replase keys funkctions. Function to be modified are replaseKeys, translateColumns, findTranslateKey, changeKey. There some things in common between these functions and there must be found a way how to unify/refactor them.
    replaceKeys(original: Array<any>, keyMapping: any): Array<any> {
        return Object.entries(original).reduce((result, [key, value]) => {
            const newKey = keyMapping[key as keyof typeof keyMapping] || key;
            result[newKey] = value;
            return result;
        }, {} as Array<any>);
    }
    //translates the columns of the table and fill the table with the new columns and data
    translateColumns(data: any, tableColumns: any, queryObj?: DashboardQueryInterface) {
        let queryId = queryObj ? queryObj.Id : 0;
        let columnsConfig: any = queryObj?.Configs.find(el => el.Code === VisualType.Table)!.InteractionConfig;
        tableColumns = Object.keys(Object.assign({}, ...data))
            .filter(column => !this.checkColumnProperty(columnsConfig, column, 'hidden', true)) // Filter out hidden columns
            .map(column => {
                // Check if there is a translation for the column, otherwise use the original column name
                let translatedColumn = this.findTranslation(column, queryId) != column ? this.findTranslation(column, queryId) : undefined;
                if (!translatedColumn) {
                    for (let keyword of this.translations.map((item: any) => item.keyword)) {
                        if (column.includes(keyword)) {
                            if (column.includes('Higg.year_self_assessment_date') || column.includes('Higg.year_verf_assessment_date') || column.includes('WasteWaterCombined.latest_date') || column.includes('WasteWaterCombined.latest_source'))
                                translatedColumn = column.split(',')[0].trim() != '' ? column.split(',')[0].trim() + ' ' + this.findTranslation(keyword, queryId) || column : this.findTranslation(keyword, queryId) || column

                            else
                                translatedColumn = this.findTranslation(keyword, queryId) || column;
                            break;
                        }
                        else {
                            translatedColumn = column;
                        }
                    }
                }
                return { key: column, name: translatedColumn };

            });
        const datasource = data.map((row: any) => {
            // First, copy all existing data fields to the newRow
            const newRow: any = { ...row };

            // Then, map specified keys to new names and overwrite or add new keys in newRow
            tableColumns.forEach((column: any) => {
                if (column.key in row) {
                    newRow[column.name] = row[column.key]; // Map original key to new key
                    if (column.name !== column.key) {
                        delete newRow[column.key]; // Optionally delete the original key
                    }
                }
            });

            return newRow;
        });

        let clickableColumns = tableColumns.filter((column: any) => this.checkColumnProperty(columnsConfig, column.key, 'clickable', true))
            .map((column: any) => {
                return { key: column.key, name: column.name, action: columnsConfig?.columns[column.key].action, payload: columnsConfig?.columns[column.key].payload, condition: columnsConfig?.columns[column.key].condition }
            });

        return { columns: tableColumns, data: datasource, clickableColumns: clickableColumns }
    }

    checkColumnProperty(columnsConfig: unknown, columnName: string, propertyName: string, propertyValue: any): boolean {
        // First, check if columnsConfig is an object
        if (columnsConfig !== null && typeof columnsConfig === 'object') {
            // Cast to any temporarily to access potential properties
            const config = columnsConfig as any;
            // Check if 'columns' exists and is an object
            if (config.columns && typeof config.columns === 'object') {
                // Check if the specified columnName exists within columns
                if (config.columns[columnName] && typeof config.columns[columnName] === 'object') {
                    // Finally, check if 'hidden' exists as a boolean within the column
                    const column = config.columns[columnName];
                    return propertyName in column && column[propertyName] === propertyValue;
                }
            }
        }
        return false;
    }

    findTranslation(translationValue: string, queryId: any): string | undefined {
        let translationData = this.translations.filter((item: any) => item.keyword === translationValue);

        if (translationData.length > 0) {
            if (translationData.length === 1) {
                return translationData[0].translation;
            }

            let translationDataWithQueryId = translationData.filter((item: any) => item.query_id === queryId);

            if (translationDataWithQueryId.length > 0) {
                return translationDataWithQueryId[0].translation;
            }

            return translationData[0].translation;
        }

        return translationValue;
    }

    latestFormulas = []
    private getFormulasFirst(query: any) {
        let chemicalQuery = {
            "dimensions": [
                "Formulas.organization_id",
                "Formulas.chemical_identifier"
            ],
            "timeDimensions": [
                {
                    "dimension": "Formulas.scan_date"
                }
            ],
            "order": [
                [
                    "Formulas.chemical_identifier",
                    "asc"
                ]
            ],
            "filters": [...query.filters],
            "measures": [
                "Formulas.latest_formula_in_range"
            ]
        };
        return this.getCubeJSData(chemicalQuery, undefined, undefined, true, false)
            .then(data => {
                let tempData = data.chartPivot();
                tempData = this.changeKey("Formulas.latest_formula_in_range", "latest_formula", tempData);
                this.latestFormulas = tempData.map(function (el: any) { return el.latest_formula; });
                let latestChemicalsIds: any[] = [];
                let newFilters;
                for (let obj of this.latestFormulas as any[]) {
                    latestChemicalsIds.push(obj.toString());
                }
                newFilters = [
                    {
                        "and": latestChemicalsIds.length == 0 ? [
                            ...query.filters,

                        ] : [
                            ...query.filters,
                            {
                                "member": 'Formulas.id',
                                "operator": 'equals',
                                "values": latestChemicalsIds
                            }
                        ]
                    }
                ]
                query.filters = newFilters;
                return query;
            })
    }
    private getConnectedFactories() {
        let chemicalQuery = {
            "dimensions": [
                "Factories.factory_name",
                "Factories.organization_id"
            ],
            "timeDimensions": [
            ],
            "order": [
            ],
            "filters": [],
            "measures": [
            ]
        };
        return this.getCubeJSData(chemicalQuery, undefined, undefined, true, false)
            .then(data => {
                let tempData = data['loadResponse']['results'][0]['data'];
                tempData = this.changeKey("Factories.factory_name", "factory_name", tempData);
                tempData = this.changeKey("Factories.organization_id", "organization_id", tempData);
                return tempData;
            })
    }

    private  specialQueries(queryObj: DashboardQueryInterface, data: any) {
        switch (queryObj.Name) {
            case "Supplier count by compliance rate": {
                let querterData = this.groupDataByQuarter(data)

                const quarterlyCounts: any[] = [];

                for (const quarter in querterData) {
                    if (Object.prototype.hasOwnProperty.call(querterData, quarter)) {
                        let under50PercentCount = 0;
                        let between50And75PercentCount = 0;
                        let over75PercentCount = 0;

                        querterData[quarter].forEach((entry: any) => {
                            const compliancePct = entry["factory_compliant_pct"];
                            if (compliancePct < 50) {
                                under50PercentCount++;
                            } else if (compliancePct >= 50 && compliancePct <= 75) {
                                between50And75PercentCount++;
                            } else {
                                over75PercentCount++;
                            }
                        });
                        const date = new Date(quarter);
                        const quarter_ = Math.floor((date.getMonth() / 3) + 1);
                        const year = date.getFullYear();

                        quarterlyCounts.push({
                            label: `${year}-Q${quarter_}`,
                            value: [
                                { label: "under 50%", value: under50PercentCount },
                                { label: "50%-75%", value: between50And75PercentCount },
                                { label: "over 75%", value: over75PercentCount }
                            ]
                        });
                    }
                }

                return quarterlyCounts;
            };
            case "Country Performance": {
                const result: { country: any; count: number, quarters: any }[] = [];

                // Loop through the data
                data.forEach((entry: any) => {
                    const complianceValue = parseInt(entry["Formulas.factory_compliant_pct"]); // Convert compliance to integer
                    const countryName = entry["Formulas.country"].toUpperCase();
                    const quarter = entry["Formulas.scan_date.quarter"]; // Assume there is a quarter field in your data
                    const date = new Date(quarter);
                    const quarter_ = Math.floor((date.getMonth() / 3) + 1);
                    const year = date.getFullYear();
                    const quarterLabel = `${year}-Q${quarter_}`;
                    // Check if country exists in the result array
                    const existingCountryIndex = result.findIndex(item => item.country === countryName);
                    if (existingCountryIndex === -1) {
                        // If country doesn't exist in the result, add it with initial quarter data
                        result.push({
                            country: countryName,
                            count: 1,
                            quarters: {
                                [quarterLabel]: {
                                    min: complianceValue,
                                    max: complianceValue,
                                    avg: complianceValue,
                                    count: 1
                                }
                            }
                        });
                    } else {
                        // Country exists, check if the quarter exists for this country
                        let country = result[existingCountryIndex];
                        country.count += 1;
                        if (!country.quarters[quarterLabel]) {
                            // If the quarter doesn't exist, initialize it
                            country.quarters[quarterLabel] = {
                                min: complianceValue,
                                max: complianceValue,
                                avg: complianceValue,
                                count: 1
                            };
                        } else {
                            // Update min, max, and sum for existing quarter
                            let quarterData = country.quarters[quarterLabel];
                            quarterData.min = Math.min(quarterData.min, complianceValue);
                            quarterData.max = Math.max(quarterData.max, complianceValue);
                            quarterData.count += 1;
                            quarterData.avg = (quarterData.avg * (quarterData.count - 1) + complianceValue) / quarterData.count;
                            quarterData.avg = Number(quarterData.avg.toFixed(2)); // Recalculate average and format to two decimal places
                        }
                    }
                });

                return result;
            };
            case "Quarterly Inventory Overview": case "Monthly Inventory Overview": {
                return new Promise((resolve, reject) => {
                    const allObservables = forkJoin([
                        this.getConnectedFactories()
                    ]);

                    allObservables.subscribe(responses => {
                        let connectedFactories = responses[0];
                        let factoryNamesInData = data.map((obj: any) => obj['Formulas.organization_name']);

                        if (queryObj.Name == "Monthly Inventory Overview") {
                            const allObservables = forkJoin([
                                this.getCubeJSData(JSON.parse(queryObj.QueryJSON), undefined),
                                this.http.get<any>(this.baseHref + '/getAnalyticsInternalComments')
                            ]);

                            allObservables.subscribe(responses => {
                                let comments = responses[1]; 
                                data = data.map((obj: any) => { 
                                    obj['Formulas.scanned'] = (connectedFactories.map((factory: any) => factory.factory_name)).includes(obj['Formulas.organization_name']) ? 'Yes' : 'No';
                                    obj.hasComment = false;
                                    obj.comment = {
                                        supplierId: obj['Formulas.organization_id'],
                                        supplierName: obj['Formulas.organization_name'],
                                        commentDetails: [],
                                        queryId: queryObj.Id
                                    };
                                    return this.reorderProperties(obj, 'Formulas.scanned');
                                });
                                connectedFactories.forEach((factory: any) => {
                                    if (!factoryNamesInData.includes(factory.factory_name)) {
                                        let newEntry: any = {
                                            'Formulas.organization_name': factory.factory_name,
                                            'Formulas.scanned': 'No',
                                            'Formulas.organization_id': factory.organization_id,
                                            hasComment: false,
                                            comment: {
                                                supplierId: factory.organization_id,
                                                supplierName: factory.factory_name,
                                                commentDetails: [],
                                                queryId: queryObj.Id
                                            }
                                        };
                                        for (let key in data[0]) {
                                            if (key !== 'Formulas.organization_name' && key !== 'Formulas.scanned' && key !== 'Formulas.organization_id' && key !== 'hasComment' && key !== 'comment') {
                                                newEntry[key] = '';
                                            }
                                        }
                                        data.push(newEntry);
                                    }
                                });
                                if (comments.length !== 0) {
                                    comments.forEach((comm: any) => {
                                        if (comm.supplierId && comm.queryId === queryObj.Id) {
                                            let a = data.filter((el: any) =>
                                                el['Formulas.organization_id'] == comm.supplierId &&
                                                el['comment'].queryId == queryObj.Id
                                            );
                                            a.forEach((el: any) => {
                                                el.hasComment = true;
                                                el.comment['supplierName'] = comm['supplierName'];
                                                el.comment['supplierId'] = comm['supplierId'];
                                                el.comment['commentDetails'].push({
                                                    createdOn: comm['createdOn'],
                                                    userName: comm['userName'],
                                                    comment: comm['comment']
                                                });
                                            });
                                        }
                                    });
                                }
                                resolve(data);
                            }, error => {
                                reject(error);
                            });
                        } else if (queryObj.Name == "Quarterly Inventory Overview") {
                            const allObservables = forkJoin([
                                this.http.get<any>(this.baseHref + '/getAnalyticsInternalComments')
                            ]);
                            allObservables.subscribe(responses => {
                                let comments = responses[0]; // get comments for factories
                                data = data.map((obj: any) => {
                                    // Check if the factory is in the connected factories list
                                    obj['Formulas.max_scan_date'] = (connectedFactories.map((factory: any) => factory.factory_name)).includes(obj['Formulas.organization_name']) ? obj['Formulas.max_scan_date'] : 'No Scan';
                                    obj.hasComment = false; // by default there is no comment for factories, later will be updated
                                    obj.comment = {
                                        supplierId: obj['Formulas.organization_id'],
                                        supplierName: obj['Formulas.organization_name'],
                                        commentDetails: [],
                                        queryId: queryObj.Id
                                    };
                                    return this.reorderProperties(obj, 'Formulas.max_scan_date');
                                });
                                connectedFactories.forEach((factory: any) => {
                                    if (!factoryNamesInData.includes(factory.factory_name)) {
                                        let newEntry: any = {
                                            'Formulas.organization_name': factory.factory_name,
                                            'Formulas.max_scan_date': 'No Scan',
                                            'Formulas.organization_id': factory.organization_id,
                                            hasComment: false,
                                            comment: {
                                                supplierId: factory.organization_id,
                                                supplierName: factory.factory_name,
                                                commentDetails: [],
                                                queryId: queryObj.Id
                                            }
                                        };
                                        for (let key in data[0]) {
                                            if (key !== 'Formulas.organization_name' && key !== 'Formulas.max_scan_date' && key !== 'Formulas.organization_id' && key !== 'hasComment' && key !== 'comment') {
                                                newEntry[key] = '';
                                            }
                                        }
                                        data.push(newEntry);
                                    }
                                });
                                if (comments.length !== 0) {
                                    comments.forEach((comm: any) => {
                                        if (comm.supplierId && comm.queryId === queryObj.Id) {
                                            let a = data.filter((el: any) =>
                                                el['Formulas.organization_id'] == comm.supplierId &&
                                                el['comment'].queryId == queryObj.Id
                                            );
                                            a.forEach((el: any) => {
                                                el.hasComment = true;
                                                el.comment['supplierName'] = comm['supplierName'];
                                                el.comment['supplierId'] = comm['supplierId'];
                                                el.comment['commentDetails'].push({
                                                    createdOn: comm['createdOn'],
                                                    userName: comm['userName'],
                                                    comment: comm['comment']
                                                });

                                            });
                                        }                              
                                    });
                                }
                                resolve(data);
                            }, error => {
                                reject(error);
                            });
                        } else {
                            resolve(data);
                        }
                    }, error => {
                        reject(error);
                    });
                });
            };

            case "Otto Activity Monitoring": {
                return new Promise((resolve, reject) => {
                    const allObservables = forkJoin([
                        this.getCubeJSData(JSON.parse(queryObj.QueryJSON), undefined),
                        this.http.get<any>(this.baseHref + '/getAnalyticsInternalComments')
                    ]);
                    allObservables.subscribe(responses => {
                        let comments = responses[1]; // get comments for factories
                        data = data.map((obj: any) => { // adjust some of the fields
                            obj.hasComment = false // by default there is no comment for factories, later will be updated
                            obj.comment = {
                                supplierId: obj['BrandActivityWithCompliance.supplier_id'],
                                supplierName: obj['BrandActivityWithCompliance.supplier_name'],
                                commentDetails: []
                            };
                            return obj;
                        });

                        if (comments.length !== 0) {
                            comments.forEach((comm: any) => {
                                let a = data.filter((el: any) => el['BrandActivityWithCompliance.supplier_id'] == comm.supplierId);
                                a.forEach((el: any) => {
                                    el.hasComment = true;
                                    el.comment['supplierName'] = comm['supplierName'];
                                    el.comment['supplierId'] = comm['supplierId'];
                                    el.comment['commentDetails'].push({
                                        createdOn: comm['createdOn'],
                                        userName: comm['userName'],
                                        comment: comm['comment']
                                    });
                                });
                            });
                        }
                        resolve(data);
                    }, error => {
                        reject(error);
                    });
                });
            };             
            case "Higg Overview": case "Wastewater Overview": case "Wastewater Test Reports (v2.1) - Result Analysis": {
                return new Promise((resolve, reject) => {
                    const allObservables = forkJoin([
                        this.getCubeJSData(JSON.parse(queryObj.QueryJSON), undefined),
                        this.http.get<any>(this.baseHref + '/getAnalyticsInternalComments')
                    ]);
                    allObservables.subscribe(responses => {
                        let comments = responses[1]; // get comments for factories and specific query
                        data = data.map((obj: any) => { // adjust some of the fields
                            obj.hasComment = false // by default there is no comment for factories, later will be updated
                            obj.comment = {
                                supplierId: obj['Factories.organization_id'],
                                supplierName: obj['Factories.factory_name'],
                                commentDetails: [],
                                queryId: queryObj.Id
                            };
                            return obj;
                        });

                        if (comments.length !== 0) {
                            comments.forEach((comm: any) => {
                                if (comm.supplierId && comm.queryId === queryObj.Id) {
                                    let a = data.filter((el: any) => el['Factories.organization_id'] == comm.supplierId && el['comment'].queryId == queryObj.Id);
                                    a.forEach((el: any) => {
                                        el.hasComment = true;
                                        el.comment['supplierName'] = comm['supplierName'];
                                        el.comment['supplierId'] = comm['supplierId'];
                                        el.comment['commentDetails'].push({
                                            createdOn: comm['createdOn'],
                                            userName: comm['userName'],
                                            comment: comm['comment']
                                        });
                                    });
                                }
                            });
                        }
                        resolve(data);
                    }, error => {
                        reject(error);
                    });
                });
            };             
                
            case "WasterWater v2.1 parameters": {
                const simplifiedData = data.map((item: any) => ({
                    name: item.name,
                    result: item.result,
                    limit: item.limit,
                    cap: item.cap
                }));

                return simplifiedData;
            }
            default: return Promise.resolve([]);
        }
    }
    private isSpecialQuery(queryObj: DashboardQueryInterface) {
        switch (queryObj.Name) {
            case "Supplier count by compliance rate": return true;
            case "Country Performance": return true;
            case "Otto Activity Monitoring": return true;
            case "Quarterly Inventory Overview": return true;
            case "Monthly Inventory Overview": return true;
            case "Higg Overview": return true;
            case "Wastewater Overview": return true;
            case "Wastewater Test Reports (v2.1) - Result Analysis": return true;
            default: return false;
        }
    }
    handleAction(clickedColumn: any, rowData: any, queryObject: DashboardQueryInterface) {
        let data: any = [];
        switch (clickedColumn.action) {
            case 'showDetails': {
                const dataOrig = clickedColumn.payload.columnData;
                if (queryObject.Name == 'WasteWater v2.1') {
                    let origData = JSON.parse(rowData[dataOrig]);
                    if (clickedColumn.name == 'Sludge') {



                        let convParam = origData.conventionalParams.map((item: any) => ({
                            Type: 'Conventional',
                            name: item.name,
                            detectedValue: item.detectedValue,
                            result: item.result,
                            cap: item.cap
                        }));
                        let anionParam = origData.anionParams.map((item: any) => ({
                            Type: 'Anion',
                            name: item.name,
                            detectedValue: item.detectedValue,
                            result: item.result,
                            cap: item.cap
                        }));
                        let metalPram = origData.metalsParams.map((item: any) => ({
                            Type: 'Metals',
                            name: item.name,
                            detectedValue: item.detectedValue,
                            result: item.result,
                            cap: item.cap
                        }));
                        let mrslParam = origData.mrslParams.map((item: any) => ({
                            Type: 'ZDHC MRSL',
                            name: item.name,
                            detectedValue: item.detectedValue,
                            result: item.result,
                            cap: item.cap
                        })).filter((item: any) => item.result === "Failed");

                        data = [...convParam, ...anionParam, ...metalPram, ...mrslParam]

                    } else {
                        if (clickedColumn.name == 'Conventionals') {
                            origData = JSON.parse(origData.conventionalParameters);
                        }
                        if (clickedColumn.name == 'Anion') {
                            origData = JSON.parse(origData.anionParameters);
                        }
                        if (clickedColumn.name === 'ZDHC MRSL') {
                            data = origData.map((item: any) => ({
                                name: item.name,
                                detectedValue: item.detectedValue,
                                result: item.result,
                                limit: item.limit,
                                cap: item.cap
                            }))
                            data = data.filter((item: any) => item.result === "Failed" || item.result === null);
                        }
                        else {
                            data = origData.map((item: any) => ({
                                name: item.name,
                                detectedValue: item.detectedValue,
                                result: item.result,
                                limit: item.isLeather == true ? (item.leatherLimit ? item.leatherLimit.foundational : null) : (item.textileLimit ? item.textileLimit.foundational : null),
                                cap: item.cap
                            }))
                        }
                    }
                    data = data.sort((a: any, b: any) => {
                        // Handle 'failed' to always come first
                        if (a.result === "Failed" && b.result !== "Failed") return -1;
                        if (b.result === "Failed" && a.result !== "Failed") return 1;

                        // Handle 'null' values to always come last
                        if (a.result === null && b.result !== null) return 1;
                        if (b.result === null && a.result !== null) return -1;

                        // For non-null and non-failed, sort alphabetically or numerically as required
                        if (a.result && b.result) return a.result.localeCompare(b.result);
                        return 0; // Default case to handle unexpected conditions
                    });
                }

                const dialogRef = this.dialog.open(DrillDownDialog, {
                    width: '50%',
                    data: { table: data, description: "This chart shows the list of parameters of " + clickedColumn.name + ' with their result, limit and CAP for factory ' + rowData['Factory Name'], query: queryObject },
                });
            }

        }
    }
    reorderProperties(obj: any, keyToMove: string): any {
        const reorderedObj: any = {};
        const keys = Object.keys(obj);
        const index = keys.indexOf(keyToMove);

        if (index !== -1) {
            // Add 'Formulas.organization_name' first
            if (keys.includes('Formulas.organization_name')) {
                reorderedObj['Formulas.organization_name'] = obj['Formulas.organization_name'];
            }

            // Add the specified key as the second property
            reorderedObj[keyToMove] = obj[keyToMove];

            // Add the remaining keys
            keys.forEach((key, i) => {
                if (key !== 'Formulas.organization_name' && key !== keyToMove) {
                    reorderedObj[key] = obj[key];
                }
            });
        }

        return reorderedObj;
    }

    groupDataByQuarter(data: any) {
        const groupedData: any = {};

        data.forEach((entry: any) => {
            const quarter = entry["Formulas.scan_date.quarter"];
            if (!groupedData[quarter]) {
                groupedData[quarter] = [];
            }
            groupedData[quarter].push({
                factory_name: entry["Factories.factory_name"],
                factory_compliant_pct: entry["Formulas.factory_compliant_pct"]
            });
        });

        return groupedData;
    }

    whoAmI() {
        return this.http.get(this.baseHref + '/whoAmI');
    }

    isValidISODateTime(dateString: any) {
        const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}$/;
        return isoDateTimeRegex.test(dateString);
    }

    getAllTranslations(): Observable<any> {
        return this.http.get(this.baseHref + '/getTranslations')
            .pipe(
                catchError(error => {
                    console.error('Error in getTranslations:', error);
                    throw error;
                })
            );
    }

}
