import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { DocumentcenterComponent } from '../documentcenter/documentcenter.component';
import {HttpClient} from '@angular/common/http';
import { GlobalsService } from '../globals-services/globals.service';
import { UseCategory, HazardPhrase, FormulaGlobalDataService } from '../formula-global-data/formula-global-data.service';
import { FormBuilder, Validators, FormGroup, NgForm, FormControl } from '@angular/forms';


export interface FormulaData {
  name: string;
  manufacturer: string;
  cas_nr: string;
  application: string;
  containsmsds: string;
  ghs_classification: string;
  ghs_hazard_statement: string;
  masterid: string;
  notes: string;
  precautionary_statement: string;
  useofppe: string;
  user_cas_nr: string;
  user_notes: string;
  user_precautionary_statement: string;
  user_use_ofppe: string;
  user_application: string;
  userghsclassification: string;
  userghshazard_statement: string;
  msds_identifier: string;
  creatorID: string;
  userghsmsds_available: string;
  ghs_msds_available: string;
  readonly: boolean;
  remediation: string;
  action : string;
  deadline: string;
  usageInFactory:number,
  cap_status: boolean;
}


@Component({
  selector: 'app-formula-details',
  templateUrl: './formula-details.component.html',
  styleUrls: ['./formula-details.component.css']
})

export class FormulaDetailsComponent implements OnInit{



  useCategories: UseCategory[] = [];

  hazardPhrases: HazardPhrase[] = [];

  useCategoryOptions: UseCategory[];
  readonly: boolean;
  applicationInput: string = '';
  applications = [];
  applicationsRemovable = true;
  applicationsSelectable = false;


  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  statementOptions: HazardPhrase[];
  hazardStatements = [];
  statementsRemovable = true;
  statementsSelectable = false;
  statementInput: string = '';

  chemicalFormulation: FormGroup;
  correctiveActionPlan: FormGroup;

  formulation = [];
  newFormulation = [];
  deletedFormulation = [];
  matTabIndex = 0

  dateToday: Date;

  formulaUsageInFactory = [

    { name: 'Production (dyes, pigments and aux)', value: 0 },
    { name: 'Bulk / Commodities', value: 2 },
    { name: 'Lab / Testing chemicals', value: 3 },
    { name: 'Cleaning Agents / Maintenance Chemicals', value: 4 },
    { name: 'Water Treatment chemicals', value: 5 },
    { name: 'Functional Finishing', value: 6 },
    { name: 'Solvent based chemicals', value: 7 },
    { name: 'Developing/cleaning printing screen', value: 8 }
    

  ]

  constructor(
    public dialogRef: MatDialogRef<DocumentcenterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormulaData,
    private http: HttpClient,
    private globalsService: GlobalsService,
    private predefinedData: FormulaGlobalDataService,
    private _formBuilder: FormBuilder,
    private datePipe : DatePipe,

    ) {
      // console.log(data);
      if ( this.data.user_application ){
        this.applications = this.data.user_application.split("\n");
      }
      // console.log(this.applications);
      if ( this.data.userghshazard_statement ) {
        this.hazardStatements = this.data.userghshazard_statement.split("\n");
      }
      // console.log(this.hazardStatements);

      this.hazardPhrases = this.predefinedData.hazardPhrases;
      this.useCategories = this.predefinedData.useCategories;
      // console.log(this.hazardPhrases);
      // console.log(this.useCategories);
      this.statementOptions = this.hazardPhrases;
      this.useCategoryOptions = this.useCategories;
      this.chemicalFormulation = this._formBuilder.group({
        'casNrControl' : ['', null],
        'substanceName' : ['', null],
        'percentage' : ['', null]
        });
      this.correctiveActionPlan = this._formBuilder.group({
          'remediation': ['', null],
          'action': ['', null],
          'deadline': ['', null]
      });
      this.dateToday = new Date();
    };

    ngOnInit() {
        this.getChemicalInformation();
    }

  checkDisable(formula: any, defaultfield: string){
    let def: string;
    def = formula['ref'.concat(defaultfield)];
      if (def == null || def == undefined) {
        def = '';
      }
      if (formula['readonly'] === true){
        def = '';
      }
      def = def.replace(/[\s\r]/g,'');
      return def !== '';
      }



  checkFieldToShow(formula: any, defaultfield: string) {
    let def: string;

    def = formula[defaultfield];
    if (def == null || def == undefined) {
      def = '';
    }
    def = def.replace(/[\s\r]/g,'');
    return def !== '' && formula.masterid != "-1";
  };

  Save(data: FormulaData) {
    this.checkCorrectiveActionPlan()
    if (this.correctiveActionPlan.valid) {
        if( this.applications.length > 0){
            data.user_application = this.applications.join('\n');
          } else {
            data.user_application = "";
          }
          // console.log(data.user_application);
          if(this.hazardStatements.length > 0) {
            data.userghshazard_statement = this.hazardStatements.join('\n');
          } else {
            data.userghshazard_statement = "";
          }
          // console.log(data.userghshazard_statement);

          this.http.put(this.globalsService.baseHref + '/formulaDetails',
          { 'masterid' : parseInt(data.masterid, 10),
            'user_cas_nr' : data.user_cas_nr,
            'userghshazard_statement' : data.userghshazard_statement,
            'userghsclassification' : data.userghsclassification,
            'user_precautionary_statement' : data.user_precautionary_statement,
            'user_use_ofppe': data.user_use_ofppe,
            'user_notes' : data.user_notes,
            'userghsmsds_available' : data.userghsmsds_available,
            'user_application': data.user_application,
            'creatorID': data.creatorID,
            'msds_identifier': data.msds_identifier,
            'newFormulation': this.newFormulation,
            'deletedFormulation': this.deletedFormulation,
            'remediation': this.correctiveActionPlan.controls['remediation'].value,
            'action': this.correctiveActionPlan.controls['action'].value,
            'deadline': this.correctiveActionPlan.controls['deadline'].value,
            'usageInFactory': parseInt(data.usageInFactory+"",10)

             }).subscribe(
            () => {
              // console.log(data);
              this.dialogRef.close(data);
            },
            (err) => {
              console.log(err);
              alert('Error: Data specific to chemical could not be saved!');
            });
    }

  }

  Cancel() {
    this.dialogRef.close(null);
  }

  resetApplication(event: MatChipInputEvent): void {
    const input = event.input;

    if (input) {
      input.value = '';
    }
  }

  addAutocompleteApplication(event: MatAutocompleteSelectedEvent): void {
    // console.log(event);
    const value = event.option.value;

    // Add application
    if ( (value || '').trim() && this.applications.indexOf(value) == -1 ) {
      this.applications.push(value.trim());
    }

    this.useCategoryOptions = this.useCategories;
  }

  removeApplication(application): void {
    const index = this.applications.indexOf(application);

    if (index >= 0) {
      this.applications.splice(index, 1);
    }
  }

  resetStatement(event: MatChipInputEvent): void {
    const input = event.input;

    if (input) {
      input.value = "";
    }
  }

  addAutocompleteStatement(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;

    // Add application
    if ( (value || '').trim() && this.hazardStatements.indexOf(value) == -1 ) {
      this.hazardStatements.push(value.trim());
    }

    this.statementOptions = this.hazardPhrases;
  }

  removeStatement(statement): void {
    const index = this.hazardStatements.indexOf(statement);

    if (index >= 0) {
      this.hazardStatements.splice(index, 1);
    }
  }

  filterApplications() {
    this.useCategoryOptions = [];

    this.useCategories.forEach( useCategory => {
      let newUseCategory: UseCategory = this.initUseCategories();
      let header = useCategory.header;
      let options = useCategory.options;
      let newOptions = [];

      // console.log(useCategory.options);
      newOptions = options.filter( option =>
        option.toLowerCase().indexOf( this.applicationInput.toLowerCase() ) !== -1
      );

      if ( newOptions.length > 0 ) {
        newUseCategory.header = header;
        newUseCategory.options = newOptions;

        this.useCategoryOptions.push( newUseCategory );
      }
    });
    // console.log(this.useCategoryOptions);
  }

  filterStatements(){
    this.statementOptions = [];

    this.hazardPhrases.forEach( hazardPhrase => {
      let newHazardStatement: HazardPhrase = this.initHazardPhrases();
      let header = hazardPhrase.header;
      let options = hazardPhrase.options;
      let newOptions = [];

      newOptions = options.filter( option =>
        option.toLowerCase().indexOf( this.statementInput.toLowerCase() ) !== -1
      );

      if ( newOptions.length > 0 ) {
        newHazardStatement.header = header;
        newHazardStatement.options = newOptions;

        this.statementOptions.push( newHazardStatement );
      }
    });
    // console.log(this.statementOptions);
  }

  initUseCategories(): UseCategory {
    return { header: "", options: [] };
  }

  initHazardPhrases(): HazardPhrase {
    return { header: "", options: [] };
  }

  addToFormulation(){
    this.checkForEmpty(this.chemicalFormulation.controls['casNrControl']);
    this.checkForEmpty(this.chemicalFormulation.controls['substanceName']);
    this.checkForEmpty(this.chemicalFormulation.controls['percentage']);
    if (this.chemicalFormulation.valid){
        const formulation = {
            'id': '',
            'casnr': this.chemicalFormulation.value['casNrControl'] ,
            'substanceName': this.chemicalFormulation.value['substanceName'] ,
            'percentage': this.chemicalFormulation.value['percentage']
        }

        this.formulation.push(formulation);
        this.newFormulation.push(formulation);
        this.chemicalFormulation.reset();
        this.chemicalFormulation.controls['casNrControl'].setErrors(null);
        this.chemicalFormulation.controls['substanceName'].setErrors(null);
        this.chemicalFormulation.controls['percentage'].setErrors(null);
        }

    }

    checkForEmpty(control){
        if(this.checkForNullorBlank(control.value)){
            control.setErrors({
                'required': true
            });
        }
    }

    checkCorrectiveActionPlan() {
        //check if any of the corrective action plan controls has value
        if (this.checkForNullorBlank(this.correctiveActionPlan.value['remediation']) !== this.checkForNullorBlank(this.correctiveActionPlan.value['deadline'])) {
            this.matTabIndex = 2;
            if (this.checkForNullorBlank(this.correctiveActionPlan.value['remediation']) ){
                this.correctiveActionPlan.controls['remediation'].setErrors({
                    'required': true
                });
            } else if ( (this.checkForNullorBlank(this.correctiveActionPlan.value['deadline'])) || (this.correctiveActionPlan.value['deadline'] < this.dateToday) ){
                this.correctiveActionPlan.controls['deadline'].setErrors({
                    'required': true
                });
            }
        } else if (this.correctiveActionPlan.value['deadline'] < this.dateToday && !this.checkForNullorBlank(this.correctiveActionPlan.value['remediation']) ){
            this.correctiveActionPlan.controls['deadline'].setErrors({
                'invalid': true
            });
        } else {
            this.correctiveActionPlan.controls['remediation'].setErrors(null);
            this.correctiveActionPlan.controls['deadline'].setErrors(null);
        }

        if (this.correctiveActionPlan.value['remediation']==='Update' ||this.correctiveActionPlan.value['remediation']==='Substitute'){
            this.matTabIndex = 2;
            if (this.checkForNullorBlank(this.correctiveActionPlan.value['action'])){
                this.correctiveActionPlan.controls['action'].setErrors({
                    'required': true
                });
            }
        } else {
            this.correctiveActionPlan.controls['action'].reset();
            this.correctiveActionPlan.setErrors(null);
        }
    }

    checkForNullorBlank(object){
        if (object === null || object === ''){
            return true;
        } else{
            return false;
        }
    }

    deleteSubstance(index){
        if (this.formulation[index]['id'] !== null && this.formulation[index]['id'] !== ''){
            this.deletedFormulation.push(this.formulation[index]['id']);
        } else{
            const newFormulationIndex = this.newFormulation.indexOf(this.formulation[index])
            this.newFormulation.splice(newFormulationIndex,1)
        }
        this.formulation.splice(index, 1)
    }

    getChemicalInformation(){

        let id = this.data.masterid;

        if (id === "-1"){

            id = this.data.msds_identifier;
        }

        const type = this.data.creatorID;

        this.http.get<any>(this.globalsService.baseHref + '/getChemicalInformation/' + type + '/' + id).subscribe(

          (response) => {

               // console.log(response)

                this.formulation = response['ingredients'];

                if(response['usageInFactory'] === 'undefined' || response['usageInFactory'] == 0) {

                  this.data.usageInFactory=1 //set default value to  " production ""

                  //set default on empty

                }else{

                    this.data.usageInFactory=response['usageInFactory'];
                 }

                this.correctiveActionPlan.setValue({'remediation':response['remediation'], 'action': response['action'], 'deadline':response['deadline']})
            });

    }

}
