import { Injectable } from "@angular/core";
import { GatewayAuthenticationService } from "app/gateway/authentication/gateway-authentication.service";
import { Formula } from "app/globals-interface/formula";
import { FormulaService } from "app/shared/services/formula.service";

@Injectable()
export class NewComplianceCheckerService {



    constructor(
        private fmService: FormulaService
    ) {

    }


    checkAndParseFormulasWithBrandCompliance(finalFormulas: any, brandCompliance: string[]) {

        const result = {
            'positiveChemicals': [],
            'negativeChemicals': []
        };


        for (const formula of finalFormulas) {

            let isMatched = false;

            if (brandCompliance.includes('Gateway') && this.isGatewayFormula(formula)) {
                isMatched = true
            }

            if (!isMatched && brandCompliance.includes('ZDHC crowedsource') && formula.zdhcMrsl == 'Yes') {
                isMatched = true
            }
            //usageInFactory 2=Commodities
            //basic chemical Check
            const isBasicChemicalCategory = this.fmService.getFormulaType(formula) == 'F' ? formula.category === 'Basic Chemical' : formula.basicChemicalIdentifier == '1';

            if (!isMatched && brandCompliance.includes('Basic Chemical') && (isBasicChemicalCategory || formula.usageInFactory == 2)) {
                isMatched = true
            }
            // if (!isMatched && brandCompliance.includes('Inditex')) {
            //     isMatched = true
            // }

            if (!isMatched && brandCompliance.includes('SDS Screened') && formula.bluwinSdsVerified == 'Yes') {
                isMatched = true
            }

            if (!isMatched && brandCompliance.includes('OekoTex') && this.validateOekotexCompliance(formula)
            ) {
                isMatched = true
            }


            // If a match is found, push the formula into the positiveChemicals array
            if (isMatched) {
                result.positiveChemicals.push(formula);
            } else {
                result.negativeChemicals.push(formula);
            }
        }

        return result;

    }
    validateOekotexCompliance(formula: any) {
        if (GatewayAuthenticationService.isActiveGatewaySubcription)
            return this.isGatewayFormula(formula) || ((formula.ecoPassport == 'Yes' || formula.zdhcMrsl == 'Yes' || formula.hasStepDeclaration) && (formula.gatewayLevel == null || formula.gatewayLevel == "" || this.isGatewayLevelValidForOekotexCompliance(formula.gatewayLevel)))
        else
            return formula.ecoPassport == 'Yes' || formula.zdhcMrsl == 'Yes' || formula.hasStepDeclaration



        //     ( coalesce(gateway_subscription_status, 0) = 2 and  (
        //         ( (incheck_level1 = 1 or incheck_level2 = 1 or incheck_level3 = 1)  and incheck_archived = 0)
        //          or
        //         (
        //               (eco_passport = 1 or zdhc_mrsl = 1 or step_self_declaration = 1) 
        //                and 
        //               (gateway_level is null or incheck_oldzdhc = 1 or incheck_registered = 1 or incheck_expired = 1 or incheck_archived = 1)
        //          )
        //       )
        //   ) 
        //   or (coalesce(gateway_subscription_status, 0) != 2 and (eco_passport = 1 or zdhc_mrsl = 1 or step_self_declaration = 1))

    }

    isGatewayFormula(manAddFormula) {
        return GatewayAuthenticationService.isActiveGatewaySubcription && manAddFormula['gatewayLevel'] != null
            && !manAddFormula['gatewayLevel'].toLowerCase().includes('archived') && (manAddFormula['gatewayLevel'].includes('v2') || manAddFormula['gatewayLevel'].includes('v3'))
    }


    isGatewayLevelValidForOekotexCompliance(manAddFormula) {
        return manAddFormula['gatewayLevel'] != null && (['archived', 'registered', 'expired'].includes(manAddFormula['gatewayLevel'].toLowerCase()))
    }


    checkAndParseFormulasWithScope(formulas: Formula[], scope) {
        const formulas_copy = formulas.slice();
        //arrange by ID so that it will get the latest data on the formula
        formulas_copy.sort(function (a, b) {
            const x = a.id;
            const y = b.id;
            return x > y ? -1 : x < y ? 1 : 0;
        });

        let result: any = this.filterFormulaByBrandScope(formulas_copy, scope)

        this.moveMatchingChemicalFormulas(result['negativeChemicals'], result['positiveChemicals']);

        return result;
    }

    public moveMatchingChemicalFormulas(negativeChemicals, positiveChemicals) {


        for (const formula of negativeChemicals) {
            let isDbFm = formula.masterID == undefined ? false : true;

            const match = positiveChemicals.find(
                (positiveFormula) =>
                    formula.masterID != undefined && (positiveFormula.masterID === formula.masterID)
                    ||
                    formula.msdsIdentifier != undefined && (positiveFormula.msdsIdentifier === formula.msdsIdentifier)

            );
            if (match) {
                // Remove the formula from negativeChemicals
                const index = negativeChemicals.indexOf(formula);
                if (index !== -1) {
                    negativeChemicals.splice(index, 1);
                }
                // Add the formula to positiveChemicals
                positiveChemicals.push(formula);
            }
        }
    }



    filterFormulaByBrandScope(formulas: any[], definedScope: number[]) {

        let result: any = {}
        result['positiveChemicals'] = []; //inscope
        result['negativeChemicals'] = []    //out Of Scope

        const isScopeIncludesComoditiesOrBasicChemicals = definedScope.includes(2); //check if commodities/basic chemical defined in scope
        const isScopeIncludesEmptyUsageInFactory = definedScope.includes(9);

        formulas.forEach(formula => {

            const isUsageInFactoryMatchesWithDefinedScope =
                (isScopeIncludesEmptyUsageInFactory && (formula.usageInFactory == null || formula.usageInFactory === ''))
                || definedScope.includes(formula.usageInFactory);  //match inventory with defined scope


            const isFormulaBasicChemical = this.fmService.getFormulaType(formula) == 'F' ? formula.category === 'Basic Chemical' : formula.basicChemicalIdentifier == '1';

            //check if db formual or man added formula. for man added formula /basicChemicalIdentifier = "0"

            // const isFormulaBasicChemical = formula.category === 'Basic Chemical';  // applies for formula new. manually added formula has basic chemical identifier

            //basicChemicalIdentifier = "0"

            if (isScopeIncludesComoditiesOrBasicChemicals && (isFormulaBasicChemical || isUsageInFactoryMatchesWithDefinedScope)) {

                result['positiveChemicals'].push(formula)

                console.info("Positive Name :" + formula.name + "  basic chemical =" + isFormulaBasicChemical + " usageInFactory Matched " + isUsageInFactoryMatchesWithDefinedScope)

            } else if (!isScopeIncludesComoditiesOrBasicChemicals && !isFormulaBasicChemical && isUsageInFactoryMatchesWithDefinedScope) {
                //if scope dont have commodities /basic chemical then push only those chemical to positive
                //which are not basic chemical and having matching usageInFactory with scope
                console.info("Positive Name :" + formula.name + "  basic chemical =" + isFormulaBasicChemical + " usageInFactory Matched " + isUsageInFactoryMatchesWithDefinedScope)

                result['positiveChemicals'].push(formula)

            } else {
                console.info("Negative Name :" + formula.name + "  basic chemical =" + isFormulaBasicChemical + " usageInFactory Matched " + isUsageInFactoryMatchesWithDefinedScope)

                result['negativeChemicals'].push(formula)
            }

        });

        return result
    }

    // filterFormulaByBrandScope(formulas: any[], scope: number[]) {

    //     let result: any = {}
    //     result['positiveChemicals'] = []; //inscope
    //     result['negativeChemicals'] = []    //out Of Scope

    //     const isScopeIncludesBasicChemical = scope.includes(9);

    //     formulas.forEach(formula => {
    //         const isUsageInFactoryMatchesWithScope = scope.includes(formula.usageInFactory);

    //         const isBasicChemicalCategory = formula.category === 'Basic Chemical';

    //         if (isScopeIncludesBasicChemical && (isUsageInFactoryMatchesWithScope || isBasicChemicalCategory)) {
    //             //if scope includes Basic chemical then
    //             //either matching with usageInfactory or category='Basic chemical' push chemical to positive
    //             result['positiveChemicals'].push(formula)

    //             console.info("Positive Name :" + formula.name + "  basic chemical =" + isBasicChemicalCategory + " usageInFactory Matched " + isUsageInFactoryMatchesWithScope)

    //         } else if (!isScopeIncludesBasicChemical && !isBasicChemicalCategory && isUsageInFactoryMatchesWithScope) {
    //             //if scope dont have basic chemical then push only those chemical to positive
    //             //which are not basic chemical and having matching usageInFactory with scope
    //             console.info("Positive Name :" + formula.name + "  basic chemical =" + isBasicChemicalCategory + " usageInFactory Matched " + isUsageInFactoryMatchesWithScope)

    //             result['positiveChemicals'].push(formula)

    //         } else {
    //             console.info("Negative Name :" + formula.name + "  basic chemical =" + isBasicChemicalCategory + " usageInFactory Matched " + isUsageInFactoryMatchesWithScope)

    //             result['negativeChemicals'].push(formula)
    //         }

    //     });

    //     return result
    // }




    checkForOtherCertification(f) {
        return (
            (f.gatewaySubscriptionStatus == 2 &&
                (
                    (f.incheckLevel1 == 1 || f.incheckLevel2 == 1 || f.incheckLevel3 == 1)
                    ||
                    (
                        (
                            f.gots == 1
                            || f.toxfmd == 1
                            || f.ecoPassport == 1
                            || f.zdhcMrsl == 1
                            || f.bluesign == 1
                            || f.scivera == 1
                            || (f.inditexAboveThreeYears == 'A' || f.inditexAboveThreeYears == 'B' || f.inditexAboveThreeYears == 'C')
                            || (f.testReport == 1 && f.zdhcMrslVersion !== '1.1')
                            || f.thirdPartyVerifierBool == 1
                            || f.greenScreen == 1
                        )
                        &&

                        (f.gatewayLevel == null || f.incheckOldzdhc == 1 || f.incheckRegistered == 1 || f.incheckExpired == 1)
                    )
                )
            )
            ||
            (f.gatewaySubscriptionStatus !== 2 &&
                (
                    f.gots == 1
                    || f.toxfmd == 1
                    || f.ecoPassport == 1
                    || f.zdhcMrsl == 1
                    || f.bluesign == 1
                    || f.scivera == 1
                    || (f.inditexAboveThreeYears == 'A' || f.inditexAboveThreeYears == 'B' || f.inditexAboveThreeYears == 'C')
                    || (f.testReport == 1 && f.zdhcMrslVersion != '1.1')
                    || f.thirdPartyVerifierBool == 1
                    || f.greenScreen == 1
                )
            ))


    }


}
