import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-factory-reporting',
  templateUrl: './factory-reporting.component.html',
  styleUrls: ['./factory-reporting.component.css']
})
export class FactoryReportingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
