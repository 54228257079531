import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BrandSideNewLaunchDialogComponent } from 'app/brand-side-new-launch-dialog/brand-side-new-launch-dialog.component';

@Component({
    selector: 'app-custom-dashboard',
    templateUrl: './custom-dashboard.component.html',
    styleUrls: ['./custom-dashboard.component.scss']
})
export class CustomDashboardComponent implements OnInit {

    appVersionKey = "brandAppVersion";
    latestDialogVersion = "1.0"

    constructor(private dialog: MatDialog) { }

    ngOnInit(): void {
        let localStorageDialogVersion = localStorage.getItem(this.appVersionKey);
        let sessionStorageDialogVersion = sessionStorage.getItem(this.appVersionKey);

        if ((sessionStorageDialogVersion == undefined || sessionStorageDialogVersion == null || sessionStorageDialogVersion != this.latestDialogVersion)
            && (localStorageDialogVersion == undefined || localStorageDialogVersion == null || localStorageDialogVersion != this.latestDialogVersion)) {
            //show Dialog
            this.showNewLaunchInfoDialog();
        }
    }

    showNewLaunchInfoDialog() {
        const dialogRef = this.dialog.open(BrandSideNewLaunchDialogComponent, {
            width: '55%',
            //   height: '30%'
        });

        dialogRef.afterClosed().subscribe(response => {

            if (response) {
                localStorage.setItem(this.appVersionKey, this.latestDialogVersion)
            } else {
                sessionStorage.setItem(this.appVersionKey, this.latestDialogVersion)
            }


        });

    }

}
