<div class="w3-large" style="float: right;">
    <a (click)="Cancel()">
        <mat-icon>close</mat-icon>
    </a>
</div>


<h1 mat-dialog-title i18n="Formula Details header | Headline of the formula details component"><b>{{data.name}}</b>
  <br>
  <i>{{data.manufacturer}}</i>
  <br>
  <p><b>{{"FORMULA_DETAILS_COMPONENT.HEADER.ADDITIONAL_INFORMATION_HEADER" | translate}}</b></p>
  <small>{{"FORMULA_DETAILS_COMPONENT.HEADER.ADDITIONAL_INFORMATION_BODY" | translate}}</small>
</h1>
<br><br>
<mat-tab-group [(selectedIndex)]="matTabIndex">
    <mat-tab label="{{'FORMULA_DETAILS_COMPONENT.TAB_HEADERS.GENERAL_INFORMATION'| translate}}">
        <br><br>
        <div>
        <div i18n="Formula Details GHS MSDS Available div">
            <mat-label>{{"FORMULA_DETAILS_COMPONENT.FORMULA_DETAILS.GHS_MSDS.HEADER" | translate}}</mat-label>&nbsp;&nbsp;
            <select *ngIf= "checkFieldToShow(data, 'ghs_msds_available')" [disabled]="true" matInput [(ngModel)]="data.ghs_msds_available">
                <option value=""></option>
                <option value="Yes">{{"FORMULA_DETAILS_COMPONENT.FORMULA_DETAILS.GHS_MSDS.VALUE_YES" | translate}}</option>
                <option value="No">{{"FORMULA_DETAILS_COMPONENT.FORMULA_DETAILS.GHS_MSDS.VALUE_NO" | translate}}</option>
            </select>
            <select class = "user-edited" *ngIf= "!checkFieldToShow(data, 'ghs_msds_available')"  matInput [(ngModel)]="data.userghsmsds_available">
                <option value=""></option>
                <option value="Yes">{{"FORMULA_DETAILS_COMPONENT.FORMULA_DETAILS.GHS_MSDS.VALUE_YES" | translate}}</option>
                <option value="No">{{"FORMULA_DETAILS_COMPONENT.FORMULA_DETAILS.GHS_MSDS.VALUE_NO" | translate}}</option>
            </select>
        </div>

        <br>

        <div i18n="Edit Data Dialog GHS MSDS Available div">
        <mat-label> General Usage in Factory</mat-label>&nbsp;&nbsp;
        <select class = "user-edited" matInput [(ngModel)]="data['usageInFactory']">
            <option *ngFor="let formulaUsage of formulaUsageInFactory" [value]="formulaUsage.value">
                {{formulaUsage.name}}
            </option>
        </select>
        </div>



        <br>
        <div i18n="Formula Details CAS No. div">
            <mat-form-field>
                <mat-label>{{"FORMULA_DETAILS_COMPONENT.FORMULA_DETAILS.CAS_NO" | translate}}</mat-label>
                <textarea *ngIf= "checkFieldToShow(data,'cas_nr')" [readonly]="true" matInput [(ngModel)]="data.cas_nr"></textarea>
                <textarea class = "user-edited"  *ngIf= "!checkFieldToShow(data,'cas_nr')" matInput [(ngModel)] = "data.user_cas_nr"></textarea>
            </mat-form-field>
        </div>
            <br />
        <div>
            <mat-form-field *ngIf="checkFieldToShow(data,'application')" class="bottom-align">
            <mat-label>
                {{"FORMULA_DETAILS_COMPONENT.FORMULA_DETAILS.APPLICATIONS" | translate}}
            </mat-label>

            <textarea  [readonly]="true" matInput [(ngModel)]="data.application"></textarea>
            </mat-form-field>
            <mat-form-field *ngIf="!checkFieldToShow(data,'application')"  class="bottom-align">
            <mat-label>
                {{"FORMULA_DETAILS_COMPONENT.FORMULA_DETAILS.APPLICATIONS" | translate}}
            </mat-label>
            <mat-chip-list class="mat-chip-list-stacked" #applicationsList>
                <mat-chip *ngFor="let application of applications" [selectable]="applicationsSelectable"
                        [removable]="applicationsRemovable" (removed)="removeApplication(application)">
                <mat-icon matChipRemove *ngIf="applicationsRemovable">cancel</mat-icon>
                {{application}}
                </mat-chip>
            </mat-chip-list>
            <div class="input-div">
                <input
                class="chip-input"
                [(ngModel)]="applicationInput"
                matInput
                type="text"
                (keyup)="filterApplications($event)"
                [matAutocomplete]="autoGroup"
                [matChipInputFor]="applicationsList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="resetApplication($event)">
            </div>
                <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="addAutocompleteApplication($event)">
                <mat-optgroup *ngFor="let category of useCategoryOptions" [label]="category.header">
                    <mat-option *ngFor="let option of category.options" [value]="option">{{option}}</mat-option>
                </mat-optgroup>
            </mat-autocomplete>
            </mat-form-field>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <mat-form-field *ngIf="checkFieldToShow(data,'ghs_hazard_statement')" class="bottom-align">
            <mat-label>
                {{"FORMULA_DETAILS_COMPONENT.FORMULA_DETAILS.GHS_HAZARD_STATEMENTS" | translate}}
            </mat-label>
            <textarea  [readonly]="true"  matInput [(ngModel)]="data.ghs_hazard_statement"></textarea>
            </mat-form-field>
            <mat-form-field *ngIf="!checkFieldToShow(data,'ghs_hazard_statement')"  class="bottom-align">
            <mat-label>
                {{"FORMULA_DETAILS_COMPONENT.FORMULA_DETAILS.GHS_HAZARD_STATEMENTS" | translate}}
            </mat-label>
            <mat-chip-list class="mat-chip-list-stacked" #statementsList>
                <mat-chip *ngFor="let statement of hazardStatements" [selectable]="statementsSelectable"
                        [removable]="statementsRemovable" (removed)="removeStatement(statement)">
                <mat-icon matChipRemove *ngIf="statementsRemovable">cancel</mat-icon>
                {{statement}}
                </mat-chip>
            </mat-chip-list>
            <div class="input-div">
                <input
                class="chip-input"
                [(ngModel)]="statementInput"
                matInput
                type="text"
                (keyup)="filterStatements()"
                [matChipInputFor]="statementsList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="resetStatement($event)"
                [matAutocomplete]="autoStatements">
            </div>
                <mat-autocomplete #autoStatements="matAutocomplete" (optionSelected)="addAutocompleteStatement($event)">
                <mat-optgroup *ngFor="let statement of statementOptions" [label]="statement.header">
                    <mat-option *ngFor="let option of statement.options" [value]="option">{{option}}</mat-option>
                </mat-optgroup>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <div i18n="Formula Details GHS Classification div">
            <mat-form-field>
                <mat-label>{{"FORMULA_DETAILS_COMPONENT.FORMULA_DETAILS.GHS_CLASSIFICATION" | translate}}</mat-label>
                <textarea *ngIf= "checkFieldToShow(data,'ghs_classification')"  [readonly]="true" matInput [(ngModel)]="data.ghs_classification"></textarea>
                <textarea class = "user-edited"  *ngIf= "!checkFieldToShow(data,'ghs_classification')"  matInput [(ngModel)]="data.userghsclassification"></textarea>
            </mat-form-field>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <mat-form-field>
                <mat-label>{{"FORMULA_DETAILS_COMPONENT.FORMULA_DETAILS.PRECAUTIONARY_STATEMENT" | translate}}</mat-label>
                <textarea *ngIf=  "checkFieldToShow(data,'precautionary_statement')" [readonly]="true" matInput [(ngModel)]="data.precautionary_statement"></textarea>
                <textarea class = "user-edited"  *ngIf= "!checkFieldToShow(data,'precautionary_statement')" matInput [(ngModel)]="data.user_precautionary_statement"></textarea>
            </mat-form-field>
        </div>
        <div i18n="Formulas Details Use of PPE div">
            <mat-form-field>
                <mat-label>{{"FORMULA_DETAILS_COMPONENT.FORMULA_DETAILS.USE_OF_PPE" | translate}}</mat-label>
                <textarea *ngIf=  "checkFieldToShow(data,'useofppe')" [readonly]="true" matInput [(ngModel)]="data.useofppe"></textarea>
                <textarea class = "user-edited"  *ngIf=  "!checkFieldToShow(data,'useofppe')"  matInput [(ngModel)]="data.user_use_ofppe"></textarea>
            </mat-form-field>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <mat-form-field>
                <mat-label>{{"FORMULA_DETAILS_COMPONENT.FORMULA_DETAILS.NOTES" | translate}}</mat-label>
                <textarea *ngIf=  "checkFieldToShow(data,'notes')" [readonly]="true" matInput [(ngModel)]="data.notes"></textarea>
                <textarea class = "user-edited" *ngIf=  "!checkFieldToShow(data,'notes')" matInput [(ngModel)]="data.user_notes"></textarea>
            </mat-form-field>
        </div>
        </div>
    </mat-tab>
    <mat-tab label="{{'FORMULA_DETAILS_COMPONENT.TAB_HEADERS.CHEMICAL_COMPOSITION'| translate}}">
        <br><br>
        <table class="table table-striped tablefixed">
            <th>{{"FORMULA_DETAILS_COMPONENT.CHEMICAL_COMPOSITION.CAS_NR"| translate}}</th>
            <th>{{"FORMULA_DETAILS_COMPONENT.CHEMICAL_COMPOSITION.SUBSTANCE_NAME" | translate}}</th>
            <th>{{"FORMULA_DETAILS_COMPONENT.CHEMICAL_COMPOSITION.CONTENT"| translate}}</th>
            <th>{{"FORMULA_DETAILS_COMPONENT.CHEMICAL_COMPOSITION.ACTION" | translate}}</th>
            <tr *ngFor="let substance of formulation, let index = index">
                <td>{{substance.casnr}}</td>
                <td>{{substance.substanceName}}</td>
                <td>{{substance.percentage}}</td>
                <td><a (click)="deleteSubstance(index)" class="table-link"><mat-icon class="table-icon">delete_outline</mat-icon></a></td>
            </tr>
        </table>
        <br><br>
        <form [formGroup]="chemicalFormulation" >
            <mat-form-field class='question'>
                <mat-label>{{"FORMULA_DETAILS_COMPONENT.CHEMICAL_COMPOSITION.CAS_NR" | translate}}</mat-label>&nbsp;&nbsp;
                <input matInput type="text" formControlName="casNrControl" required>
                <mat-error *ngIf="this.chemicalFormulation.controls['casNrControl'].invalid">{{"FORMULA_DETAILS_COMPONENT.CHEMICAL_COMPOSITION.ERROR_STATES.MISSING_CAS_NR" | translate}}</mat-error>
            </mat-form-field>
            &nbsp;  &nbsp;
            <mat-form-field class='question'>
                <mat-label>{{"FORMULA_DETAILS_COMPONENT.CHEMICAL_COMPOSITION.SUBSTANCE_NAME"| translate}}</mat-label>&nbsp;&nbsp;
                <input matInput type="text" formControlName="substanceName">
                <mat-error *ngIf="this.chemicalFormulation.controls['substanceName'].invalid">{{"FORMULA_DETAILS_COMPONENT.CHEMICAL_COMPOSITION.ERROR_STATES.MISSING_SUBSTANCE_NAME" | translate}}</mat-error>
            </mat-form-field>
            &nbsp;  &nbsp;
            <mat-form-field class='question'>
                <mat-label>{{"FORMULA_DETAILS_COMPONENT.CHEMICAL_COMPOSITION.CONTENT" | translate}}</mat-label>&nbsp;&nbsp;
                <input matInput type="number" formControlName="percentage">
                <mat-error *ngIf="this.chemicalFormulation.controls['percentage'].invalid">{{"FORMULA_DETAILS_COMPONENT.CHEMICAL_COMPOSITION.ERROR_STATES.MISSING_CONTENT_RATE" | translate}}</mat-error>
            </mat-form-field>
            &nbsp;  &nbsp;
            <button (click)="addToFormulation()" class="btn">{{"FORMULA_DETAILS_COMPONENT.CHEMICAL_COMPOSITION.ADD_BUTTON" | translate}}</button>
        </form>
    </mat-tab>
    <mat-tab label="{{'FORMULA_DETAILS_COMPONENT.TAB_HEADERS.CORRECTIVE_ACTION_PLAN'| translate}}">
        <br><br>
        <form [formGroup]='correctiveActionPlan' id='formCAP' #CAPForm="ngForm">
            <mat-form-field class='capQuestions'>
                <mat-label>{{"FORMULA_DETAILS_COMPONENT.CORRECTIVE_ACTION_PLAN.REMEDIATION" | translate}}</mat-label>
                <mat-select matNativeControl formControlName="remediation">
                    <mat-option value="Stop">
                        {{"FORMULA_DETAILS_COMPONENT.CORRECTIVE_ACTION_PLAN.OPTIONS.STOP" | translate}}
                    </mat-option>
                    <mat-option value="Substitute">
                        {{"FORMULA_DETAILS_COMPONENT.CORRECTIVE_ACTION_PLAN.OPTIONS.SUBSTITUTE" | translate}}
                    </mat-option>
                    <mat-option value="Update">
                        {{"FORMULA_DETAILS_COMPONENT.CORRECTIVE_ACTION_PLAN.OPTIONS.UPDATE" | translate}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="this.correctiveActionPlan.controls['remediation'].invalid">{{"FORMULA_DETAILS_COMPONENT.CORRECTIVE_ACTION_PLAN.ERROR_STATES.MISSING_REMEDIATION_PLAN" | translate}}</mat-error>
            </mat-form-field>
            <br>
            <mat-form-field class='capQuestions' *ngIf="this.correctiveActionPlan.controls['remediation'].value === 'Substitute'">
                <mat-label>{{"FORMULA_DETAILS_COMPONENT.CORRECTIVE_ACTION_PLAN.CHEMICAL_NAME" | translate}}</mat-label>
                <input matInput formControlName="action">
                <mat-error *ngIf="this.correctiveActionPlan.controls['action'].invalid">{{"FORMULA_DETAILS_COMPONENT.CORRECTIVE_ACTION_PLAN.ERROR_STATES.MISSING_SUBSTITUTED_CHEMICAL" | translate}}{{data['name']}}</mat-error>
            </mat-form-field>
            <mat-form-field class='capQuestions' *ngIf="this.correctiveActionPlan.controls['remediation'].value === 'Update'">
                <mat-label>{{"FORMULA_DETAILS_COMPONENT.CORRECTIVE_ACTION_PLAN.ACTION" | translate}}</mat-label>
                <mat-select matNativeControl formControlName="action">
                    <mat-option value="Test">
                        {{"FORMULA_DETAILS_COMPONENT.CORRECTIVE_ACTION_PLAN.UPDATE_OPTIONS.TEST" | translate}}
                    </mat-option>
                    <mat-option value="Certify">
                        {{"FORMULA_DETAILS_COMPONENT.CORRECTIVE_ACTION_PLAN.UPDATE_OPTIONS.CERTIFY" | translate}}
                    </mat-option>
                    <mat-option value="Contact Chemical Supplier">
                        {{"FORMULA_DETAILS_COMPONENT.CORRECTIVE_ACTION_PLAN.UPDATE_OPTIONS.CONTACT_CHEMICAL_SUPPLIER" | translate}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="this.correctiveActionPlan.controls['action'].invalid">{{"FORMULA_DETAILS_COMPONENT.CORRECTIVE_ACTION_PLAN.ERROR_STATES.MISSING_ACTION" | translate}}</mat-error>
            </mat-form-field>
            <br>
            <mat-form-field class='capQuestions'>
                <mat-label>{{"FORMULA_DETAILS_COMPONENT.CORRECTIVE_ACTION_PLAN.DEADLINE" | translate}}</mat-label>
                <input matInput [min]="this.dateToday" formControlName="deadline" [matDatepicker]="datePicker">
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker startView="year"></mat-datepicker>
                <mat-error *ngIf="this.correctiveActionPlan.controls['deadline'].invalid">{{"FORMULA_DETAILS_COMPONENT.CORRECTIVE_ACTION_PLAN.ERROR_STATES.MISSING_DEADLINE" | translate}}</mat-error>
            </mat-form-field>
        </form>
    </mat-tab>
</mat-tab-group>

<div mat-dialog-actions i18n="Formula Details Save and Cancel Buttons">
  <button mat-button mat-raised-button form="formCAP"  [disabled]="data.readonly" (click)="Save(data);CAPForm.ngSubmit.emit()">{{"FORMULA_DETAILS_COMPONENT.FORMULA_DETAILS.SAVE_BUTTON" | translate}}</button>
  <button mat-button mat-raised-button (click)="Cancel()" >{{"FORMULA_DETAILS_COMPONENT.FORMULA_DETAILS.CANCEL_BUTTON" | translate}}</button>
</div>
