import { Injectable, OnInit } from '@angular/core';
import { Stack } from '../stack';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MtaSuppliersService implements OnInit {

  baseHref = environment.baseHref;
  selectedSuppliers = new Stack<any>();
  public static isRoutingFromMenu=false;

  supplierNetworkGraphData$=new Subject<any>();
  selectedOrganization$=new Subject<any>();



  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {
  }

  /*********************************************************************************************
   * 
   *  REST API EndPoint
   * 
   *********************************************************************************************/

  getMySupplierData(id, shared?:any) {

    return this.http.get<any>(this.baseHref + '/getSupplierDetails/' + id + '/' + shared);
  }

  getMySupplierList(id ?:number, tierLevel ?: number) {
    
    let httpParams = new HttpParams();

    if (id !== undefined && id !== null) {
      httpParams = httpParams.set('parentId', id.toString());
    }

    httpParams =httpParams.set('maxDepth', tierLevel.toString());

    return this.http.get<any>(this.baseHref + '/mta/getMyLevelOneSuppliers', { params: httpParams } );
  }

  getMySelfData(id?: any) {
    return this.http.get<any>(environment.baseHref + '/mta/selfData/' + id);
  }

  getMySuppliersData(id?: any) {
    return this.http.get<any>(environment.baseHref + '/mta/getMySuppliers/' + id);
  }

  getProfileDetails(id: string, shared: string | boolean){
    return this.http.get<any>(environment.baseHref + '/getSupplierDetails/' + id + '/' + shared);
  }




  /*********************************************************************************************
   * 
   *   MTA- Store Organization hierarchy data
   * 
   *********************************************************************************************/

  // Push data onto the stack
  addToHistory(data: any): void {
    this.selectedSuppliers.push(data);
    console.log(this.selectedSuppliers)
  }

  // Pop data from the stack
  removeFromHistory(): any | undefined {
    return this.selectedSuppliers.pop();
  }

  // Get the current data on top of the stack without removing it
  getCurrentState(): any | undefined {
    return this.selectedSuppliers.peek();
  }

  // Check if the history stack is empty
  isHistoryEmpty(): boolean {
    return this.selectedSuppliers.isEmpty();
  }

  // Clear the entire history stack
  clearHistory(): void {
    this.selectedSuppliers.clear();
  }

  // Get the size of the history stack
  getHistorySize(): number {
    return this.selectedSuppliers.size();
  }

  getAllData(): any[] {
    return this.selectedSuppliers.toArray();
  }

  updateLastHistory(updatedData: any){
    // remove first data
    this.removeFromHistory();
    this.addToHistory(updatedData);
    console.log(this.selectedSuppliers.peek())
  }


  // Pop data from the stack until the current data is found
  removeFromHistoryUntilCurrent(currentData: any) {

    while (!this.selectedSuppliers.isEmpty()) {
      let currentItem = this.selectedSuppliers.peek();

      if (currentItem.id === currentData.id) {
        // Stop popping when the current data is found
        break;
      }else{
          this.selectedSuppliers.pop();
      }
    }

  }


}
