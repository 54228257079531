import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { GlobalsService } from '../../globals-services/globals.service';

import { SubscriptionStatusEnum } from '../subscriptionStatus';


export interface GatewayAuthenticationToken {
  tokenType: string;
  accessToken: string;
  expiresInSeconds: number;
  refreshToken: string;
}

export interface GatewayOrganization {
  Name: string;
  OrgGUID: string;
  SupplierAID: string;
  Type: string;
}

export class GatewayAuthenticationState {

  /*
    Check Gateway API here for authentication state:
    GET /tokenstatus
  */
  static authenticated(token: GatewayAuthenticationToken): GatewayAuthenticationState {
    const authenticationState = new GatewayAuthenticationState();
    authenticationState.authenticated = true;
    authenticationState.token = token;
    return authenticationState;
  }

  authenticated: boolean;
  token: GatewayAuthenticationToken;
  // username: string;

}

@Injectable()
export class GatewayAuthenticationService {
  public gatewayOrganisations: GatewayOrganization[];
  public authenticationState: GatewayAuthenticationState;
  public gwK: string = "";
  public subscriptionStatus = {};
  subscriptionStatusEnum = SubscriptionStatusEnum;
  public static isActiveGatewaySubcription: boolean;
  public userAccessKey: String;

  get authenticated(): boolean {
    if (!this.authenticationState) {
      return false;
    }
    return this.authenticationState.authenticated;
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private globalsService: GlobalsService,
    //  private bhiveAuthService: AuthenticationService
  ) {
  }

  organizationDetails(): Promise<any> {
    let url = this.globalsService.gatewayUrl + "/v2/userOrganizations"
    return this.http.get(url).toPromise();
  }

  login(targetPath: string, queryParams) {
    if (!this.authenticated) {
      this.authenticate(targetPath, queryParams);
    }
  }

  private authenticate(targetPath: string, queryParams) {
    this.router.navigate(['/gateway'], {
      queryParams: queryParams,
      skipLocationChange: true,
    });
  }

  validateUserCredentials(username: string, password: string): Promise<string> {
    let gwKey = this.gwK;

    return this.http.post<GatewayAuthenticationToken>(this.globalsService.gatewayUrl + '/oauth/token', { loginCredentials: { username: username, password: password }, authenticationKey: gwKey }).toPromise()
      .then(token => {
        // console.log(token);
        if (token["result"]["success"]) {
          this.authenticationState = GatewayAuthenticationState.authenticated(token["token"]);
          this.userAccessKey = token['UserAccessKey'] 
          return Promise.resolve(username);
        } else {
          return Promise.reject("Login was unsuccessful!");
        }
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  validateUserAccessKey(accessKey: string): Promise<string> {
    let gwKey = this.gwK;

    return this.http.post<GatewayAuthenticationToken>(this.globalsService.gatewayUrl + '/oauth/tokenbykey', { userAccessKey: accessKey, authenticationKey: gwKey }).toPromise()
      .then(token => {
        // console.log(token);
        if (token["result"]["success"]) {
          this.authenticationState = GatewayAuthenticationState.authenticated(token["token"]);
          return Promise.resolve(accessKey);
        } else {
          return Promise.reject("Login was unsuccessful!");
        }
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  logout() {
    /*
      Log out from Gateway API:
      GET /terminateSession
    */
    this.authenticationState = null;
    this.gatewayOrganisations = null;
    setTimeout(() => {
      window.location.href = this.globalsService.homeUrl;
    }, 500);
  }

  getToken(): Promise<GatewayAuthenticationToken> {
    // TODO implement token refresh
    if (this.authenticationState.token !== undefined) {
      return Promise.resolve(
        this.authenticationState.token
      );
    } else {
      return Promise.reject('NOT_LOGGED_IN');
    }
  }

  fetchGatewayOrganizations() {

    return this.http.get<GatewayOrganization[]>(this.globalsService.gatewayUrl + '/v2/userOrganizations').toPromise();

  }


  fetchGwK() {

    this.gwK = environment.gatewayAuthenticationKey;
  }

  fetchGatewaySubscriptionStatus() {

    let userId = { userId: this.globalsService.profile["id"] }

    this.http.post<any>(this.globalsService.bhiveIncheckServiceUrl + "/subscription/getStatus", userId).subscribe(status => {

      // console.log(status);
      this.subscriptionStatus = status;
      GatewayAuthenticationService.isActiveGatewaySubcription = status['status'] === SubscriptionStatusEnum.Active;
      // console.log(this.subscriptionStatus);
    },
      error => {
        console.log(error);
      });
  }


  purchaseInCheckSubscription(OrgGUID, OrgName) {

    //   return this.http.post<any>(this.globalsService.gatewayServiceMockUrl + '/v5/PurchaseInCheckSubscription?OrgGUID=' + OrgGUID + "&OrgName=" + OrgName,null)

    return this.http.post<any>(this.globalsService.gatewayUrl + '/v5/PurchaseInCheckSubscription?OrgGUID=' + OrgGUID + "&OrgName=" + OrgName, null)


  }

  renewInCheckSubscription(OrgGUID, ExpirationDate) {

    //  return this.http.post<any>(this.globalsService.gatewayServiceMockUrl + '/v5/RenewInCheckSubscription?OrgGUID=' + OrgGUID + "&ExpirationDate=" + ExpirationDate,null)

    return this.http.post<any>(this.globalsService.gatewayUrl + '/v5/RenewInCheckSubscription?OrgGUID=' + OrgGUID + "&ExpirationDate=" + ExpirationDate, null)


  }

  refundInCheckSubscription(OrgGUID) {

    //  return this.http.post<any>(this.globalsService.gatewayServiceMockUrl + '/v5/RenewInCheckSubscription?OrgGUID=' + OrgGUID + "&ExpirationDate=" + ExpirationDate,null)

    return this.http.post<any>(this.globalsService.gatewayUrl + '/v5/RefundInCheck?OrgGUID=' + OrgGUID, null)
  }
}

