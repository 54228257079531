import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith } from 'rxjs/operators';
import { GlobalsService } from '../../globals-services/globals.service';
import { DocumentcenterComponent } from '../documentcenter.component';
import { ChemicalDocumentData, ZdhcParameters, anions, conventionalParameters, metals, persistentFoamOptions, samplingPoint, sumParameters, typeOfDischarge, zdhcParametersOptions } from './waste-water-options';

@Component({
    selector: 'app-waste-water',
    templateUrl: './waste-water.component.html',
    styleUrls: ['./waste-water.component.css']
    //   encapsulation: ViewEncapsulation.None
})
export class WasteWaterComponent implements OnInit {

    @ViewChild('stepper', { static: true }) private myStepper: MatStepper;
    alertMsgs = {};
    document: ChemicalDocumentData;

    testWater: Boolean;
    viewMode: Boolean
    isLatestReport=false
    dataSensitivity = 1;
    loading = true

    //options for the other labs
    othersLabMode = false;
    otherLabRequiredFields = ['tempDelta', 'tempDeltaUnit', 'temp', 'tempUnit', 'tss', 'tssUnit', 'cod', 'codUnit', 'ph', 'bod5', 'bod5Unit', 'persistentFoam']

    basicInformation: FormGroup;
    conventionalSumParameters: FormGroup;
    conventionalAnions: FormGroup;
    conventionalMetals: FormGroup;
    zdhcMrslParameters: FormGroup;

    //approved labs option
    approvedLabs;

    //option imports
    samplingPoint = samplingPoint;
    typeOfDischarge = typeOfDischarge;

    //data templates-- needs to be deep copy when assigned to the table, otherwise the old state is saved
    sumParameters = sumParameters;
    anions = anions;
    metals = metals;

    //this needs to be setup otherwise the number will be interpreted as string in matoption
    persistentFoamOptions = persistentFoamOptions

    //options chosen must be removed from the available lists
    zdhcParametersOptions = zdhcParametersOptions.slice();
    zdhcAvailableParametersOptions = zdhcParametersOptions.slice();
    excludedOptions = []
    userDisplayedZDHCParametersOptions;

    sumParametersTable = new MatTableDataSource<conventionalParameters>();
    anionsParametersTable = new MatTableDataSource<conventionalParameters>();
    metalsParametersTable = new MatTableDataSource<conventionalParameters>();
    zdhcMrslParametersTable = new MatTableDataSource<ZdhcParameters>();

    //resultGrade = ['Aspirational', 'Progressive', 'Foundational', 'Failed'] // the index will determine the result stored in the system
    resultGrade = ['WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.ASPIRATIONAL', 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.PROGRESSIVE', 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FOUNDATIONAL', 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FAILED']
    conventionalParameters = ['name', 'result', 'limits', 'actionplan', 'deadline']
    displayedColumnsZdhcParameters = ['substance', 'substanceGroup', 'result', 'aboveLimit', 'actionplan', 'deadline', 'delete']

    mindate: Date;

    constructor(
        private translateService: TranslateService,
        public dialogRef: MatDialogRef<DocumentcenterComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private _formBuilder: FormBuilder,
        //    private cdr: ChangeDetectorRef,
        private globalsService: GlobalsService,
        private http: HttpClient
    ) {
        this.fetchTranslations();
        this.translateService.onLangChange.subscribe(() => {
            // this.loading = true;
            this.fetchTranslations();
        });
        this.mindate = new Date();
    }

    ngOnInit() {
        this.viewMode = this.data['viewMode']
        this.isLatestReport = this.data['isLatestReport']
        this.document = this.data['consumption']
        this.dataSensitivity = this.data['dataSensitivity'] ? this.data['dataSensitivity'] : 1
        this.initializeApprovedLabs()
        this.initializeDataEntry()
    }


    initializeApprovedLabs() {
        this.http.get<any>(this.globalsService.baseHref + `/getAllZdhcApprovedLabs`).subscribe((response => {
            this.approvedLabs = response;
        }))
    }

    initializeDataEntry() {

        //initialize the form controls
        this.basicInformation = this._formBuilder.group({
            name: ['', Validators.required],
            expiry: ['', ''],
            testingLab: ['', Validators.required],
            testingLabOthers: [{ value: '', disabled: true }, ''],
            samplingLocation: ['rawWasteWater', Validators.required],
            typeOfWaterDischarge: ['', Validators.required],
            samplingDate: ['', Validators.required],
            testingDate: ['', Validators.required],
            issueDate: ['', Validators.required],
            passfail: ['', ''],
            comment: ['', ''],
            documentID: ['', Validators.required],
        });

        this.conventionalSumParameters = this._formBuilder.group({
            tempDelta: ['', Validators.required],
            tempDeltaDeadline: [this.mindate, ''],
            tempDeltaStatus: ['NoneTaken', ''],
            tempDeltaUnit: ['celsius', Validators.required],
            temp: ['', Validators.required],
            tempDeadline: [this.mindate, ''],
            tempStatus: ['NoneTaken', ''],
            tempUnit: ['celsius', Validators.required],
            tss: ['', Validators.required],
            tssDeadline: [this.mindate, ''],
            tssStatus: ['NoneTaken', ''],
            tssUnit: ['mg/L', Validators.required],
            cod: ['', Validators.required],
            codDeadline: [this.mindate, ''],
            codStatus: ['NoneTaken', ''],
            codUnit: ['mg/L', Validators.required],
            total: ['', Validators.required],
            totalDeadline: [this.mindate, ''],
            totalStatus: ['NoneTaken', ''],
            totalUnit: ['mg/L', Validators.required],
            ph: ['', Validators.required],
            phDeadline: [this.mindate, ''],
            phStatus: ['NoneTaken', ''],
            phUnit: ['NONE', ''],
            color436: ['', Validators.required],
            color436Deadline: [this.mindate, ''],
            color436Status: ['NoneTaken', ''],
            color436Unit: ['NONE', ''],
            color525: ['', Validators.required],
            color525Deadline: [this.mindate, ''],
            color525Status: ['NoneTaken', ''],
            color525Unit: ['NONE', ''],
            color620: ['', Validators.required],
            color620Deadline: [this.mindate, ''],
            color620Status: ['NoneTaken', ''],
            color620Unit: ['NONE', ''],
            bod5: ['', Validators.required],
            bod5Deadline: [this.mindate, ''],
            bod5Status: ['NoneTaken', ''],
            bod5Unit: ['mg/L', Validators.required],
            ammonionN: ['', Validators.required],
            ammonionNDeadline: [this.mindate, ''],
            ammonionNStatus: ['NoneTaken', ''],
            ammonionNUnit: ['mg/L', Validators.required],
            totalP: ['', Validators.required],
            totalPDeadline: [this.mindate, ''],
            totalPStatus: ['NoneTaken', ''],
            totalPUnit: ['mg/L', Validators.required],
            aox: ['', Validators.required],
            aoxDeadline: [this.mindate, ''],
            aoxStatus: ['NoneTaken', ''],
            aoxUnit: ['mg/L', Validators.required],
            oilAndGrease: ['', Validators.required],
            oilAndGreaseDeadline: [this.mindate, ''],
            oilAndGreaseStatus: ['NoneTaken', ''],
            oilAndGreaseUnit: ['mg/L', Validators.required],
            phenol: ['', Validators.required],
            phenolDeadline: [this.mindate, ''],
            phenolStatus: ['NoneTaken', ''],
            phenolUnit: ['mg/L', Validators.required],
            coliform: ['', Validators.required],
            coliformStatus: ['NoneTaken', Validators.required],
            coliformDeadline: [this.mindate, ''],
            coliformUnit: ['Number of Bacteria/100ml', Validators.required],
            persistentFoam: ['', Validators.required],
            persistentFoamDeadline: [this.mindate, ''],
            persistentFoamStatus: ['NoneTaken', ''],
            persistentFoamUnit: ['NONE', ''],
        });

        this.conventionalAnions = this._formBuilder.group({
            cyanide: ['', Validators.required],
            cyanideDeadline: [this.mindate, ''],
            cyanideStatus: ['NoneTaken', ''],
            cyanideUnit: ['mg/L', Validators.required],
            sulfide: ['', Validators.required],
            sulfideDeadline: [this.mindate, ''],
            sulfideStatus: ['NoneTaken', ''],
            sulfideUnit: ['mg/L', Validators.required],
            sulfite: ['', Validators.required],
            sulfiteDeadline: [this.mindate, ''],
            sulfiteStatus: ['NoneTaken', ''],
            sulfiteUnit: ['mg/L', Validators.required],
        });

        this.conventionalMetals = this._formBuilder.group({
            antimony: ['', ''],
            antimonyDeadline: [this.mindate, ''],
            antimonyStatus: ['NoneTaken', ''],
            antimonyUnit: ['mg/L', Validators.required],
            chromiumTotal: ['', Validators.required],
            chromiumTotalDeadline: [this.mindate, ''],
            chromiumTotalStatus: ['NoneTaken', ''],
            chromiumTotalUnit: ['mg/L', Validators.required],
            cobalt: ['', Validators.required],
            cobaltDeadline: [this.mindate, ''],
            cobaltStatus: ['NoneTaken', ''],
            cobaltUnit: ['mg/L', Validators.required],
            copper: ['', Validators.required],
            copperDeadline: [this.mindate, ''],
            copperStatus: ['NoneTaken', ''],
            copperUnit: ['mg/L', Validators.required],
            nickle: ['', Validators.required],
            nickleDeadline: [this.mindate, ''],
            nickleStatus: ['NoneTaken', ''],
            nickleUnit: ['mg/L', Validators.required],
            silver: ['', Validators.required],
            silverDeadline: [this.mindate, ''],
            silverStatus: ['NoneTaken', ''],
            silverUnit: ['mg/L', Validators.required],
            zinc: ['', Validators.required],
            zincDeadline: [this.mindate, ''],
            zincStatus: ['NoneTaken', ''],
            zincUnit: ['mg/L', Validators.required],
            arsenic: ['', Validators.required],
            arsenicDeadline: [this.mindate, ''],
            arsenicStatus: ['NoneTaken', ''],
            arsenicUnit: ['mg/L', Validators.required],
            cadmium: ['', Validators.required],
            cadmiumDeadline: [this.mindate, ''],
            cadmiumStatus: ['NoneTaken', ''],
            cadmiumUnit: ['mg/L', Validators.required],
            chromiumvi: ['', Validators.required],
            chromiumviDeadline: [this.mindate, ''],
            chromiumviStatus: ['NoneTaken', ''],
            chromiumviUnit: ['mg/L', Validators.required],
            _lead: ['', Validators.required],
            _leadDeadline: [this.mindate, ''],
            _leadStatus: ['NoneTaken', ''],
            _leadUnit: ['mg/L', Validators.required],
            mercury: ['', Validators.required],
            mercuryDeadline: [this.mindate, ''],
            mercuryStatus: ['NoneTaken', ''],
            mercuryUnit: ['mg/L', Validators.required]
        });

        this.zdhcMrslParameters = this._formBuilder.group({
            Substance: ['', Validators.required],
            Result: ['', Validators.required],
            SubstanceUnit: ['μg/L', Validators.required]
        });

        //initialize the table
        this.sumParametersTable.data = JSON.parse(JSON.stringify(sumParameters));
        this.anionsParametersTable.data = JSON.parse(JSON.stringify(anions));
        this.metalsParametersTable.data = JSON.parse(JSON.stringify(metals));

        if (this.viewMode) {
            this.getData() //replace form value with the data from the server

            this.myStepper.selectedIndex = this.data['page']
        } else {
            this.loading = false
        }
        // initialize autocomplete
        this.userDisplayedZDHCParametersOptions = this.zdhcMrslParameters.get('Substance').valueChanges.pipe(startWith(''),
            map(value => value ? this.zdhcParameterFilter(value) : this.zdhcAvailableParametersOptions)
        );


    }

    getData() {

        // the min date needs to be reset otherwise form validation will not allow the user to view the next page
        this.mindate = new Date("2000-01-01")
        const payload = {
            "documentID": this.data['id'],
            shared: this.dataSensitivity == 1 ? false : true
        }

        this.http.post<any>(this.globalsService.baseHref + '/getDetailedWasteWaterReport', payload).subscribe((result) => {

            //basic information
            this.basicInformation.get('name').setValue(result['documentName']);
            this.basicInformation.get('expiry').setValue(result['expiry']);
            this.basicInformation.get('testingLab').setValue(result['testingLab']);
            console.log(result['testingLab'])
            if (result['testingLab'] == 90) {
                this.othersLabMode = true;
            }
            this.basicInformation.get('testingLabOthers').setValue(result['testingLabOthers'] == 'null' ? '' : result['testingLabOthers']);
            this.basicInformation.get('samplingLocation').setValue(result['samplingLocation']);
            this.basicInformation.get('typeOfWaterDischarge').setValue(result['typeOfWaterDischarge']);
            this.basicInformation.get('samplingDate').setValue(result['samplingDate']);
            this.basicInformation.get('testingDate').setValue(result['testingDate']);
            this.basicInformation.get('issueDate').setValue(result['issueDate']);
            this.basicInformation.get('passfail').setValue(result['passfail'] < 2 ? 0 : 1); // fail pass is based on limits reached. The lower the better. If it is 3, then it is fail.
            this.basicInformation.get('comment').setValue(result['comment']);
            this.basicInformation.get('documentID').setValue(result['documentID']);

            //sum parameters
            this.conventionalSumParameters.get('tempDelta').setValue(this.convertBackToLabels(result['tempDelta']));
            this.conventionalSumParameters.get('tempDeltaDeadline').setValue(result['tempDeltaDeadline']);
            this.conventionalSumParameters.get('tempDeltaStatus').setValue(result['tempDeltaStatus']);
            this.conventionalSumParameters.get('tempDeltaUnit').setValue(result['tempDeltaUnit']);
            this.conventionalSumParameters.get('temp').setValue(this.convertBackToLabels(result['temp']));
            this.conventionalSumParameters.get('tempDeadline').setValue(result['tempDeadline']);
            this.conventionalSumParameters.get('tempStatus').setValue(result['tempStatus']);
            this.conventionalSumParameters.get('tempUnit').setValue(result['tempUnit']);
            this.conventionalSumParameters.get('tss').setValue(result['tss']);
            this.conventionalSumParameters.get('tssDeadline').setValue(result['tssDeadline']);
            this.conventionalSumParameters.get('tssStatus').setValue(result['tssStatus']);
            this.conventionalSumParameters.get('tssUnit').setValue(result['tssUnit']);
            this.conventionalSumParameters.get('cod').setValue(result['cod']);
            this.conventionalSumParameters.get('codDeadline').setValue(result['codDeadline']);
            this.conventionalSumParameters.get('codStatus').setValue(result['codStatus']);
            this.conventionalSumParameters.get('codUnit').setValue(result['codUnit']);
            this.conventionalSumParameters.get('total').setValue(result['total']);
            this.conventionalSumParameters.get('totalDeadline').setValue(result['totalDeadline']);
            this.conventionalSumParameters.get('totalStatus').setValue(result['totalStatus']);
            this.conventionalSumParameters.get('totalUnit').setValue(result['totalUnit']);
            this.conventionalSumParameters.get('ph').setValue(result['ph']);
            this.conventionalSumParameters.get('phDeadline').setValue(result['phDeadline']);
            this.conventionalSumParameters.get('phStatus').setValue(result['phStatus']);
            this.conventionalSumParameters.get('phUnit').setValue(result['phUnit']);

            this.conventionalSumParameters.get('color436').setValue(this.convertColorBackToLabels(parseInt(result['color436'])));
            this.conventionalSumParameters.get('color436Deadline').setValue(result['color436Deadline']);
            this.conventionalSumParameters.get('color436Status').setValue(result['color436Status']);
            this.conventionalSumParameters.get('color436Unit').setValue('');
            this.conventionalSumParameters.get('color525').setValue(this.convertColorBackToLabels(parseInt(result['color525'])));
            this.conventionalSumParameters.get('color525Deadline').setValue(result['color525Deadline']);
            this.conventionalSumParameters.get('color525Status').setValue(result['color525Status']);
            this.conventionalSumParameters.get('color525Unit').setValue('');
            this.conventionalSumParameters.get('color620').setValue(this.convertColorBackToLabels(parseInt(result['color620'])));
            this.conventionalSumParameters.get('color620Deadline').setValue(result['color620Deadline']);
            this.conventionalSumParameters.get('color620Status').setValue(result['color620Status']);
            this.conventionalSumParameters.get('color620Unit').setValue('');
            this.conventionalSumParameters.get('bod5').setValue(result['bod5']);
            this.conventionalSumParameters.get('bod5Deadline').setValue(result['bod5Deadline']);
            this.conventionalSumParameters.get('bod5Status').setValue(result['bod5Status']);
            this.conventionalSumParameters.get('bod5Unit').setValue(result['bod5Unit']);
            this.conventionalSumParameters.get('ammonionN').setValue(result['ammonionN']);
            this.conventionalSumParameters.get('ammonionNDeadline').setValue(result['ammonionNDeadline']);
            this.conventionalSumParameters.get('ammonionNStatus').setValue(result['ammonionNStatus']);
            this.conventionalSumParameters.get('ammonionNUnit').setValue(result['ammonionNUnit']);
            this.conventionalSumParameters.get('totalP').setValue(result['totalP']);
            this.conventionalSumParameters.get('totalPDeadline').setValue(result['totalPDeadline']);
            this.conventionalSumParameters.get('totalPStatus').setValue(result['totalPStatus']);
            this.conventionalSumParameters.get('totalPUnit').setValue(result['totalPUnit']);
            this.conventionalSumParameters.get('aox').setValue(result['aox']);
            this.conventionalSumParameters.get('aoxDeadline').setValue(result['aoxDeadline']);
            this.conventionalSumParameters.get('aoxStatus').setValue(result['aoxStatus']);
            this.conventionalSumParameters.get('aoxUnit').setValue(result['aoxUnit']);
            this.conventionalSumParameters.get('oilAndGrease').setValue(result['oilAndGrease']);
            this.conventionalSumParameters.get('oilAndGreaseDeadline').setValue(result['oilAndGreaseDeadline']);
            this.conventionalSumParameters.get('oilAndGreaseStatus').setValue(result['oilAndGreaseStatus']);
            this.conventionalSumParameters.get('oilAndGreaseUnit').setValue(result['oilAndGreaseUnit']);
            this.conventionalSumParameters.get('phenol').setValue(result['phenol']);
            this.conventionalSumParameters.get('phenolDeadline').setValue(result['phenolDeadline']);
            this.conventionalSumParameters.get('phenolStatus').setValue(result['phenolStatus']);
            this.conventionalSumParameters.get('phenolUnit').setValue(result['phenolUnit']);
            this.conventionalSumParameters.get('coliform').setValue(result['coliform']);
            this.conventionalSumParameters.get('coliformStatus').setValue(result['coliformStatus']);
            this.conventionalSumParameters.get('coliformDeadline').setValue(result['coliformDeadline']);
            this.conventionalSumParameters.get('coliformUnit').setValue(result['coliformUnit']);
            this.conventionalSumParameters.get('persistentFoam').setValue(result['persistentFoam']);
            this.conventionalSumParameters.get('persistentFoamDeadline').setValue(result['persistentFoamDeadline']);
            this.conventionalSumParameters.get('persistentFoamStatus').setValue(result['persistentFoamStatus']);
            this.conventionalSumParameters.get('persistentFoamUnit').setValue('');

            //anions
            this.conventionalAnions.get('cyanide').setValue(this.convertBackToLabels(result['cyanide']));
            this.conventionalAnions.get('cyanideDeadline').setValue(result['cyanideDeadline']);
            this.conventionalAnions.get('cyanideStatus').setValue(result['cyanideStatus']);
            this.conventionalAnions.get('cyanideUnit').setValue(result['cyanideUnit']);
            this.conventionalAnions.get('sulfide').setValue(this.convertBackToLabels(result['sulfide']));
            this.conventionalAnions.get('sulfideDeadline').setValue(result['sulfideDeadline']);
            this.conventionalAnions.get('sulfideStatus').setValue(result['sulfideStatus']);
            this.conventionalAnions.get('sulfideUnit').setValue(result['sulfideUnit']);
            this.conventionalAnions.get('sulfite').setValue(this.convertBackToLabels(result['sulfite']));
            this.conventionalAnions.get('sulfiteDeadline').setValue(result['sulfiteDeadline']);
            this.conventionalAnions.get('sulfiteStatus').setValue(result['sulfiteStatus']);
            this.conventionalAnions.get('sulfiteUnit').setValue(result['sulfiteUnit']);

            //metals
            this.conventionalMetals.get('antimony').setValue(this.convertBackToLabels(result['antimony']));
            this.conventionalMetals.get('antimonyDeadline').setValue(result['antimonyDeadline']);
            this.conventionalMetals.get('antimonyStatus').setValue(result['antimonyStatus']);
            this.conventionalMetals.get('antimonyUnit').setValue(result['antimonyUnit']);
            this.conventionalMetals.get('chromiumTotal').setValue(this.convertBackToLabels(result['chromiumTotal']));
            this.conventionalMetals.get('chromiumTotalDeadline').setValue(result['chromiumTotalDeadline']);
            this.conventionalMetals.get('chromiumTotalStatus').setValue(result['chromiumTotalStatus']);
            this.conventionalMetals.get('chromiumTotalUnit').setValue(result['chromiumTotalUnit']);
            this.conventionalMetals.get('cobalt').setValue(this.convertBackToLabels(result['cobalt']));
            this.conventionalMetals.get('cobaltDeadline').setValue(result['cobaltDeadline']);
            this.conventionalMetals.get('cobaltStatus').setValue(result['cobaltStatus']);
            this.conventionalMetals.get('cobaltUnit').setValue(result['cobaltUnit']);
            this.conventionalMetals.get('copper').setValue(this.convertBackToLabels(result['copper']));
            this.conventionalMetals.get('copperDeadline').setValue(result['copperDeadline']);
            this.conventionalMetals.get('copperStatus').setValue(result['copperStatus']);
            this.conventionalMetals.get('copperUnit').setValue(result['copperUnit']);
            this.conventionalMetals.get('nickle').setValue(this.convertBackToLabels(result['nickle']));
            this.conventionalMetals.get('nickleDeadline').setValue(result['nickleDeadline']);
            this.conventionalMetals.get('nickleStatus').setValue(result['nickleStatus']);
            this.conventionalMetals.get('nickleUnit').setValue(result['nickleUnit']);
            this.conventionalMetals.get('silver').setValue(this.convertBackToLabels(result['silver']));
            this.conventionalMetals.get('silverDeadline').setValue(result['silverDeadline']);
            this.conventionalMetals.get('silverStatus').setValue(result['silverStatus']);
            this.conventionalMetals.get('silverUnit').setValue(result['silverUnit']);
            this.conventionalMetals.get('zinc').setValue(this.convertBackToLabels(result['zinc']));
            this.conventionalMetals.get('zincDeadline').setValue(result['zincDeadline']);
            this.conventionalMetals.get('zincStatus').setValue(result['zincStatus']);
            this.conventionalMetals.get('zincUnit').setValue(result['zincUnit']);
            this.conventionalMetals.get('arsenic').setValue(this.convertBackToLabels(result['arsenic']));
            this.conventionalMetals.get('arsenicDeadline').setValue(result['arsenicDeadline']);
            this.conventionalMetals.get('arsenicStatus').setValue(result['arsenicStatus']);
            this.conventionalMetals.get('arsenicUnit').setValue(result['arsenicUnit']);
            this.conventionalMetals.get('cadmium').setValue(this.convertBackToLabels(result['cadmium']));
            this.conventionalMetals.get('cadmiumDeadline').setValue(result['cadmiumDeadline']);
            this.conventionalMetals.get('cadmiumStatus').setValue(result['cadmiumStatus']);
            this.conventionalMetals.get('cadmiumUnit').setValue(result['cadmiumUnit']);
            this.conventionalMetals.get('chromiumvi').setValue(this.convertBackToLabels(result['chromiumvi']));
            this.conventionalMetals.get('chromiumviDeadline').setValue(result['chromiumviDeadline']);
            this.conventionalMetals.get('chromiumviStatus').setValue(result['chromiumviStatus']);
            this.conventionalMetals.get('chromiumviUnit').setValue(result['chromiumviUnit']);
            this.conventionalMetals.get('_lead').setValue(this.convertBackToLabels(result['_lead']));
            this.conventionalMetals.get('_leadDeadline').setValue(result['_leadDeadline']);
            this.conventionalMetals.get('_leadStatus').setValue(result['_leadStatus']);
            this.conventionalMetals.get('_leadUnit').setValue(result['_leadUnit']);
            this.conventionalMetals.get('mercury').setValue(this.convertBackToLabels(result['mercury']));
            this.conventionalMetals.get('mercuryDeadline').setValue(result['mercuryDeadline']);
            this.conventionalMetals.get('mercuryStatus').setValue(result['mercuryStatus']);
            this.conventionalMetals.get('mercuryUnit').setValue(result['mercuryUnit']);

            //set zdhc parameters
            this.zdhcMrslParametersTable.data = result['zdhcWastewaterParameters'];

            this.disableAndUpdateFormControls()

            this.loading = false

            //remove delete colume
            this.displayedColumnsZdhcParameters.pop()


        })


    }

    disableAndUpdateFormControls() {


        for (var control in this.basicInformation.controls) {
            this.basicInformation.controls[control].disable()
        }
        for (var control in this.conventionalSumParameters.controls) {
            this.conventionalSumParameters.controls[control].disable()
            if (!(control.includes('Deadline') || control.includes('Status') || control.includes('Unit'))) {
                const parameters = this.sumParameters.filter((data) => data.controlName == control)[0]
                parameters.result = this.conventionalSumParameters.get(control).value

                this.updateLimitsValue(parameters, 'sumParametersTable')
            }

        }
        for (var control in this.conventionalAnions.controls) {
            this.conventionalAnions.controls[control].disable()
            if (!(control.includes('Deadline') || control.includes('Status') || control.includes('Unit'))) {
                const parameters = this.anions.filter((data) => data.controlName == control)[0]
                parameters.result = this.conventionalAnions.get(control).value

                this.updateLimitsValue(parameters, 'conventionalAnionsTable')
            }
        }
        for (var control in this.conventionalMetals.controls) {
            this.conventionalMetals.controls[control].disable()
            if (!(control.includes('Deadline') || control.includes('Status') || control.includes('Unit'))) {
                const parameters = this.metals.filter((data) => data.controlName == control)[0]
                parameters.result = this.conventionalMetals.get(control).value

                this.updateLimitsValue(parameters, 'metalsParametersTable')
            }
        }

        // zdhc parameter form controls are hidden
        this.updateAllParameters()
    }

    addFile(files: FileList) {
        let file: File = files[0];
        let uploadTypes = ['pdf'];
        let nameArray = file.name.split('.');
        let type = nameArray[nameArray.length - 1];
        if (!uploadTypes.includes(type)) {
            alert(this.alertMsgs["FILE_TYPE"]);
            this.clearContent();
        } else {
            this.document.file = file;
        }
    }

    clearContent() {
        var elements = document.getElementsByTagName("input");
        for (var ii = 0; ii < elements.length; ii++) {
            if (elements[ii].type.toLowerCase() == 'file') {
                elements[ii].value = "";
            }
        }
        this.document.file = null;
    }

    cancel() {
        this.dialogRef.close(null);
    }

    hasFile() {
        return this.document.file;
    }

    isFormValid() {
        const basicInfoComplete = this.basicInformation.valid;
        const conventionalSumParametersComplete = this.conventionalSumParameters.valid;
        const conventionalAnionsComplete = this.conventionalAnions.valid;
        const conventionalMetalsComplete = this.conventionalMetals.valid;

        return basicInfoComplete && conventionalSumParametersComplete && conventionalAnionsComplete && conventionalMetalsComplete
    }

    redirectUsersToInvalidStep() {
        const basicInfoComplete = this.basicInformation.valid;
        const conventionalSumParametersComplete = this.conventionalSumParameters.valid;
        const conventionalAnionsComplete = this.conventionalAnions.valid;
        const conventionalMetalsComplete = this.conventionalMetals.valid;

        if (!basicInfoComplete) {
            this.myStepper.selectedIndex = 0;
        } else if (!conventionalSumParametersComplete) {
            this.myStepper.selectedIndex = 1;
        } else if (!conventionalAnionsComplete) {
            this.myStepper.selectedIndex = 2;
        } else if (!conventionalMetalsComplete) {
            this.myStepper.selectedIndex = 3;
        }

    }

    upload() {

        // if the document is view only, dont return anything otherwise the document center will upload
        if (this.viewMode) {
            this.dialogRef.close(null)
        } else {
            if (this.isFormValid()) {
                if (!this.document["file"]) {
                    this.myStepper.selectedIndex = 0;
                    alert(this.alertMsgs["FILE_SELECT"]);
                }
                else {
                    this.document["name"] = this.basicInformation.get('name').value
                    this.document["documentID"] = this.basicInformation.get('documentID').value
                    this.document["expiry"] = this.basicInformation.get('expiry').value ? this.basicInformation.get('expiry').value.toISOString() : ''
                    this.document["testingLab"] = this.basicInformation.get('testingLab').value
                    this.document["testingLabOthers"] = this.basicInformation.get('testingLabOthers').value
                    this.document["samplingLocation"] = this.basicInformation.get('samplingLocation').value
                    this.document["typeOfWaterDischarge"] = this.basicInformation.get('typeOfWaterDischarge').value
                    this.document["samplingDate"] = this.basicInformation.get('samplingDate').value ? this.basicInformation.get('samplingDate').value.toISOString() : ''
                    this.document["testingDate"] = this.basicInformation.get('testingDate').value ? this.basicInformation.get('testingDate').value.toISOString() : ''
                    this.document["issueDate"] = this.basicInformation.get('issueDate').value ? this.basicInformation.get('issueDate').value.toISOString() : ''
                    this.document["passfail"] = this.basicInformation.get('passfail').value
                    this.document["comment"] = this.basicInformation.get('comment').value

                    //loop through the sum parameters control
                    const conventionalSumParametersResults = []
                    for (const param of this.sumParameters) {
                        const result = {
                            "control": param['controlName'],
                            "result": this.conventionalSumParameters.get(param['controlName']).value,
                            "deadline": this.conventionalSumParameters.get(param['controlName'] + "Deadline").value ? this.conventionalSumParameters.get(param['controlName'] + "Deadline").value.toISOString() : '',
                            "status": this.conventionalSumParameters.get(param['controlName'] + "Status").value,
                            "unit": this.conventionalSumParameters.get(param['controlName'] + "Unit").value,
                            "limits": this.resultGrade.indexOf(this.sumParametersTable.data.filter(value => value['controlName'] == param['controlName'])[0]['limitReached'])
                        }
                        conventionalSumParametersResults.push(result)

                    }
                    //loop through the anions control
                    const conventionalAnionsResults = []
                    for (const param of this.anions) {
                        const result = {
                            "control": param['controlName'],
                            "result": this.conventionalAnions.get(param['controlName']).value,
                            "deadline": this.conventionalAnions.get(param['controlName'] + "Deadline").value ? this.conventionalAnions.get(param['controlName'] + "Deadline").value.toISOString() : '',
                            "status": this.conventionalAnions.get(param['controlName'] + "Status").value,
                            "unit": this.conventionalAnions.get(param['controlName'] + "Unit").value,
                            "limits": this.resultGrade.indexOf(this.anionsParametersTable.data.filter(value => value['controlName'] == param['controlName'])[0]['limitReached'])
                        }
                        conventionalAnionsResults.push(result)

                    }


                    //loop through the metals control
                    const conventionalMetalsResults = []
                    for (const param of this.metals) {
                        const result = {
                            "control": param['controlName'],
                            "result": this.conventionalMetals.get(param['controlName']).value,
                            "deadline": this.conventionalMetals.get(param['controlName'] + "Deadline").value ? this.conventionalMetals.get(param['controlName'] + "Deadline").value.toISOString() : '',
                            "status": this.conventionalMetals.get(param['controlName'] + "Status").value,
                            "unit": this.conventionalMetals.get(param['controlName'] + "Unit").value,
                            "limits": this.resultGrade.indexOf(this.metalsParametersTable.data.filter(value => value['controlName'] == param['controlName'])[0]['limitReached'])
                        }
                        conventionalMetalsResults.push(result)

                    }

                    //loop through the metals control
                    const zdhcMrslParametersResult = []
                    for (var entry of this.zdhcMrslParametersTable.data) {
                        const result = {
                            "group": entry["substanceGroup"],
                            "name": entry["substance"],
                            "unit": entry["unit"],
                            "result": entry["result"],
                            "deadline": entry["deadline"] ? entry["deadline"].toISOString() : '',
                            "actionplan": entry["actionplan"],
                            "fail": entry["fail"]

                        }

                        zdhcMrslParametersResult.push(result)
                    }

                    this.document["conventionalSumParameters"] = conventionalSumParametersResults
                    this.document["conventionalAnions"] = conventionalAnionsResults
                    this.document["conventionalMetals"] = conventionalMetalsResults
                    this.document["zdhcMrslParameters"] = zdhcMrslParametersResult

                    this.dialogRef.close(this.document);

                }
            } else {
                this.redirectUsersToInvalidStep()
                alert("Please fill in all required fields!");
            }


        }
    }


    updateIssueDate(event: MatDatepickerInputEvent<Date>) {
        this.mindate = this.basicInformation.get('issueDate').value;
        if (this.basicInformation.get('issueDate').valid) {
            this.basicInformation.get('expiry').enable()
        } else {
            this.basicInformation.get('expiry').setValue('')
            this.basicInformation.get('expiry').disable()
        }


    }

    deleteEntry(index: number) {
        const data = this.zdhcMrslParametersTable.data;
        const itemRemoved = data[index]
        data.splice(index, 1);
        this.zdhcMrslParametersTable.data = data

        //update the available options
        const removedItemIndex = this.excludedOptions.indexOf(itemRemoved.substance)
        this.excludedOptions.splice(removedItemIndex, 1)
        this.zdhcAvailableParametersOptions = this.zdhcParametersOptions.slice().filter(val => !this.excludedOptions.includes(val.name))

        //to update the options viewable in the frontend
        this.zdhcMrslParameters.get('Substance').updateValueAndValidity()
    }

    addParameter() {

        const item = this.zdhcAvailableParametersOptions.filter(val => val.name == this.zdhcMrslParameters.get('Substance').value)[0]
        const limit = item['limit']
        const group = item['group']
        const result = this.convertToNumber(this.zdhcMrslParameters.get('Result').value);
        this.zdhcMrslParameters.get('Result').setValue(this.convertToNumber(result));
        if (this.validateZdhcMrslForm) {
            const zdhcParameter = {
                substanceGroup: group,
                substance: this.zdhcMrslParameters.get('Substance').value,
                result: this.zdhcMrslParameters.get('Result').value,
                unit: this.zdhcMrslParameters.get('SubstanceUnit').value,
                //consider the BD and ND
                aboveLimit: isNaN(parseFloat(result)) ? '' : result > limit ? (result - limit).toPrecision(3).toString() + item['unit'] + " (" + ((result / limit - 1) * 100).toPrecision(4).toString() + "%)" : "",
                fail: result > limit,
                table: item['table'],
                //set default values for the deadline and action  
                deadline: result > limit ? this.mindate : '',
                actionplan: result > limit ? 'NoneTaken' : ''
            }

            this.addToZdhcMrslParametersTable(zdhcParameter);
            this.resetZdhcMrslParameterForm()
        }

        //update the available options
        this.excludedOptions.push(item.name)
        this.zdhcAvailableParametersOptions = this.zdhcParametersOptions.slice().filter(val => !this.excludedOptions.includes(val.name))

        //to update the options viewable in the frontend
        this.zdhcMrslParameters.get('Substance').updateValueAndValidity()
    }

    updateAllParameters() {
        for (const item of this.zdhcMrslParametersTable.data) {
            const index = this.zdhcMrslParametersTable.data.indexOf(item)
            const template = this.zdhcAvailableParametersOptions.filter(val => val.name == item['substance'])[0]
            const limit = template['limit']
            const result = this.convertToNumber(item['result']);


            this.zdhcMrslParametersTable.data[index]['aboveLimit'] = result > limit ? (result - limit).toPrecision(3).toString() + item['unit'] + " (" + ((result / limit - 1) * 100).toPrecision(4).toString() + "%)" : ""

            this.zdhcMrslParametersTable.data[index]['table'] = template['table']

            this.zdhcMrslParametersTable.data[index]['fail'] = result > limit

        }

        //sort data by substance group
        const dataSource = this.zdhcMrslParametersTable.data;
        dataSource.sort((a, b) => a.table.localeCompare(b.table))
        this.zdhcMrslParametersTable.data = dataSource;

    }



    validateZdhcMrslForm() {
        let valid = true

        //check if the subtance name is filled
        if (!this.zdhcMrslParameters.get('Substance')) {
            this.zdhcMrslParameters.get('Substance').setErrors({
                'validLogic': true
            })
            valid = false
        }

        //check if the subtance name is filled
        if (!this.zdhcMrslParameters.get('Result')) {
            this.zdhcMrslParameters.get('Result').setErrors({
                'validLogic': true
            })
            valid = false
        }

        return valid
    }

    addToZdhcMrslParametersTable(zdhcParameter: ZdhcParameters) {
        const dataSource = this.zdhcMrslParametersTable.data;
        dataSource.push(zdhcParameter);
        dataSource.sort((a, b) => a.table.localeCompare(b.table))
        this.zdhcMrslParametersTable.data = dataSource;
    }

    resetZdhcMrslParameterForm() {
        this.zdhcMrslParameters.controls['Substance'].reset()
        this.zdhcMrslParameters.controls['Result'].reset()
        // this.zdhcMrslParameters.controls['Substance_Unit'].reset()

    }

    initDocument() {
        return { issueDate: undefined, expiryDate: undefined, name: "", passFail: undefined, comment: "", category: "", topic: "", masterId: "-1", msdsIdentifier: "none", file: null };
    }

    fetchTranslations() {
        this.translateService.get("DOCUMENT_UPLOAD_COMPONENT.ALERTS").subscribe((response) => {
            this.alertMsgs = response;
        });
    }

    findLimit(control: string, limits: number[], result: any, validity: Boolean, unit: string) {
        let limitReached = "WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FAILED"
        let aboveLimit = (result - limits[2]).toPrecision(3).toString() + unit + " (" + ((result / limits[2] - 1) * 100).toPrecision(4).toString() + "%)"

        //below detection limit and non-detected
        if (isNaN(parseFloat(result))) {
            limitReached = ''
            aboveLimit = ''
        } else {
            if (control != "ph") {
                //remove the aboveLimit value if it is just pass or fail
                //check for validity of form first because user can input string in the form.
                //conisder the case where result=0, which will be a fals
                if (validity && (result || result == 0)) {
                    var index = limits.findIndex(function (res) {
                        return res >= result;
                    });

                    if (index == 0) {
                        limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.ASPIRATIONAL'
                    } else if (index == 1) {
                        limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.PROGRESSIVE'
                    } else if (index == 2) {
                        limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FOUNDATIONAL'
                    }

                    if (unit == "Pass or Fail" || index >= 0) {
                        aboveLimit = ""
                    }

                } else {
                    limitReached = '';
                    aboveLimit = '';
                }

            } else {
                if (result < limits[0]) {
                    limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FAILED'
                    aboveLimit = (limits[0] - result).toPrecision(3).toString()
                } else if (result > limits[1]) {
                    limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FAILED'
                    aboveLimit = (limits[1] - result).toPrecision(3).toString()
                } else {
                    limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.ASPIRATIONAL'
                    aboveLimit = ''
                }
            }

        }



        return [limitReached, aboveLimit];

    }
    checkSumParameters(parameter: conventionalParameters, sourceTable: String) {
        if (sourceTable == 'sumParametersTable') {
            if (['tempDelta', 'temp'].includes(parameter.controlName)) {
                const result = this.convertToNumber(this.conventionalSumParameters.get(parameter.controlName).value);
                if (result == 'BL' || result == 'NA' || result == 'ND') {
                    this.conventionalSumParameters.patchValue({
                        tempDelta: result,
                        temp: result
                    })
                }

            } else if (['color436', 'color525', 'color620'].includes(parameter.controlName)) {

                const result = this.convertToNumber(this.conventionalSumParameters.get(parameter.controlName).value);
                if (result == 'BL' || result == 'NA' || result == 'ND') {
                    this.conventionalSumParameters.patchValue({
                        color436: result,
                        color525: result,
                        color620: result
                    })
                }
            }
        }
    }
    updateLimitsValue(parameter: conventionalParameters, sourceTable: String) {

        if (sourceTable == 'sumParametersTable') {
            const index = this.sumParametersTable.data.findIndex(value => value.name == parameter.name)
            const updatedParameter = this.sumParametersTable.data[index]
            let limitReached = []
            const result = this.convertToNumber(this.conventionalSumParameters.get(parameter.controlName).value);

            //parse the input value to ensure it is a number
            this.conventionalSumParameters.get(parameter.controlName).setValue(result)

            //assign value inside the table  
            this.sumParametersTable.data[index]['result'] = result;

            //temperature and colors must be assessed with other related forms
            if (['tempDelta', 'temp'].includes(parameter.controlName)) {
                this.checkTemperatureCompliance()
            } else if (['color436', 'color525', 'color620'].includes(parameter.controlName)) {
                this.checkColorCompliance()
            } else {
                const validity = this.conventionalSumParameters.get(parameter.controlName).valid || this.viewMode;
                limitReached = this.findLimit(parameter.controlName, parameter.limits, result, validity, parameter.unit)
                updatedParameter.limitReached = limitReached[0]
                updatedParameter.aboveLimit = limitReached[1]
            }
        } else if (sourceTable == 'conventionalAnionsTable') {
            const index = this.anionsParametersTable.data.findIndex(value => value.name == parameter.name)
            const updatedParameter = this.anionsParametersTable.data[index]
            const result = this.convertToNumber(this.conventionalAnions.get(parameter.controlName).value);
            let limitReached = []

            //parse the input value to ensure it is a number
            this.conventionalAnions.get(parameter.controlName).setValue(result)

            const validity = this.conventionalAnions.get(parameter.controlName).valid || this.viewMode;;
            limitReached = this.findLimit(parameter.controlName, parameter.limits, result, validity, parameter.unit)
            updatedParameter.limitReached = limitReached[0]
            updatedParameter.aboveLimit = limitReached[1]

            //assign value inside the table  
            this.anionsParametersTable.data[index]['result'] = result;
        } else if (sourceTable == 'metalsParametersTable') {
            const index = this.metalsParametersTable.data.findIndex(value => value.name == parameter.name)
            const updatedParameter = this.metalsParametersTable.data[index]
            const result = this.convertToNumber(this.conventionalMetals.get(parameter.controlName).value);
            let limitReached = []

            //parse the input value to ensure it is a number
            this.conventionalMetals.get(parameter.controlName).setValue(result)

            const validity = this.conventionalMetals.get(parameter.controlName).valid || this.viewMode;
            limitReached = this.findLimit(parameter.controlName, parameter.limits, result, validity, parameter.unit)
            updatedParameter.limitReached = limitReached[0]
            updatedParameter.aboveLimit = limitReached[1]

            //assign value inside the table  
            this.metalsParametersTable.data[index]['result'] = result;
        }


    }

    checkTemperatureCompliance() {
        const tempDeltaIndex = this.sumParametersTable.data.findIndex(value => value.controlName == 'tempDelta')
        const tempDischargedIndex = this.sumParametersTable.data.findIndex(value => value.controlName == 'temp')

        const tempDeltaValidity = this.conventionalSumParameters.get('tempDelta').valid;
        const tempDischargedValidity = this.conventionalSumParameters.get('temp').valid;

        const tempDeltaUserInput = this.conventionalSumParameters.get('tempDelta').value;
        const tempDischargedUserInput = this.conventionalSumParameters.get('temp').value;

        const tempDeltaLimits = this.sumParametersTable.data[tempDeltaIndex].limits
        const tempDischargedLimits = this.sumParametersTable.data[tempDischargedIndex].limits
        let result = -1 //init

        let limitReached = ''
        if ((tempDeltaValidity && tempDischargedValidity) || this.viewMode) {

            let resultDelta = tempDeltaLimits.findIndex(function (res) {
                return res >= tempDeltaUserInput
            });

            let resultDischarged = tempDischargedLimits.findIndex(function (res) {
                return res >= tempDischargedUserInput
            });

            // negative must be removed because the evaluation is an "or"
            if (resultDelta < 0) {
                resultDelta = 3
            }

            if (resultDischarged < 0) {
                resultDischarged = 3
            }

            result = Math.min(resultDelta, resultDischarged)

        } else if (tempDeltaValidity) {
            result = 4
        } else if (tempDischargedValidity) {
            result = 5
        }

        if (result == 0) {
            limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.ASPIRATIONAL'
        } else if (result == 1) {
            limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.PROGRESSIVE'
        } else if (result == 2) {
            limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FOUNDATIONAL'
        } else if (result == 3) {
            limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FAILED'
        } else if (result == 4) {
            limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.MISSING_PARAMETER_ERRORS.MISSING_TEMPERATURE_WATER'
        } else if (result == 5) {
            limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.MISSING_PARAMETER_ERRORS.MISSING_TEMPERATURE_DELTA'

        }

        //update both fields in the table
        this.sumParametersTable.data[tempDeltaIndex]['limitReached'] = limitReached
        this.sumParametersTable.data[tempDischargedIndex]['limitReached'] = limitReached

    }

    checkColorCompliance() {
        const fourThreeSixIndex = this.sumParametersTable.data.findIndex(value => value.controlName == 'color436')
        const fiveTwoFiveIndex = this.sumParametersTable.data.findIndex(value => value.controlName == 'color525')
        const sixTwoZeroIndex = this.sumParametersTable.data.findIndex(value => value.controlName == 'color620')


        const fourThreeSixUserInput = this.conventionalSumParameters.get('color436').value;
        const fiveTwoFiveUserInput = this.conventionalSumParameters.get('color525').value;
        const sixTwoZeroUserInput = this.conventionalSumParameters.get('color620').value;

        const fourThreeSixLimits = this.sumParametersTable.data[fourThreeSixIndex].limits
        const fiveTwoFiveimits = this.sumParametersTable.data[fiveTwoFiveIndex].limits
        const sixTwoZeroLimits = this.sumParametersTable.data[sixTwoZeroIndex].limits

        let result = -1 //init

        let limitReached = ''

        const isAnyInputNotNull = (fourThreeSixUserInput != null || fiveTwoFiveUserInput != null || sixTwoZeroUserInput != null)
        const areAllInputNotNull = (fourThreeSixUserInput != null && fiveTwoFiveUserInput != null && sixTwoZeroUserInput != null)

        //modify the requirements depending on the value
        if (isAnyInputNotNull && this.othersLabMode) {
            //in case this is on other lab mode and one of the inputs are not null
            this.setRequiredValidator(this.conventionalSumParameters.get('color436'), true)
            this.setRequiredValidator(this.conventionalSumParameters.get('color525'), true)
            this.setRequiredValidator(this.conventionalSumParameters.get('color620'), true)
        } else if (!isAnyInputNotNull && this.othersLabMode) {
            this.setRequiredValidator(this.conventionalSumParameters.get('color436'), false)
            this.setRequiredValidator(this.conventionalSumParameters.get('color525'), false)
            this.setRequiredValidator(this.conventionalSumParameters.get('color620'), false)
        }

        //check for validty after adjustment on the validator condition
        const fourThreeSixValidity = this.conventionalSumParameters.get('color436').valid;
        const fiveTwoFiveValidity = this.conventionalSumParameters.get('color525').valid;
        const sixTwoZeroValidity = this.conventionalSumParameters.get('color620').valid;



        if (((fourThreeSixValidity && fiveTwoFiveValidity && sixTwoZeroValidity && areAllInputNotNull) || this.viewMode)) {
            const fourThreeSixResult = fourThreeSixLimits.findIndex(function (res) {
                return res >= fourThreeSixUserInput
            });

            const fiveTwoFiveResult = fiveTwoFiveimits.findIndex(function (res) {
                return res >= fiveTwoFiveUserInput
            });

            const sixTwoZeroResult = sixTwoZeroLimits.findIndex(function (res) {
                return res >= sixTwoZeroUserInput
            });

            //strict assessment must be done. Get the lowest limit reached
            result = Math.max(fourThreeSixResult, fiveTwoFiveResult, sixTwoZeroResult)
            console.log(result)
            const failures = Math.min(fourThreeSixResult, fiveTwoFiveResult, sixTwoZeroResult) //check for failures

            if (failures < 0) {
                result = 3
            }

        } else {
            result = 4
        }

        if (result == 0) {
            limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.ASPIRATIONAL'
        } else if (result == 1) {
            limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.PROGRESSIVE'
        } else if (result == 2) {
            limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FOUNDATIONAL'
        } else if (result == 3) {
            limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FAILED'
        } else if (result == 4 && !this.viewMode) {
            limitReached = 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.MISSING_PARAMETER_ERRORS.MISSING_COLORS'
        } else if (result == 4 && this.viewMode) {
            limitReached = ''
        }

        //update both fields in the table
        this.sumParametersTable.data[fourThreeSixIndex]['limitReached'] = limitReached
        this.sumParametersTable.data[fiveTwoFiveIndex]['limitReached'] = limitReached
        this.sumParametersTable.data[sixTwoZeroIndex]['limitReached'] = limitReached
    }

    updateLinkedFormControls(parameter: conventionalParameters, sourceTable: String, category: String) {
        //linked form controls are parameters that have multiple values but are assessed as one parameter

        if (sourceTable == 'sumParametersTable') {
            const referenceValue = this.conventionalSumParameters.get(parameter.controlName + category).value
            //temperature and colors must be assessed with other related forms
            if (['tempDeltaStatus', 'tempStatus'].includes(parameter.controlName + category)) {
                this.conventionalSumParameters.get('tempDeltaStatus').setValue(referenceValue)
                this.conventionalSumParameters.get('tempStatus').setValue(referenceValue)
            } else if (['tempDeltaDeadline', 'tempDeadline'].includes(parameter.controlName + category)) {
                this.conventionalSumParameters.get('tempDeltaDeadline').setValue(referenceValue)
                this.conventionalSumParameters.get('tempDeadline').setValue(referenceValue)

            } else if (['color436Status', 'color525Status', 'color620Status'].includes(parameter.controlName + category)) {
                this.conventionalSumParameters.get('color436Status').setValue(referenceValue)
                this.conventionalSumParameters.get('color525Status').setValue(referenceValue)
                this.conventionalSumParameters.get('color620Status').setValue(referenceValue)
            } else if (['color436Deadline', 'color525Deadline', 'color620Deadline'].includes(parameter.controlName + category)) {
                this.conventionalSumParameters.get('color436Deadline').setValue(referenceValue)
                this.conventionalSumParameters.get('color525Deadline').setValue(referenceValue)
                this.conventionalSumParameters.get('color620Deadline').setValue(referenceValue)
            }
        }
    }

    isFailed(limitReached: String) {
        return limitReached == 'WASTE_WATER_UPLOAD_COMPONENT.TEST_RESULTS.LIMITES_REACHED.FAILED'
    }

    convertToNumber(input: any) {
        //this function aims to fix input values that are number in nature but does not make sense-- ie 1.1.1 
        //this function also ensure that BD and ND
        let convertedValue


        if (isNaN(parseFloat(input)) && input != null) {
            console.log(input)
            switch (input.toUpperCase()) {
                case "ND": {
                    convertedValue = "ND";
                    break;
                }
                case "BL": {
                    convertedValue = "BL";
                    break;
                }
                case "NA": {
                    convertedValue = "NA";
                    break;
                }
                default: {
                    //override random texts
                    convertedValue = ''
                }
            }
        } else if (input == null) {
            convertedValue = null
        } else {
            convertedValue = Math.abs(parseFloat(input));
        }

        return convertedValue
    }

    convertBackToLabels(input: Number) {
        let convertedValue;

        switch (input) {
            case -3: {
                convertedValue = 'BL';
                break;
            }
            case -4: {
                convertedValue = 'ND';
                break;
            }
            case -5: {
                convertedValue = 'NA';
                break;
            }
            default: {
                //override random texts
                convertedValue = input
            }
        }

        return convertedValue

    }
    convertColorBackToLabels(input: any) {

        let convertedValue;
        if (!isNaN(input)) {

            switch (input) {
                case -3: {
                    convertedValue = 'BL';
                    break;
                }
                case -4: {
                    convertedValue = 'ND';
                    break;
                }
                case -5: {
                    convertedValue = 'NA';
                    break;
                }
                default: {
                    //override random texts
                    convertedValue = input
                }
            }
        }
        return convertedValue

    }

    private zdhcParameterFilter(value: string): any[] {
        const filterValue = value.toLowerCase();
        return this.zdhcAvailableParametersOptions.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    checkValidZdhcParameters() {
        const substance = this.zdhcMrslParameters.get('Substance').value
        const validOptionSelected = this.zdhcAvailableParametersOptions.filter(val => val.name == substance).length == 1
        const valueInputed = parseFloat(this.zdhcMrslParameters.get('Result').value)
        return validOptionSelected && (valueInputed || valueInputed == 0)

    }

    downloadDocument() {
        const id = this.data['id']

        this.globalsService.showSpinner();
        this.http.get(this.globalsService.baseHref + "/getFacilityDocument/" + id, { responseType: 'blob' }).subscribe(response => {
            // console.log(response);
            this.globalsService.hideSpinner();
            let blob = new Blob([response], { type: 'application/pdf' });

            var downloadURL = window.URL.createObjectURL(blob);
            window.open(downloadURL);
        },
            (err) => {
                console.log(err);
                this.globalsService.hideSpinner();
                alert(this.alertMsgs["DOWNLOAD_ERROR"]);
            });
    }

    downloadCAPTemplate() {

        this.http.get(this.globalsService.baseHref + "/getWasteWaterCap", { responseType: 'blob' }).subscribe(response => {
            // console.log(response);
            let blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            var downloadURL = window.URL.createObjectURL(blob);
            window.open(downloadURL);
        },
            (err) => {
                console.log(err);
                alert(this.alertMsgs["DOWNLOAD_ERROR"]);
            });
    }

    alignValidators() {
        const labChosen = this.basicInformation.get('testingLab').value
        console.log(labChosen);
        //if the lab chose is a non-zdhc approved lab
        if (labChosen == 90) {
            this.othersLabMode = true;
            this.setRequiredValidator(this.basicInformation.get('testingLabOthers'), true)
            this.basicInformation.get('testingLabOthers').enable()

            for (const control in this.conventionalSumParameters.controls) {
                if (!this.isRequired(control)) {
                    this.setRequiredValidator(this.conventionalSumParameters.get(control), false)
                }
            }

            for (const control in this.conventionalAnions.controls) {
                if (!this.isRequired(control)) {
                    this.setRequiredValidator(this.conventionalAnions.get(control), false)
                }
            }

            for (const control in this.conventionalMetals.controls) {
                if (!this.isRequired(control)) {
                    this.setRequiredValidator(this.conventionalMetals.get(control), false)
                }
            }

        } else {
            this.othersLabMode = false;
            this.setRequiredValidator(this.basicInformation.get('testingLabOthers'), false)
            this.basicInformation.get('testingLabOthers').setValue(null);
            this.basicInformation.get('testingLabOthers').disable()

            for (const control in this.conventionalSumParameters.controls) {
                if (!this.isRequired(control)) {
                    this.setRequiredValidator(this.conventionalSumParameters.get(control), true)
                }
            }


            for (const control in this.conventionalAnions.controls) {
                if (!this.isRequired(control)) {
                    this.setRequiredValidator(this.conventionalAnions.get(control), true)
                }
            }

            for (const control in this.conventionalMetals.controls) {
                if (!this.isRequired(control)) {
                    this.setRequiredValidator(this.conventionalMetals.get(control), true)
                }
            }
        }


    }


    setRequiredValidator(control: AbstractControl, on: Boolean) {
        if (on) {
            control.setValidators([Validators.required]);
            control.updateValueAndValidity();
        } else {
            control.clearValidators();
            control.updateValueAndValidity();
        }
    }

    isRequired(formControlName: string) {

        //persistent foam does not have a unit
        const inTheList = this.otherLabRequiredFields.filter((form) => form.includes(formControlName)).length > 0
        return inTheList
    }


}
