import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthenticationService } from 'app/globals-services/authentication.service';
import { GlobalsService } from 'app/globals-services/globals.service';


@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  redirectPath = '';
  public passwordResetForm: FormGroup;
  passwordMissmatch: boolean;
  showSuccessMessage: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private authenticationService: AuthenticationService,
              private globalsService: GlobalsService) {

    this.passwordResetForm = this.createForm();
    this.passwordMissmatch = false;
    this.showSuccessMessage = false;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      this.redirectPath = queryParams['redirect_path'] || '';
    });
    
  }

  private createForm(): FormGroup {
    return this.fb.group({
      'newPassword': ['', [Validators.required, Validators.minLength(10)]],
//      'newPassword': ['', [Validators.required, Validators.minLength(2)]],
      'newPasswordConfirmation': ['', Validators.required]
    });    
  }

  onSubmit(event) {
    this.authenticationService.resetPassword( this.passwordResetForm.value.newPassword.trim() ).then(
      () => {
        this.showSuccessMessage = true;
        setTimeout(
          () => {
            this.showSuccessMessage = false;
            this.globalsService.isLoggedIn = true;
            this.router.navigateByUrl(this.redirectPath, {replaceUrl: true});
            this.passwordResetForm.reset();
            event.preventDefault();
          }, 1000
        );
      },
      (err) => {
        console.log(err);
        alert("Could not reset password, please contact our support!");
      }
    )
  }

  checkPasswordMissmatch() {
    let newPassword: string = this.newPassword.value;
    let newPasswordConfirmation: string = this.newPasswordConfirmation.value;
    if(!(newPassword === newPasswordConfirmation)) {
      this.passwordMissmatch = true;
    } else {
      this.passwordMissmatch = false;
    }
  }
  
  get newPassword() {return this.passwordResetForm.get('newPassword')};
  
  get newPasswordConfirmation() {return this.passwordResetForm.get('newPasswordConfirmation')};
}
