<div class="flex flex-col flex-auto min-w-0">
    <!-- Main -->
    <div class="flex-auto p-6 sm:p-10">
        <div class="flex items-center justify-between w-full mb-4">
            <h2 class="text-3xl font-semibold tracking-tight leading-8">
                {{ "WET_PROCESSING_UNIT_LIST_COMPONENT.HEADER" | translate }}
            </h2>
        </div>
        <div class="content">

            <mat-tab-group (selectedTabChange)="onTabChanged($event.index)">
                <mat-tab label="{{
                        'WET_PROCESSING_UNIT_LIST_COMPONENT.MAT_TABS.TAB_1'
                            | translate
                    }}">
                    <ng-template matTabContent>
                        <div>
                            <mat-form-field appearance="outline" i18n="Document Center Search Field">
                                <input class="filter" matInput i18n-placeholder placeholder="{{
                                        'WET_PROCESSING_UNIT_LIST_COMPONENT.SEARCH_SUPPLIER'
                                            | translate
                                    }}" (keyup)="
                                        applyFilterFormula($event.target.value)
                                    " (focus)="
                                        setupFilter('manufacturer', 'name')
                                    " />
                                <mat-hint>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.SEARCH_SUPPLIER_HINT"
                                    | translate
                                    }}
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <br />

                        <table *ngIf="supplierCount > 0" mat-table [dataSource]="dataSource" matSort
                            class="table table-striped tablefixed" multiTemplateDataRows>
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.SUPPLIER_ID"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="
                                        let supplier;
                                        let in = dataIndex
                                    ">
                                    {{ in + 1 }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.NAME"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">

                                    

                                        <a routerLink="/wet-processing-units/{{
                                            supplier['id']
                                        }}">
                                            <div class="flex flex-row items-center">
                                               
                                                 
                                                {{ supplier["name"] }}

                                                <div class="rounded-full min-w-2 h-2 ml-2" *ngIf="supplier['aldiConnected']"
                                                style="background-color: rgb(0, 153, 255) ;">
                                            </div>
                                            </div>

                                        </a>

                                        <!-- <img *ngIf="supplier['aldiConnected']" class="dark:hidden w-6 ml-2" src="assets/icons/aldi.png"
                                            alt="Logo image"> -->
                                   

                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ids">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.ID"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <div>
                                        <span *ngIf="
                                                supplier.ffc != null &&
                                                supplier.ffc != ''
                                            ">
                                            <strong>{{
                                                "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.FFC"
                                                | translate
                                                }}</strong>&nbsp;:{{ supplier.ffc }}&nbsp;
                                        </span>
                                    </div>

                                    <div>
                                        <span *ngIf="
                                                supplier.higg != null &&
                                                supplier.higg != ''
                                            ">
                                            <strong>{{
                                                "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.HIGG"
                                                | translate
                                                }} </strong>&nbsp;:{{ supplier.higg }}
                                        </span>
                                    </div>

                                    <span *ngIf="
                                            supplier.brand != null &&
                                            supplier.brand != ''
                                        ">
                                        <strong>{{
                                            "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.BRAND"
                                            | translate
                                            }}</strong>&nbsp;:{{ supplier.brand }}&nbsp;
                                    </span>
                                    <span *ngIf="
                                            supplier.oar != null &&
                                            supplier.oar != ''
                                        ">
                                        <strong>{{
                                            "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.OAR"
                                            | translate
                                            }}</strong>&nbsp;:{{ supplier.oar }}&nbsp;
                                    </span>

                                    <br>
                                    <span *ngIf="
                                    supplier.brandIdValue!= null &&
                                    supplier.brandIdValue != ''
                                ">
                                        <strong>{{
                                            "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.MILL_ID"
                                            | translate
                                            }}</strong>&nbsp;:{{ supplier.brandIdValue }}&nbsp;
                                        <br>
                                    </span>


                                    <span *ngIf="supplier.zdhcAID != null && supplier.zdhcAID != '' ">
                                        <strong>{{
                                            "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.ZDHC_AID" | translate
                                            }}</strong>&nbsp;:{{ supplier.zdhcAID }}
                                        &nbsp;

                                    </span>

                                </td>

                            </ng-container>
                            <ng-container matColumnDef="documents">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.DOCUMENTS"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <a routerLink="/wet-processing-units/{{
                                            supplier['id']
                                        }}">
                                        {{
                                        "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.VIEW_DOCUMENT"
                                        | translate
                                        }}
                                    </a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="inventory">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.INVENTORIES"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <a routerLink="/supplier-inventories" [queryParams]="{
                                            supplierId: supplier['id']
                                        }">
                                        <mat-icon>format_list_numbered</mat-icon>
                                    </a>
                                </td>
                            </ng-container>



                            <ng-container matColumnDef="env_data">
                                <th mat-header-cell *matHeaderCellDef>
                                    Env. Data
                                </th>
                                <td *matCellDef="let supplier">
                                    <a matTooltip="Energy Data" routerLink="/environmental-data/energy/{{
                                            supplier['id']
                                        }}">
                                        <mat-icon>dvr</mat-icon>
                                    </a>

                                    &nbsp; &nbsp;
                                    <a matTooltip="Health Data" routerLink="/environmental-data/health/{{
                                        supplier['id']
                                    }}">
                                        <mat-icon>dvr</mat-icon>
                                    </a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="chemicals">
                                <th mat-header-cell *matHeaderCellDef>
                                    Chemicals
                                </th>
                                <td *matCellDef="let supplier">
                                    <a matTooltip="Chemical list" routerLink="/my-chemstore/list/{{
                                            supplier['id']
                                        }}">
                                        <mat-icon>dvr</mat-icon>
                                    </a>

                                </td>
                            </ng-container>


                            <ng-container matColumnDef="cap">
                                <th mat-header-cell *matHeaderCellDef>
                                    CAP
                                </th>
                                <td *matCellDef="let supplier">
                                    <a matTooltip="CAP Data" routerLink="../my-chemstore/cap/{{
                                            supplier['id']
                                        }}">
                                        <mat-icon>dvr</mat-icon>
                                    </a>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="lastScanDate">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.LAST_SCAN_DATE"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <a (click)="showInventory(supplier)">
                                        {{
                                        supplier.lastScanDate
                                        | date: "mediumDate"
                                        }}
                                    </a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="inCheck">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.IN_CHECK"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <a *ngIf="supplier.inCheckAvailable" routerLink="/incheck-certificate"
                                        [queryParams]="{
                                            supplierId: supplier['id'],
                                            name: supplier['name']
                                        }">
                                        <mat-icon>insert_drive_file</mat-icon>
                                    </a>
                                    <span *ngIf="!supplier.inCheckAvailable">&nbsp;</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="other">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.OTHER"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <a *ngIf="supplier.otherReportAvailable" routerLink="/other-certificate/list"
                                        [queryParams]="{
                                            supplierId: supplier['id'],
                                            name: supplier['name']
                                        }">
                                        <mat-icon>insert_drive_file</mat-icon>
                                    </a>
                                    <span *ngIf="!supplier.otherReportAvailable"></span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="higgDoc">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.HIGG_DOC"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <a *ngIf="supplier.higgReportAvailable" routerLink="/higg-certificate"
                                        [queryParams]="{
                                            supplierId: supplier['id'],
                                            name: supplier['name']
                                        }">
                                        <mat-icon>insert_drive_file</mat-icon>
                                    </a>
                                    <span *ngIf="!supplier.higgReportAvailable">&nbsp;</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="wasteWater">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.WASTE_WATER"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <a routerLink="/wet-processing-units/{{
                                            supplier['id']
                                        }}">
                                        <mat-icon>dvr</mat-icon>
                                    </a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="inCheckInternal">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.IN_CHECK"
                                    | translate
                                    }}
                                    <br />
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.IN_CHECK_INTERNAL"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <!-- <a
                                        *ngIf="
                                            supplier.internalInCheckAvailable
                                        "
                                        routerLink="/wet-processing-units/{{
                                            supplier['id']
                                        }}"
                                    >
                                        <mat-icon>insert_drive_file</mat-icon>
                                    </a> -->


                                    <a *ngIf="
                                        supplier.internalInCheckAvailable
                                    " routerLink="/inventory/incheck/{{
                                        supplier['id']
                                    }}">
                                        <mat-icon>insert_drive_file</mat-icon>
                                    </a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="inCheckExternal">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.IN_CHECK"
                                    | translate
                                    }}
                                    <br />
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.IN_CHECK_EXTERNAL"
                                    | translate
                                    }}
                                </th>

                                <td *matCellDef="let supplier">
                                    <a *ngIf="supplier.inCheckAvailable" routerLink="/incheck-certificate"
                                        [queryParams]="{
                                            supplierId: supplier['id'],
                                            name: supplier['name']
                                        }">
                                        <mat-icon>insert_drive_file</mat-icon>
                                    </a>
                                    <span *ngIf="!supplier.inCheckAvailable">&nbsp;</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="wasteWaterResult">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.WASTE_WATER_TEST_RESULT"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <!-- <a *ngIf="
                                            supplier.testWaterResultAvailable
                                        " routerLink="/wet-processing-units/{{
                                            supplier['id']
                                        }}" [queryParams]="{mainTabIndex: 0,subTabIndex:1}">
                                        <mat-icon>insert_drive_file</mat-icon>
                                    </a> -->

                                    <a *ngIf="
                                            supplier.testWaterResultAvailable && !supplier.wasteWaterResultHideForKontoor
                                        " routerLink="/environmental-data/water/{{
                                            supplier['id']
                                        }}">
                                        <mat-icon>insert_drive_file</mat-icon>
                                    </a>

                                    <span *ngIf="supplier.wasteWaterResultHideForKontoor">NA</span>
                                </td>
                            </ng-container>

                            <!--
                    <ng-container matColumnDef="tags">

                        <! -- <th mat-header-cell *matHeaderCellDef>{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.WASTE_WATER' | translate}}</th>
 -- >

                      <th mat-header-cell *matHeaderCellDef>Tags</th>

                        <td *matCellDef="let supplier;">

                            <a (click) = "openDialog()">
                                Tag
                            </a>

                        </td>
                    </ng-container>

                    -->

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        </table>
                        <!-- <table class="table table-striped tablefixed">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.Name' | translate }}</th>
                            <th>{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.ID' | translate }}</th>
                            <th>{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.Documents' | translate }}</th>
                            <th>{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.Inventories' | translate }}</th>


                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let supplier of suppliers;let i=index">
                            <td>{{i+1}}</td>
                            <td> <a routerLink="/wet-processing-units/{{supplier['id']}}">{{supplier['name']}}</a>
                            </td>
                            <td>
                                <span
                                    *ngIf="supplier.ffc !=null && supplier.ffc!=''">{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.FFC'
                                    | translate }}
                                    &nbsp;:{{supplier.ffc}}</span>&nbsp;
                                <span
                                    *ngIf="supplier.higg !=null && supplier.higg!=''">{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.Higg'
                                    | translate }} &nbsp;:
                                    {{supplier.higg}}</span>&nbsp;
                                <span
                                    *ngIf="supplier.brand !=null && supplier.brand!=''">{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.Brand'
                                    | translate }}&nbsp;:{{supplier.brand}}</span>&nbsp;
                                <span
                                    *ngIf="supplier.oar !=null && supplier.oar!=''">{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.OAR'
                                    | translate }}
                                    &nbsp;:{{supplier.oar}}</span>&nbsp;
                            </td>
                            <td>
                                <a routerLink="/wet-processing-units/{{supplier['id']}}">
                                    {{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.View_Document'
                                    | translate }}
                                </a>
                            </td>

                            <td>
                                <a routerLink="/supplier-inventories" [queryParams]="{supplierId:supplier['id']}">
                                    {{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.INVENTORY_REPORT'
                                    | translate }}
                                </a>

                            </td>

                        </tr>
                    </tbody>
                </table> -->
                        <mat-paginator #paginator pageSize="10"></mat-paginator>
                        <span *ngIf="supplierCount == 0">
                            {{
                            "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.EMPTY_LIST"
                            | translate
                            }}</span>
                    </ng-template>
                </mat-tab>
                <mat-tab label="{{
                        'WET_PROCESSING_UNIT_LIST_COMPONENT.MAT_TABS.TAB_2'
                            | translate
                    }}">
                    <ng-template matTabContent>
                        <div>
                            <mat-form-field appearance="outline" i18n="Document Center Search Field">
                                <input class="filter" matInput i18n-placeholder placeholder="{{
                                        'WET_PROCESSING_UNIT_LIST_COMPONENT.SEARCH_SUPPLIER'
                                            | translate
                                    }}" (keyup)="
                                        applyFilterFormula($event.target.value)
                                    " (focus)="
                                        setupFilter('manufacturer', 'name')
                                    " />
                                <mat-hint>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.SEARCH_SUPPLIER_HINT"
                                    | translate
                                    }}
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <br />
                        <table *ngIf="sharedSuppliersCount > 0" mat-table [dataSource]="sharedSuppliers" matSort
                            class="table table-striped tablefixed" multiTemplateDataRows>
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.SUPPLIER_ID"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="
                                        let supplier;
                                        let i = dataIndex
                                    ">
                                    {{ i + 1 }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.NAME"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <a routerLink="/wet-processing-units/{{
                                            supplier['id']
                                        }}">{{ supplier["name"] }}</a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="ids">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.ID"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <span *ngIf="
                                            supplier.ffc != null &&
                                            supplier.ffc != ''
                                        ">
                                        <strong>{{
                                            "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.FFC"
                                            | translate
                                            }}</strong>&nbsp;:{{ supplier.ffc }}&nbsp;
                                    </span>
                                    <span *ngIf="
                                            supplier.higg != null &&
                                            supplier.higg != ''
                                        ">
                                        <strong>{{
                                            "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.HIGG"
                                            | translate
                                            }} </strong>&nbsp;:{{ supplier.higg }}
                                    </span>
                                    <br />
                                    <span *ngIf="
                                            supplier.brand != null &&
                                            supplier.brand != ''
                                        ">
                                        <strong>{{
                                            "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.BRAND"
                                            | translate
                                            }}</strong>&nbsp;:{{ supplier.brand }}&nbsp;
                                    </span>
                                    <span *ngIf="
                                            supplier.oar != null &&
                                            supplier.oar != ''
                                        ">
                                        <strong>{{
                                            "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.OAR"
                                            | translate
                                            }}</strong>&nbsp;:{{ supplier.oar }}&nbsp;
                                    </span>
                                    <br />
                                    <span *ngIf="
                                            supplier.vendorNumber != null &&
                                            supplier.vendorNumber != ''
                                        ">
                                        <strong>{{
                                            "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.VENDOR_NUMBER"
                                            | translate
                                            }}</strong>&nbsp;:{{
                                        supplier.vendorNumber
                                        }}&nbsp;
                                    </span>
                                    <span *ngIf="
                                            supplier.millID != null &&
                                            supplier.millID != ''
                                        ">
                                        <strong>{{
                                            "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.WALMART_MILL_ID"
                                            | translate
                                            }}</strong>&nbsp;:{{ supplier.millID }}&nbsp;
                                    </span>
                                    <br />

                                    <span *ngIf="supplier.zdhcAID != null && supplier.zdhcAID != '' ">
                                        <strong>{{
                                            "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.ZDHC_AID" | translate
                                            }}</strong>&nbsp;:{{ supplier.zdhcAID }}
                                        &nbsp;
                                    </span>

                                </td>
                            </ng-container>
                            <ng-container matColumnDef="documents">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.Documents"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <a routerLink="/wet-processing-units/{{
                                            supplier['id']
                                        }}">
                                        {{
                                        "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.VIEW_DOCUMENT"
                                        | translate
                                        }}
                                    </a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="inventory">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.INVENTORIES"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <a routerLink="/supplier-inventories" [queryParams]="{
                                            supplierId: supplier['id']
                                        }">
                                        <mat-icon>format_list_numbered</mat-icon>
                                    </a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="lastScanDate">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.LAST_SCAN_DATE"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <a (click)="showInventory(supplier)">
                                        {{
                                        supplier.lastScanDate
                                        | date: "mediumDate"
                                        }}
                                    </a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="inCheck">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.IN_CHECK"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <a *ngIf="supplier.inCheckAvailable" routerLink="/incheck-certificate"
                                        [queryParams]="{
                                            supplierId: supplier['id'],
                                            name: supplier['name']
                                        }">
                                        <mat-icon>insert_drive_file</mat-icon>
                                    </a>
                                    <span *ngIf="!supplier.inCheckAvailable">&nbsp;</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="other">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.OTHER"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <a *ngIf="supplier.otherReportAvailable" routerLink="/other-certificate/list"
                                        [queryParams]="{
                                            supplierId: supplier['id'],
                                            name: supplier['name']
                                        }">
                                        <mat-icon>insert_drive_file</mat-icon>
                                    </a>
                                    <span *ngIf="!supplier.otherReportAvailable"></span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="higgDoc">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.HIGG_DOC"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <a *ngIf="supplier.higgReportAvailable" routerLink="/higg-certificate"
                                        [queryParams]="{
                                            supplierId: supplier['id'],
                                            name: supplier['name']
                                        }">
                                        <mat-icon>insert_drive_file</mat-icon>
                                    </a>
                                    <span *ngIf="!supplier.higgReportAvailable">&nbsp;</span>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="wasteWater">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{
                                    "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.WASTE_WATER"
                                    | translate
                                    }}
                                </th>
                                <td *matCellDef="let supplier">
                                    <a routerLink="/wet-processing-units/{{
                                            supplier['id']
                                        }}">
                                        <mat-icon>dvr</mat-icon>
                                    </a>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="
                                    sharedSuppliersDisplayedColumns
                                "></tr>
                            <tr mat-row *matRowDef="
                                    let row;
                                    columns: sharedSuppliersDisplayedColumns;
                                    let i = index
                                " class="element-row"></tr>
                        </table>
                        <mat-paginator #paginator pageSize="10"></mat-paginator>
                        <!-- <table class="table table-striped tablefixed">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.Name' | translate }}</th>
                            <th>{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.ID' | translate }}</th>
                            <th>{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.Documents' | translate }}</th>
                            <th>{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_HEADER.Inventories' | translate }}</th>

                        </tr>
                    </thead>

                    <tr *ngFor="let supplier of sharedSuppliers;let i=index">
                        <td>{{i+1}}</td>
                        <td>{{supplier['name']}}</td>
                        <td>
                            <span *ngIf="supplier.ffc !=null && supplier.ffc!=''">{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.FFC'
                                | translate }}
                                &nbsp;:{{supplier.ffc}}</span>&nbsp;
                            <span *ngIf="supplier.higg !=null && supplier.higg!=''">{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.Higg'
                                | translate }} &nbsp;:
                                {{supplier.higg}}</span>&nbsp;
                            <span
                                *ngIf="supplier.brand !=null && supplier.brand!=''">{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.Brand'
                                | translate }}&nbsp;:{{supplier.brand}}</span>&nbsp;
                            <span *ngIf="supplier.oar !=null && supplier.oar!=''">{{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.OAR'
                                | translate }}
                                &nbsp;:{{supplier.oar}}</span>&nbsp;
                        </td>
                        <td>
                            <a routerLink="/wet-processing-units/{{supplier['id']}}">
                                {{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.View_Document'
                                | translate }}
                            </a>
                        </td>

                        <td>
                            <a routerLink="/supplier-inventories" [queryParams]="{supplierId:supplier['id']}">
                                {{'WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.INVENTORY_REPORT'
                                | translate }}
                            </a>

                        </td>
                    </tr>
                </table> -->
                        <span *ngIf="sharedSuppliersCount == 0">
                            {{
                            "WET_PROCESSING_UNIT_LIST_COMPONENT.TABLE_DATA.EMPTY_LIST"
                            | translate
                            }}</span>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
            <ngx-loading [show]="isLoading"></ngx-loading>
        </div>
    </div>
</div>