export abstract class OrganizationType {

    static readonly BRAND = 1;
    static readonly SUPPLIER = 2;
    static readonly BHIVE_ADMIN = 3;
    static readonly IMPORTER = 5;
    static readonly KONTOOR =6;



}