import { Component, OnInit, SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalsService } from '../globals-services/globals.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../globals-services/authentication.service';
import {
    DomSanitizer,
    SafeResourceUrl,
    SafeUrl,
} from '@angular/platform-browser';

@Component({
    selector: 'app-wet-processing-unit',
    templateUrl: './wet-processing-unit.component.html',
    styleUrls: ['./wet-processing-unit.component.css'],
})
export class WetProcessingUnitComponent implements OnInit {
    factoryProfile = {};
    //uploadedDocuments = [];
    factoryCoordinatesJSON = {};
    apiKey = 'AIzaSyDeAahsUv2HEAcxxtPkWa5aheZWDO9Q0aE';
    addressURI: string;
    mapsURI: string;
    brandMode = false;
    dataSensitivity: number = 1;

    businessLicenseData: any;
    factoryActivites:any=null ;
    constructor(
        private http: HttpClient,
        private globalsService: GlobalsService,
        private activatedRoute: ActivatedRoute,
        //  private authenticationService: AuthenticationService,
        private sanitizer: DomSanitizer,
        private router: Router
    ) {}
 
    ngOnInit() {
        //this.brandMode = !(this.authenticationService.authenticationState.jwtAuthenticationToken.roles.indexOf('WET_PROCESSING_UNIT') >= 0)
        this.brandMode =
            this.globalsService.profile['organization_type'] == 1 ||
            this.globalsService.profile['organization_type'] == 5;
            this.factoryActivites=      this.globalsService.profile['factoryActivities'];
        this.activatedRoute.params.subscribe((params) => {
            let id = params.id;
            if (id == null || id == undefined || id === 'undefined') {
                id = this.globalsService.profile['id'];
            }
            this.onInitMethodCall(id);
        });
        //    alert(this.factoryProfile['companyAddress']);

        /*
         */
    }

    onInitMethodCall(id) {
        this.getMillBusinessLicense(id);
        this.http
            .get(this.globalsService.baseHref + '/getProfile/' + id)
            .subscribe(
                (profile) => {
                    this.factoryProfile = profile;
                    this.dataSensitivity =
                        this.factoryProfile['dataSensitivity'];
                    console.log(this.factoryProfile['dataSensitivity']);
                    console.log(this.dataSensitivity);
                    this.factoryProfile['waterSource'] = this.factoryProfile[
                        'waterSource'
                    ]
                        .toString()
                        .split(',')
                        .join(', ');
                    this.factoryProfile['energySource'] = this.factoryProfile[
                        'energySource'
                    ]
                        .toString()
                        .split(',')
                        .join(', ');
                    this.factoryProfile['wasteWaterTreatment'] =
                        this.factoryProfile['wasteWaterTreatment']
                            .toString()
                            .split(',')
                            .join(', ');

                    if (
                        this.factoryProfile['energySource']
                            .toString()
                            .substr(0, 1) === ','
                    ) {
                        this.factoryProfile['energySource'] =
                            this.factoryProfile['energySource']
                                .toString()
                                .substr(2);
                    }

                    if (
                        this.factoryProfile['waterSource']
                            .toString()
                            .substr(0, 1) === ','
                    ) {
                        this.factoryProfile['waterSource'] =
                            this.factoryProfile['waterSource']
                                .toString()
                                .substr(2);
                    }

                    if (
                        this.factoryProfile['wasteWaterTreatment']
                            .toString()
                            .substr(0, 1) === ','
                    ) {
                        this.factoryProfile['wasteWaterTreatment'] =
                            this.factoryProfile['wasteWaterTreatment']
                                .toString()
                                .substr(2);
                    }

                 //   this.getDocuments(id);

                    console.log(
                        'Factory Address: ' +
                            this.factoryProfile['factoryAddress']
                    );

                    if (this.factoryProfile['factoryAddress'] != '') {
                        this.addressURI = encodeURI(
                            this.factoryProfile['factoryAddress']
                        );
                    } else {
                        this.addressURI = encodeURI(
                            this.factoryProfile['companyAddress']
                        );
                    }

                    let coordinateURI =
                        'https://maps.googleapis.com/maps/api/geocode/json?address=' +
                        this.addressURI +
                        '&key=' +
                        this.apiKey;
                    //        alert(coordinateURI);

                    console.log(this.factoryProfile['AccountType']);
                    console.log(this.factoryProfile['BHiveSubscription']);
                    console.log(
                        this.factoryProfile['ZDHCInCheckSubscriptionStart']
                    );

                    this.http
                        .get(coordinateURI)
                        .subscribe((coordinatesJSON) => {
                            //          console.log(coordinatesJSON);
                            this.factoryCoordinatesJSON =
                                coordinatesJSON['results'][0].geometry.location;

                            console.log(this.factoryCoordinatesJSON);

                            this.mapsURI =
                                'https://www.google.com/maps/embed/v1/view?key=' +
                                this.apiKey +
                                '&center=' +
                                this.factoryCoordinatesJSON['lat'] +
                                ',' +
                                this.factoryCoordinatesJSON['lng'] +
                                '&zoom=14';
                        });
                },
                (error) => {
                    if (
                        error['status'] == '404' ||
                        error['status'] == '500' ||
                        error['status'] == '401' ||
                        error['status'] == '403'
                    ) {
                        alert('Unauthorized access.');
                    }
                }
            );
    }

    makeSafeMapsURI() {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.mapsURI);
    }

    //Commented as it deprecated at server side
    // getDocuments(id: number) {
    //     this.http
    //         .get<any[]>(this.globalsService.baseHref + '/getAllDocuments/' + id)
    //         .subscribe((documents) => {
    //             console.log(documents);
    //             this.uploadedDocuments = documents;
    //         });
    // }

    get isWPU(): boolean {
        //return this.authenticationService.authenticationState.jwtAuthenticationToken.roles.indexOf("WET_PROCESSING_UNIT") >= 0;

        return this.globalsService.profile['organization_type'] == 2;
    }
    back() {
        this.router.navigateByUrl('/wpu-list');
    }

    downloadBusinessLicense() {
        this.http
            .get(
                this.globalsService.baseHref +
                    '/getDocument/' +
                    this.businessLicenseData.id,
                { responseType: 'blob' }
            )
            .subscribe(
                (response) => {
                    let blob = new Blob([response], {
                        type: 'application/pdf',
                    });

                    var downloadURL = window.URL.createObjectURL(blob);
                    window.open(
                        downloadURL,
                        'Document',
                        'toolbar=0,location=0,menubar=0'
                    );
                },
                (err) => {
                    console.log(err);
                    alert('Error: Could not download document!');
                }
            );
    }

    getMillBusinessLicense(supplierId) {
        let formData = {
            creatorID: supplierId,
        };
        this.http
            .post<any>(
                this.globalsService.baseHref + '/getBusinessLicenseDocument',
                formData
            )
            .subscribe(
                (response) => {
                    if (response) {
                        this.businessLicenseData = response.data[0];
                    }
                },
                (err) => {
                    //  this.isLoading = false;
                    // alert(
                    //   "There was an error while getting business license. Please try again later."
                    // );
                }
            );
    }
}
