import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalsService } from 'app/globals-services/globals.service';
import { CubejsService } from 'app/shared/cubejs/cubejs.service';
import { MessageDialogComponent } from 'app/shared/message-dialog/message-dialog.component';
import { ExportDataService } from 'app/shared/services/export-data.service';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';


const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-c-a-monthly-report',
  templateUrl: './c-a-monthly-report.component.html',
  styleUrls: ['./c-a-monthly-report.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CAMonthlyReportComponent implements OnInit {

  isCubejsConnected: boolean = true;
  isLoading: boolean = false;
  tabIndex: number = 0;
  errorList = [];
  hasNotData: boolean = false;
  hasNotDataGlobally: boolean = false;
  hasNotDataFactory: boolean = false;
  hasNotDataChemicalList: boolean = false;
  pageSizeOptions: number[] = [10, 50, 100];
  date = new FormControl(moment().subtract(1, 'month'));
  filters: Array<Object> = [];
  formulasFilters: Array<Object> = [];
  isNike: Boolean = false;
  @ViewChild('cADataPaginator') cADataPaginator: MatPaginator;
  @ViewChild('candADataMatSort') candADataMatSort: MatSort;
  @ViewChild('cADataPaginator_nonCompliant') cADataPaginator_nonCompliant: MatPaginator;
  @ViewChild('candADataMatSort_nonCompliant') candADataMatSort_nonCompliant: MatSort;
  @ViewChild('cADataPaginator_nonCompliantFactory') cADataPaginator_nonCompliantFactory: MatPaginator;
  @ViewChild('candADataMatSort_nonCompliantFactory') candADataMatSort_nonCompliantFactory: MatSort;
  @ViewChild('cADataPaginator_nonCompliant_Globally') cADataPaginator_nonCompliant_Globally: MatPaginator;
  @ViewChild('candADataMatSort_nonCompliant_Globally') candADataMatSort_nonCompliant_Globally: MatSort;
  @ViewChild('cADataPaginator_nonCompliant_ChemicalsList') cADataPaginator_nonCompliant_ChemicalsList: MatPaginator;
  @ViewChild('candADataMatSort_nonCompliant_ChemicalsList') candADataMatSort_nonCompliant_ChemicalsList: MatSort;
  @ViewChild('cAFormulaPaginator') cAFormulaPaginator: MatPaginator;
  @ViewChild('cAFormulaDataMatSort') cAFormulaDataMatSort: MatSort;
  readonly d = new Date();
  organization_name: string;



  constructor(private cubejsService: CubejsService, private exportService: ExportDataService, public dialog: MatDialog, private globalsService: GlobalsService) { }


  ngOnInit(): void {    
    this.organization_name = this.globalsService.profile["name"].toString().toUpperCase();;
    this.onTabChanged()
    

  }

  selectedTabChanged($event) {
    this.tabIndex = $event.index;
    this.onTabChanged()
  }

  onTabChanged() {
    this.filters= [];
    this.formulasFilters= [];
    switch (this.tabIndex) {
      case 0: {
        this.filters.push(
          {
            "member": "CandA.month_of_inventory",
            "operator": "equals",
            "values": [
              this.date.value.format('YYYY-MM')
            ]
          }

        ) 
        this.getCandAData()

        break;
      }
      case 1: {
        this.filters.push(
          {
            "member": "CandA.month_of_inventory",
            "operator": "equals",
            "values": [
              this.date.value.format('YYYY-MM')
            ]
          }

        )
        this.getCandAData_nonCompliantFactory()
        this.getCandAData_nonCompliant();
        this.getCandAData_nonCompliant_Globally();
        this.getCandAData_nonCompliant_ChemicalsList();

        break;
      }
      case 2: {

        this.formulasFilters.push(
          {
            "member": "FormulasCandA.date",
            "operator": "equals",
            "values": [
              this.date.value.format('YYYY-MM')
            ]
          }

        )
        this.getCandADataFormula()

        break;

      }

      default: {

      }
    }
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    this.filters = [];
    const ctrlValue = this.date.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.onTabChanged();


  }

  changeKey(originalKey: string, newKey: string, arr: Array<Object>) {
    var newArr = [];
    for (var i = 0; i < arr.length; i++) {
      var obj = arr[i];
      obj[newKey] = (newKey == "value" && obj[originalKey] === null) ? 0 : obj[originalKey];
      delete (obj[originalKey]);
      newArr.push(obj);
    }
    return newArr;
  }


  c_a_data = [

    "factory_name",
    "country_name",
    "month_of_inventory",
    "formula_count",
    "compliant_formula_count",
    "count_compliance_pct",
    "brand_formula_count",
    "compliant_brand_formula_count",
    "brand_count_compliance_pct",
    "consumption",
    "compliant_quantity",
    "consumption_compliance_pct",
    "brand_consumption",
    "compliant_brand_consumption",
    "brand_consumption_compliance_pct",
  ]

  monthlyDataListCA = new MatTableDataSource<any>()
  isLoadingc_a_DataInfo = false;

  async getCandAData() {
    this.isLoadingc_a_DataInfo = true;
    const query = {
      "dimensions": [
        "CandA.factory_name",
        "CandA.country_name",
        "CandA.month_of_inventory",
        "CandA.formula_count",
        "CandA.compliant_formula_count",
        "CandA.count_compliance_pct",
        "CandA.brand_formula_count",
        "CandA.compliant_brand_formula_count",
        "CandA.brand_count_compliance_pct",
        "CandA.consumption",
        "CandA.compliant_quantity",
        "CandA.consumption_compliance_pct",
        "CandA.brand_consumption",
        "CandA.compliant_brand_consumption",
        "CandA.brand_consumption_compliance_pct",

      ],
      "timeDimensions": [],
      "order": {
      },
      "filters": this.filters,
      "measures": [
      ]
    }
    try {
      const data = await this.cubejsService.getCubeJSData(query, null, null, true, false);
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0) {
          this.hasNotData = false;
          tempData = this.changeKey("CandA.factory_name", "factory_name", tempData)
          tempData = this.changeKey("CandA.country_name", "country_name", tempData)
          tempData = this.changeKey("CandA.month_of_inventory", "month_of_inventory", tempData)
          tempData = this.changeKey("CandA.formula_count", "formula_count", tempData)
          tempData = this.changeKey("CandA.compliant_formula_count", "compliant_formula_count", tempData)
          tempData = this.changeKey("CandA.count_compliance_pct", "count_compliance_pct", tempData)
          tempData = this.changeKey("CandA.brand_formula_count", "brand_formula_count", tempData)
          tempData = this.changeKey("CandA.compliant_brand_formula_count", "compliant_brand_formula_count", tempData)
          tempData = this.changeKey("CandA.brand_count_compliance_pct", "brand_count_compliance_pct", tempData)
          tempData = this.changeKey("CandA.consumption", "consumption", tempData)
          tempData = this.changeKey("CandA.compliant_quantity", "compliant_quantity", tempData)
          tempData = this.changeKey("CandA.consumption_compliance_pct", "consumption_compliance_pct", tempData)
          tempData = this.changeKey("CandA.brand_consumption", "brand_consumption", tempData)
          tempData = this.changeKey("CandA.compliant_brand_consumption", "compliant_brand_consumption", tempData)
          tempData = this.changeKey("CandA.brand_consumption_compliance_pct", "brand_consumption_compliance_pct", tempData)

          tempData = tempData.map(obj => {
          const formatNumber = (value) => {
            if (value === null || value === 0) {
              return '0'; // Default format when the value is null or 0
            }
            return Number(value).toFixed(2);
          };

          obj.count_compliance_pct = Number(formatNumber(obj.count_compliance_pct * 100));
          obj.brand_count_compliance_pct = Number(formatNumber(obj.brand_count_compliance_pct * 100));
          obj.consumption = Number(formatNumber(obj.consumption));
          obj.compliant_quantity = Number(formatNumber(obj.compliant_quantity));
          obj.consumption_compliance_pct = Number(formatNumber(obj.consumption_compliance_pct * 100));
          obj.brand_consumption = Number(formatNumber(obj.brand_consumption));
          obj.compliant_brand_consumption = Number(formatNumber(obj.compliant_brand_consumption));
          obj.brand_consumption_compliance_pct = Number(formatNumber(obj.brand_consumption_compliance_pct * 100));

          return obj;
        })
        }
        else this.hasNotData = true;
        this.monthlyDataListCA.data = tempData;
        this.monthlyDataListCA.paginator = this.cADataPaginator;
        this.monthlyDataListCA.sort = this.candADataMatSort;
        this.isLoadingc_a_DataInfo = false;
      } catch (error) {
        console.error('An error occurred in getCandAData:', error);
        this.isLoadingc_a_DataInfo = false;
      }
  }
  c_a_data_nonCompliantFactory = [

    "factory_name",
    "country_name",
    "month_of_inventory",
    "formula_count",
    "non_compliant_formula_count",
    "count_non_compliance_pct",
    "brand_formula_count",
    "non_compliant_brand_formula_count",
    "brand_count_non_compliance_pct",
    "consumption",
    "non_compliant_quantity",
    "consumption_non_compliance_pct",
    "brand_consumption",
    "non_compliant_brand_consumption",
    "brand_consumption_non_compliance_pct"
  ]
  monthlyDataListCA_nonCompliantFactory = new MatTableDataSource<any>()
  isLoadingc_a_DataInfo_nonCompliantFactory = false;

  async getCandAData_nonCompliantFactory() {
    this.isLoadingc_a_DataInfo_nonCompliantFactory = true;
    const query = {
      "dimensions": [
        "CandA.factory_name",
        "CandA.country_name",
        "CandA.month_of_inventory",
        "CandA.formula_count",
        "CandA.non_compliant_formula_count",
        "CandA.count_non_compliance_pct",
        "CandA.brand_formula_count",
        "CandA.non_compliant_brand_formula_count",
        "CandA.brand_count_non_compliance_pct",
        "CandA.consumption",
        "CandA.non_compliant_quantity",
        "CandA.consumption_non_compliance_pct",
        "CandA.brand_consumption",
        "CandA.non_compliant_brand_consumption",
        "CandA.brand_consumption_non_compliance_pct",
      ],
      "timeDimensions": [],
      "order": {
      },
      "filters": this.filters,
      "measures": [
      ]
    }

    try {
      const data = await this.cubejsService.getCubeJSData(query, null, null, true, false);
      let tempData = data['loadResponse']['results'][0]['data'];
      if (tempData.length > 0) {
        this.hasNotDataFactory = false;
        tempData = this.changeKey("CandA.factory_name", "factory_name", tempData)
        tempData = this.changeKey("CandA.country_name", "country_name", tempData)
        tempData = this.changeKey("CandA.month_of_inventory", "month_of_inventory", tempData)
        tempData = this.changeKey("CandA.formula_count", "formula_count", tempData)
        tempData = this.changeKey("CandA.non_compliant_formula_count", "non_compliant_formula_count", tempData)
        tempData = this.changeKey("CandA.count_non_compliance_pct", "count_non_compliance_pct", tempData)
        tempData = this.changeKey("CandA.brand_formula_count", "brand_formula_count", tempData)
        tempData = this.changeKey("CandA.non_compliant_brand_formula_count", "non_compliant_brand_formula_count", tempData)
        tempData = this.changeKey("CandA.brand_count_non_compliance_pct", "brand_count_non_compliance_pct", tempData)
        tempData = this.changeKey("CandA.consumption", "consumption", tempData)
        tempData = this.changeKey("CandA.non_compliant_quantity", "non_compliant_quantity", tempData)
        tempData = this.changeKey("CandA.consumption_non_compliance_pct", "consumption_non_compliance_pct", tempData)
        tempData = this.changeKey("CandA.brand_consumption", "brand_consumption", tempData)
        tempData = this.changeKey("CandA.non_compliant_brand_consumption", "non_compliant_brand_consumption", tempData)
        tempData = this.changeKey("CandA.brand_consumption_non_compliance_pct", "brand_consumption_non_compliance_pct", tempData)


        tempData = tempData.map(obj => {
          const formatNumber = (value) => {
            if (value === null || value === 0) {
              return '0'; // Default format when the value is null or 0
            }
            return Number(value).toFixed(2);
          };

          obj.count_non_compliance_pct = Number(formatNumber(obj.count_non_compliance_pct * 100));
          obj.brand_count_non_compliance_pct = Number(formatNumber(obj.brand_count_non_compliance_pct * 100));
          obj.consumption = Number(formatNumber(obj.consumption));
          obj.non_compliant_quantity = Number(formatNumber(obj.non_compliant_quantity));
          obj.consumption_non_compliance_pct = Number(formatNumber(obj.consumption_non_compliance_pct * 100));
          obj.non_compliant_brand_consumption = Number(formatNumber(obj.non_compliant_brand_consumption));
          obj.brand_consumption_non_compliance_pct = Number(formatNumber(obj.brand_consumption_non_compliance_pct * 100));

          return obj;
        })
      }
      else this.hasNotDataFactory = true;
      this.monthlyDataListCA_nonCompliantFactory.data = tempData;
      this.monthlyDataListCA_nonCompliantFactory.paginator = this.cADataPaginator_nonCompliantFactory;
      this.monthlyDataListCA_nonCompliantFactory.sort = this.candADataMatSort_nonCompliantFactory;
      this.isLoadingc_a_DataInfo_nonCompliantFactory = false;
    } catch (error) {
      console.error('An error occurred in getCandAData:', error);
      this.isLoadingc_a_DataInfo_nonCompliantFactory = false;
    }
  }

  c_a_data_nonCompliant = [

    "country_name",
    "month_of_inventory",
    "formula_count",
    "non_compliant_formula_count",
    "count_non_compliance_pct",
    "brand_formula_count",
    "non_compliant_brand_formula_count",
    "brand_count_non_compliance_pct",
    "consumption",
    "non_compliant_quantity",
    "consumption_non_compliance_pct",
    "brand_consumption",
    "non_compliant_brand_consumption",
    "brand_consumption_non_compliance_pct"
  ]

  monthlyDataListCA_nonCompliant = new MatTableDataSource<any>()
  isLoadingc_a_DataInfo_nonCompliant = false;

  async getCandAData_nonCompliant() {
    this.isLoadingc_a_DataInfo_nonCompliant = true;
    const query = {
      "dimensions": [
        "CandA.country_name",
        "CandA.month_of_inventory",

      ],
      "timeDimensions": [],
      "order": {
      },
      "filters": this.filters,
      "measures": [
        "CandA.formula_total_count",
        "CandA.brand_formula_total_count",
        "CandA.consumption_sum",
        "CandA.brand_consumption_sum",
        "CandA.non_compliant_total_count",
        "CandA.non_compliance_pct",
        "CandA.non_compliant_brand_total_count",
        "CandA.brand_non_compliance_pct",
        "CandA.non_compliant_total_quantity",
        "CandA.consumption_non_compl_pct",
        "CandA.non_compliant_brand_total_consumption",
        "CandA.brand_consumption_non_compliance_pct_"
      ]
    }

    try {
      const data = await this.cubejsService.getCubeJSData(query, null, null, true, false);
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0) {
          this.hasNotData = false;
          tempData = this.changeKey("CandA.country_name", "country_name", tempData)
          tempData = this.changeKey("CandA.month_of_inventory", "month_of_inventory", tempData)
          tempData = this.changeKey("CandA.formula_total_count", "formula_count", tempData)
          tempData = this.changeKey("CandA.non_compliant_total_count", "non_compliant_formula_count", tempData)
          tempData = this.changeKey("CandA.non_compliance_pct", "count_non_compliance_pct", tempData)
          tempData = this.changeKey("CandA.brand_formula_total_count", "brand_formula_count", tempData)
          tempData = this.changeKey("CandA.non_compliant_brand_total_count", "non_compliant_brand_formula_count", tempData)
          tempData = this.changeKey("CandA.brand_non_compliance_pct", "brand_count_non_compliance_pct", tempData)
          tempData = this.changeKey("CandA.consumption_sum", "consumption", tempData)
          tempData = this.changeKey("CandA.non_compliant_total_quantity", "non_compliant_quantity", tempData)
          tempData = this.changeKey("CandA.consumption_non_compl_pct", "consumption_non_compliance_pct", tempData)
          tempData = this.changeKey("CandA.brand_consumption_sum", "brand_consumption", tempData)
          tempData = this.changeKey("CandA.non_compliant_brand_total_consumption", "non_compliant_brand_consumption", tempData)
          tempData = this.changeKey("CandA.brand_consumption_non_compliance_pct_", "brand_consumption_non_compliance_pct", tempData)


          tempData = tempData.map(obj => {
            const formatNumber = (value) => {
              if (value === null || value === 0) {
                return '0'; // Default format when the value is null or 0
              }
              return Number(value).toFixed(2);
            };

            obj.count_non_compliance_pct = Number(formatNumber(obj.count_non_compliance_pct));
            obj.brand_count_non_compliance_pct = Number(formatNumber(obj.brand_count_non_compliance_pct));
            obj.consumption = Number(formatNumber(obj.consumption));
            obj.non_compliant_quantity = Number(formatNumber(obj.non_compliant_quantity));
            obj.consumption_non_compliance_pct = Number(formatNumber(obj.consumption_non_compliance_pct ));
            obj.non_compliant_brand_consumption = Number(formatNumber(obj.non_compliant_brand_consumption));
            obj.brand_consumption_non_compliance_pct = Number(formatNumber(obj.brand_consumption_non_compliance_pct));

            return obj;
          })
        }
        else this.hasNotData = true;
        this.monthlyDataListCA_nonCompliant.data = tempData;
        this.monthlyDataListCA_nonCompliant.paginator = this.cADataPaginator_nonCompliant;
        this.monthlyDataListCA_nonCompliant.sort = this.candADataMatSort_nonCompliant;
        this.isLoadingc_a_DataInfo_nonCompliant = false;
    } catch (error) {
      console.error('An error occurred in getCandAData:', error);
      this.isLoadingc_a_DataInfo_nonCompliant = false;
    }
  }
  c_a_data_nonCompliant_Globally = [

    "month_of_inventory",
    "formula_total_count",
    "non_compliant_formula_count",
    "count_non_compliance_pct",
    "brand_formula_total_count",
    "non_compliant_brand_formula_count",
    "brand_count_non_compliance_pct",
    "consumption_sum",
    "non_compliant_quantity",
    "consumption_non_compliance_pct",
    "brand_consumption_sum",
    "non_compliant_brand_consumption",
    "brand_consumption_non_compliance_pct"
  ]

  monthlyDataListCA_nonCompliant_Globally = new MatTableDataSource<any>()
  isLoadingc_a_DataInfo_nonCompliant_Globally = false;

  async getCandAData_nonCompliant_Globally() {
    this.isLoadingc_a_DataInfo_nonCompliant_Globally = true;
    const query = {
      "dimensions": [
        "CandA.month_of_inventory",
      ],
      "timeDimensions": [
      ],
      "order": {
      },
      "filters": this.filters,
      "measures": [
        "CandA.formula_total_count",
        "CandA.brand_formula_total_count",
        "CandA.consumption_sum",
        "CandA.brand_consumption_sum",
        "CandA.non_compliant_total_count",
        "CandA.non_compliance_pct",
        "CandA.non_compliant_brand_total_count",
        "CandA.brand_non_compliance_pct",
        "CandA.non_compliant_total_quantity",
        "CandA.consumption_non_compl_pct",
        "CandA.non_compliant_brand_total_consumption",
        "CandA.brand_consumption_non_compliance_pct_",
      ]
    }
    try {
      const data = await this.cubejsService.getCubeJSData(query, null, null, true, false);
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0) {
          this.hasNotDataGlobally = false;
          tempData = this.changeKey("CandA.month_of_inventory", "month_of_inventory", tempData)
          tempData = this.changeKey("CandA.formula_total_count", "formula_total_count", tempData)
          tempData = this.changeKey("CandA.non_compliant_total_count", "non_compliant_formula_count", tempData)
          tempData = this.changeKey("CandA.non_compliance_pct", "count_non_compliance_pct", tempData)
          tempData = this.changeKey("CandA.brand_formula_total_count", "brand_formula_total_count", tempData)
          tempData = this.changeKey("CandA.non_compliant_brand_total_count", "non_compliant_brand_formula_count", tempData)
          tempData = this.changeKey("CandA.brand_non_compliance_pct", "brand_count_non_compliance_pct", tempData)
          tempData = this.changeKey("CandA.consumption_sum", "consumption_sum", tempData)
          tempData = this.changeKey("CandA.non_compliant_total_quantity", "non_compliant_quantity", tempData)
          tempData = this.changeKey("CandA.consumption_non_compl_pct", "consumption_non_compliance_pct", tempData)
          tempData = this.changeKey("CandA.brand_consumption_sum", "brand_consumption_sum", tempData)
          tempData = this.changeKey("CandA.non_compliant_brand_total_consumption", "non_compliant_brand_consumption", tempData)
          tempData = this.changeKey("CandA.brand_consumption_non_compliance_pct_", "brand_consumption_non_compliance_pct", tempData)


          tempData = tempData.map(obj => {
            const formatNumber = (value) => {
              if (value === null || value === 0) {
                return '0'; // Default format when the value is null or 0
              }
              return Number(value).toFixed(2);
            };

            obj.count_non_compliance_pct = Number(formatNumber(obj.count_non_compliance_pct));
            obj.brand_count_non_compliance_pct = Number(formatNumber(obj.brand_count_non_compliance_pct));
            obj.non_compliant_quantity = Number(formatNumber(obj.non_compliant_quantity));
            obj.consumption_non_compliance_pct = Number(formatNumber(obj.consumption_non_compliance_pct));
            obj.non_compliant_brand_consumption = Number(formatNumber(obj.non_compliant_brand_consumption));
            obj.brand_consumption_non_compliance_pct = Number(formatNumber(obj.brand_consumption_non_compliance_pct));

            return obj;
          })
        }
        else this.hasNotDataGlobally = true;
        this.monthlyDataListCA_nonCompliant_Globally.data = tempData;
        this.monthlyDataListCA_nonCompliant_Globally.paginator = this.cADataPaginator_nonCompliant_Globally;
        this.monthlyDataListCA_nonCompliant_Globally.sort = this.candADataMatSort_nonCompliant_Globally;
        this.isLoadingc_a_DataInfo_nonCompliant_Globally = false;
    } catch (error) {
      console.error('An error occurred in getCandAData:', error);
      this.isLoadingc_a_DataInfo_nonCompliant_Globally = false;
    }
  }

  c_a_data_nonCompliant_ChemicalList = [
    "factory_name",
    "chemical_name",
    "manufacturer",
  ]

  monthlyDataListCA_nonCompliant_ChemicalsList = new MatTableDataSource<any>()
  isLoadingc_a_DataInfo_nonCompliant_ChemicalsList = false;

  async getCandAData_nonCompliant_ChemicalsList() {
    this.isLoadingc_a_DataInfo_nonCompliant_ChemicalsList = true;
    const query = {
      "dimensions": [
        "UnverifiedChemicalsCandA.factory_name",
        "UnverifiedChemicalsCandA.chemical_name",
        "UnverifiedChemicalsCandA.manufacturer",

      ],
      "timeDimensions": [],
      "order": {
      },
      "filters": [
        {
        "member": "UnverifiedChemicalsCandA.month_of_inventory",
          "operator": "equals",
          "values": [
            this.date.value.format('YYYY-MM')
          ]
        }],
      "measures": [
      ]
    }
    try {
      const data = await this.cubejsService.getCubeJSData(query, null, null, true, false);
      let tempData = data['loadResponse']['results'][0]['data'];
      if (tempData.length > 0) {
        this.hasNotDataChemicalList = false;
        tempData = this.changeKey("UnverifiedChemicalsCandA.factory_name", "factory_name", tempData)
        tempData = this.changeKey("UnverifiedChemicalsCandA.chemical_name", "chemical_name", tempData)
        tempData = this.changeKey("UnverifiedChemicalsCandA.manufacturer", "manufacturer", tempData)
      }
      else this.hasNotDataChemicalList = true;
      this.monthlyDataListCA_nonCompliant_ChemicalsList.data = tempData;
      this.monthlyDataListCA_nonCompliant_ChemicalsList.paginator = this.cADataPaginator_nonCompliant_ChemicalsList;
      this.monthlyDataListCA_nonCompliant_ChemicalsList.sort = this.candADataMatSort_nonCompliant_ChemicalsList;
      this.isLoadingc_a_DataInfo_nonCompliant_ChemicalsList = false;
    } catch (error) {
      console.error('An error occurred in getCandAData:', error);
      this.isLoadingc_a_DataInfo_nonCompliant_ChemicalsList = false;
    }
  }

  c_a_formula = [
    "factory_name",
    "chemical_product",
    "level",
    "version",
    "compliance_status",
    "gots",
    "eco_passport",
    "bluesign",
    "c2c",
    "green_screen",
    "toxfmd_scivera",
    "test_report"
  ]

  monthlyDataListCAFormula = new MatTableDataSource<any>()
  isLoadingc_a_FormulaInfo = false;

  async getCandADataFormula() {
    this.isLoadingc_a_FormulaInfo = true;
    const query = {
      "dimensions": [
        "FormulasCandA.name",
        "FormulasCandA.chemical_product",
        "FormulasCandA.level",
        "FormulasCandA.version",
        "FormulasCandA.compliance_status",
        "FormulasCandA.gots",
        "FormulasCandA.eco_passport",
        "FormulasCandA.bluesign",
        "FormulasCandA.c2c",
        "FormulasCandA.green_screen",
        "FormulasCandA.toxfmd_scivera",
        "FormulasCandA.test_report",

      ],
      "timeDimensions": [],
      "order": {
      },
      "filters": this.formulasFilters,
      "measures": [
      ]
    }
    try {
      const data = await this.cubejsService.getCubeJSData(query, null, null, true, false);
        let tempData = data['loadResponse']['results'][0]['data'];
        if (tempData.length > 0) {
          this.hasNotData = false;
          tempData = this.changeKey("FormulasCandA.name", "factory_name", tempData)
          tempData = this.changeKey("FormulasCandA.chemical_product", "chemical_product", tempData)
          tempData = this.changeKey("FormulasCandA.level", "level", tempData)
          tempData = this.changeKey("FormulasCandA.version", "version", tempData)
          tempData = this.changeKey("FormulasCandA.compliance_status", "compliance_status", tempData)
          tempData = this.changeKey("FormulasCandA.gots", "gots", tempData)
          tempData = this.changeKey("FormulasCandA.eco_passport", "eco_passport", tempData)
          tempData = this.changeKey("FormulasCandA.bluesign", "bluesign", tempData)
          tempData = this.changeKey("FormulasCandA.c2c", "c2c", tempData)
          tempData = this.changeKey("FormulasCandA.green_screen", "green_screen", tempData)
          tempData = this.changeKey("FormulasCandA.toxfmd_scivera", "toxfmd_scivera", tempData)
          tempData = this.changeKey("FormulasCandA.test_report", "test_report", tempData)
        }
        else this.hasNotData = true;
        this.monthlyDataListCAFormula.data = tempData;
        this.monthlyDataListCAFormula.paginator = this.cAFormulaPaginator;
        this.monthlyDataListCAFormula.sort = this.cAFormulaDataMatSort;
        this.isLoadingc_a_FormulaInfo = false;
    } catch (error) {
      console.error('An error occurred in getCandAData:', error);
      this.isLoadingc_a_DataInfo = false;
    }
  }


 

  async exportData() {
    this.filters = [];
    this.formulasFilters = [];
    try {
      this.filters.push({
        "member": "CandA.month_of_inventory",
        "operator": "equals",
        "values": [this.date.value.format('YYYY-MM')]
      });
      await this.getCandAData();
    } catch (error) {
      console.error('An error occurred in getCandAData:', error);
    }

    try {
      await this.getCandAData_nonCompliantFactory();
    } catch (error) {
      console.error('An error occurred in getCandAData_nonCompliantFactory:', error);
    }
    try {
      await this.getCandAData_nonCompliant();
    } catch (error) {
      console.error('An error occurred in getCandAData_nonCompliant:', error);
    }
    try {
      await this.getCandAData_nonCompliant_Globally();
    } catch (error) {
      console.error('An error occurred in getCandAData_nonCompliant_Globally:', error);
    }
    try {
      await this.getCandAData_nonCompliant_ChemicalsList();
    } catch (error) {
      console.error('An error occurred in getCandADataFormula:', error);
    }

    try {
      this.formulasFilters.push({
        "member": "FormulasCandA.date",
        "operator": "equals",
        "values": [this.date.value.format('YYYY-MM')]
      });
      await this.getCandADataFormula();
    } catch (error) {
      console.error('An error occurred in getCandADataFormula:', error);
    }


    try {
      await this.exportService.exportCandAasExcelFile(
        this.monthlyDataListCA.data,
        this.monthlyDataListCA_nonCompliantFactory.data,
        this.monthlyDataListCA_nonCompliant.data,
        this.monthlyDataListCA_nonCompliant_Globally.data,
        this.monthlyDataListCA_nonCompliant_ChemicalsList.data,
        this.monthlyDataListCAFormula.data,
        'C&A ' + this.date.value.format('YYYY-MM')
      );
      console.log('All functions completed successfully.');
    } catch (error) {
      console.error('An error occurred during the final export:', error);
    }
  }


    

  

  ShowErrorMessage(msg) {
    this.isLoading = false;
    let customMSg = false;
    if (msg.includes("No id found in Security Context")) {
      msg = 'No factories are connected to this account, so no data can be displayed.\n Ask your factories to connect to you to see their data.',
        customMSg = true;
    }
    if (msg.includes("Network request failed")) {
      this.isCubejsConnected = false;
      throw new Error("CubeJS is not reachable!")
      // return false;
    }
    if (!this.errorList.includes(msg)) {
      this.errorList.push(msg);
      this.dialog.open(MessageDialogComponent, {
        hasBackdrop: true,
        width: "32rem",
        disableClose: true,
        data: {
          message: msg,
          hasLink: customMSg ? true : false,
          goToLink: customMSg ? "How to share inventories" : "",
          URL: customMSg ? "https://cloud.goblu.net/s/4LXyFHrE86Tzx2A" : ""
        },
      });
    }
  }

}
