import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators'
import { from } from 'rxjs';


import { AuthenticationService, JWT_AuthenticationToken } from './globals-services/authentication.service';
import { GatewayAuthenticationService, GatewayAuthenticationToken } from './gateway/authentication/gateway-authentication.service';


@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService,
    private gwAuthService: GatewayAuthenticationService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //    console.log(this.authenticationService); 
    let noAuthUrl: boolean = req.url.includes('maps.googleapis');
    let noAuthUrl1: boolean = req.url.includes('help.thebhive.net');
    let gatewayUrl: boolean = req.url.includes('my-aip.com');
    let gatewayMockUrl: boolean = req.url.includes('/mock/Gateway');

    //removed authorization
    if (noAuthUrl1) {
      const headers = req.headers.delete('Authorization');
      return next.handle(req.clone({ headers: headers }));
      
    } else if (noAuthUrl) {
      return next.handle(req);
    } else if (gatewayMockUrl) {

      if (!this.authenticationService.isAuthenticated) {
        return next.handle(req);
      }
      const tokenPromise: Promise<JWT_AuthenticationToken> = this.authenticationService.getToken();
      const tokenObservable: Observable<JWT_AuthenticationToken> = from(tokenPromise);

      return tokenObservable.pipe(switchMap(token => {
        // Headers are immutable since Angular 4.3 HttpClient
        const headers = req.headers.set('Authorization', token.token_type + ' ' + token.access_token);
        return next.handle(req.clone({ headers: headers }));
      })
      );

    } else if (gatewayUrl) {

      // console.log(this.gwAuthService.authenticated);
      if (!this.gwAuthService.authenticated) {
        return next.handle(req);
      }

      const tokenPromise: Promise<GatewayAuthenticationToken> = this.gwAuthService.getToken();
      const tokenObservable: Observable<GatewayAuthenticationToken> = from(tokenPromise);

      return tokenObservable.pipe(switchMap(token => {
        // Headers are immutable since Angular 4.3 HttpClient
        const headers = req.headers.set('accessToken', token.accessToken);
        return next.handle(req.clone({ headers: headers }));
      })
      );

    } else {
      return next.handle(req);

    }

  }
}