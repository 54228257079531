import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DataSharedServiceService {

  isUpdate: boolean = false;

  selectedIndex: number = 0;

  commonSubject = new Subject<any>();
}
