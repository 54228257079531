<div *ngIf="isCubejsConnected" class="flex flex-col flex-auto min-w-0">
    <!-- Main -->
    <div class="flex-auto p-6 sm:p-10">
        <!-- CONTENT GOES HERE -->
        <div>
            <div class="flex items-center justify-between w-full">
                <h2
                    *ngIf="!hideTitle"
                    class="text-3xl font-semibold tracking-tight leading-8"
                    i18n="Inventories Overview Component header"
                >
                    {{ "INVENTORIES_REPORT_COMPONENT.PAGE_HAEDER" | translate }}
                </h2>
            </div>

            <div>
                <h2
                    class="page-sub-header"
                    i18n="Inventories Overview Component sub header"
                >
                    {{
                        "INVENTORIES_REPORT_COMPONENT.PAGE_SUB_HEADER"
                            | translate
                    }}
                </h2>
            </div>
            <app-formula-pie-chart-template
                [hideDate]="false" [hideFactory]="hideFactory" 
            ></app-formula-pie-chart-template>
        </div>
    </div>
</div>
<div *ngIf="!isCubejsConnected" class="flex flex-col flex-auto min-w-0">
    <div class="flex-auto p-6 sm:p-10">
        <div class="content">
            <div
                class="flex flex-auto items-center justify-center w-full mb-2 min-h-32"
            >
                <span class="text-3xl font-bold leading-8">
                    Analytics are currently not available due to maintenance.
                    Will be back soon!
                </span>
            </div>
        </div>
    </div>
</div>
