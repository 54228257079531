import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { GlobalsService } from 'app/globals-services/globals.service';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { MailFilter, MailFolder, MailLabel, Mail, FiltersNotification } from './mailbox.types';

@Injectable({
    providedIn: 'root'
})
export class MailboxService {
    selectedMailChanged: BehaviorSubject<any> = new BehaviorSubject(null);
    // private _category: BehaviorSubject<MailCategory> = new BehaviorSubject(null);
    private _filters: BehaviorSubject<MailFilter[]> = new BehaviorSubject(null);
    private _folders: BehaviorSubject<MailFolder[]> = new BehaviorSubject(null);
    private _labels: BehaviorSubject<MailLabel[]> = new BehaviorSubject(null);
    private _mails: BehaviorSubject<any> = new BehaviorSubject(null);
    private _mailsLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _mail: BehaviorSubject<Mail> = new BehaviorSubject(null);
    private _pagination: BehaviorSubject<any> = new BehaviorSubject(null);

    unReadMessagesTotal$: BehaviorSubject<any>= new BehaviorSubject<number>(null);
    unReadNotificationTotal$: BehaviorSubject<any>= new BehaviorSubject<number>(null);
    notificationUpdatedList$: BehaviorSubject<any[]>=new BehaviorSubject<any[]>(null);
    messagesUpdatedList$: BehaviorSubject<any[]>=new BehaviorSubject<any[]>(null);
    notificationSenderList$: BehaviorSubject<any[]>=new BehaviorSubject<any[]>(null);
    
    

    isAnyFilterApplyOnMailList:Boolean=false;
    filtersNotification: FiltersNotification;
    isLastNotification:boolean=false; // This is used for if last notification available in list filter by sender , we would delete it

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient, 
        private globalService: GlobalsService,
        private _fuseNavigationService: FuseNavigationService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // /**
    //  * Getter for category
    //  */
    // get category$(): Observable<MailCategory>
    // {
    //     return this._category.asObservable();
    // }

    /**
     *   Set Unread Notification Total to BehaviorSubject
     */
    // setUnreadNotificationTotal(){
    //     this.unReadNotificationTotal$.next(22);
    // }

    /**
     * Getter for filters
     */
    get filters$(): Observable<MailFilter[]> {
        return this._filters.asObservable();
    }

    /**
     * Getter for folders
     */
    get folders$(): Observable<MailFolder[]> {
        return this._folders.asObservable();
    }

    /**
     * Getter for labels
     */
    get labels$(): Observable<MailLabel[]> {
        return this._labels.asObservable();
    }

    /**
     * Getter for mails
     */
    get mails$(): Observable<Mail[]> {
        return this._mails.asObservable();
    }

    /**
     * Getter for mails loading
     */
    get mailsLoading$(): Observable<boolean> {
        return this._mailsLoading.asObservable();
    }

    /**
     * Getter for mail
     */
    get mail$(): Observable<Mail> {
        return this._mail.asObservable();
    }

    /**
     * Getter for pagination
     */
    get pagination$(): Observable<any> {
        return this._pagination.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get filters
     */
    getFilters(): Observable<any> {
        return this._httpClient.get<MailFilter[]>('api/apps/mailbox/filters').pipe(
            tap((response: any) => {
                this._filters.next(response);
            })
        );
    }

    /**
     * Get folders
     */
    getFolders(): Observable<any> {
        return this._httpClient.get<MailFolder[]>('api/apps/mailbox/folders').pipe(
            tap((response: any) => {
                this._folders.next(response);
            })
        );
    }

    /**
     * Get labels
     */
    getLabels(): Observable<any> {
        return this._httpClient.get<MailLabel[]>('api/apps/mailbox/labels').pipe(
            tap((response: any) => {
                this._labels.next(response);
            })
        );
    }

    /**
     * Get mails by filter
     */
    getMailsByFilter(filter: string, page: string = '1'): Observable<any> {
        // Execute the mails loading with true
        this._mailsLoading.next(true);



        return this._httpClient.get<Mail[]>('api/apps/mailbox/mails', {
            params: {
                filter,
                page
            }
        }).pipe(
            tap((response: any) => {

                this._mails.next(response.mails);
                this._pagination.next(response.pagination);
                this._mailsLoading.next(false);
            }),
            switchMap((response) => {

                if (response.mails === null) {
                    return throwError({
                        message: 'Requested page is not available!',
                        pagination: response.pagination
                    });
                }

                return of(response);
            })
        );
    }

    /**
     *   Updated List Call By REST API for Set to Dialog box and also Notification List if not apply any filter
     */
    getUpdatedMyNotificationList(pageIndex:number, pageSize : number): Observable<any> {
       
        let data = {
            organization: this.globalService.profile['id'],
            page: pageIndex,
            pageSize: pageSize,          
        }

        return this._httpClient.post<any[]>(environment.baseHref + '/myNotificationList', data

        ).pipe(
            tap((response: any) => {

                // if(!this.isAnyFilterApplyOnMailList){

                //     this._mails.next(response.mail);
                //     this._pagination.next(response.pagination);

                // }

            },(error)=>{
                console.info(error)
            }),
            switchMap((response) => {

                if (response.mails === null) {
                    return throwError({
                        message: 'Requested page is not available!',
                        pagination: response.pagination
                    });
                }

                return of(response);
            })
        );
    }


    /**
     * Get mails by folder
     */
    myMessagesList(data): Observable<any> {
        // Execute the mails loading with true
        this._mailsLoading.next(true);

        // console.log("Mail Service call with data :", data)

        return this._httpClient.post<any[]>(environment.baseHref + '/myMessagesList', data

        ).pipe(
            tap((response: any) => {

                if(response.mail.length == 1){
                    this.isLastNotification=true;
                }else{
                    this.isLastNotification=false;
                }

                this._mails.next(response.mail);
                
                this.unReadMessagesTotal$.next(response.totalUnreadMessages)
                this.updateBadgeTitle('NOTIFICATION_BOX.OTHER', 'mainNavigation', response.totalUnreadMessages);
                this._pagination.next(response.pagination);
                this._mailsLoading.next(false);
            },(error)=>{
                console.info(error)
            }),
            switchMap((response) => {

                
                if (response.mails === null) {
                    return throwError({
                        message: 'Requested page is not available!',
                        pagination: response.pagination
                    });
                }

                return of(response);
            })
        );
    }

    /**
     *   Get Unread Messages Count
     */
    getUnreadMessagesCount() {
        return this._httpClient.get<any>(environment.baseHref  + '/unreadMessagesTotalCount');
    }

    /**
     *   Get Unread Notification Count
     */
    getUnreadNotificationCount() {
        return this._httpClient.get<any>(environment.baseHref  + '/unreadNotificationTotalCount');
    }

    /**
     *  Get Sender List 
     */
      getSenderList():Observable<any>{
        return this._httpClient.get<any[]>(environment.baseHref  + '/getSenderNameList');
      }

    /**
     *  Get Notification Types List  
     */
      getNotificationTypeList():Observable<any>{
        return this._httpClient.get<any[]>(environment.baseHref  + '/getAllMasterNotificationTypes');
      }
    
    /**
     *  Get Filters For Sort Notification List
     */
     filterNotificationList(data:FiltersNotification){
        console.log("Filtes Data ",data)

        this.filtersNotification=data;

        // Check Which Filter are Activated 
        let sortByReadOrUnread:number=null
        let sortBysearchKeyword:string=null
        let senderList:string[]=null;
        let sortByDateSpan:Date[] =null;
        let notificationTypes:number[]=null;

        this.isAnyFilterApplyOnMailList=false;

        // Filter By Read/Unread Option are selected
        if(data.isSortByReadNotification){
            sortByReadOrUnread=data.sortByReadNotificationValue

            // check value for will update list if nothing any apply filter on list
            if(data.sortByReadNotificationValue !=null ){
                this.isAnyFilterApplyOnMailList=true;
                // console.log("data.sortByReadNotificationValue : ", data.sortByReadNotificationValue)
            }
        }

        // Filter By Sender List Option are selected
        if(data.isSortBySenderList){
            senderList=data.sortBySender

            // check value for will update list if nothing any apply filter on list
            if(data.sortBySender.length > 0){
                this.isAnyFilterApplyOnMailList=true;
            }
        }

        // Filter By Search Option are selected
        if(data.isSortBySearchKeyword){
            sortBysearchKeyword=data.sortBySearchKeyword
            // console.log("Search Keyword you will send :", sortBysearchKeyword)

            // check value for will update list if nothing any apply filter on list
            if(data.sortBySearchKeyword !=null && data.sortBySearchKeyword !=''){
                this.isAnyFilterApplyOnMailList=true;
            }

        }

        // Filter By Date Span Option are selected
        if(data.isSortByDateSpan){
            sortByDateSpan=data.sortByDateSpan;
            
            // check value for will update list if nothing any apply filter on list
            if(data.sortByDateSpan.length > 0){
                this.isAnyFilterApplyOnMailList=true;
            }
        }

        // Filter By Notification Type Option are selected
        if(data.isSortByNotiTypeList){
            notificationTypes=data.sortByNotiTypeList

            console.log(data.sortByNotiTypeList)

            // check value for will update list if nothing any apply filter on list
            if(data.sortByNotiTypeList.length > 0){
                this.isAnyFilterApplyOnMailList=true;
            }
        }

         const paramData={
                organization: this.globalService.profile['id'],

                readStatus: sortByReadOrUnread,
                senderNames:senderList,
                searchString:sortBysearchKeyword,
                filterByDate:sortByDateSpan,
                type:notificationTypes,

                page: 0,
                pageSize: 10,

         }

        //  console.log("Param Data : ", paramData)
         console.log("Is Any Filter Apply : ", this.isAnyFilterApplyOnMailList)
        
        this.myMessagesList(paramData).subscribe(
            res=>{
            //    console.log(res)
            
            
            },
            error=>{
                // console.log(error)
            }
        )

     }

     /**
      *  Delete Notification By ID
      */
      deleteNotificationById(notificationId:number){
        return this._httpClient.post<any[]>(environment.baseHref  + '/deleteNotification',{id : notificationId});
      }

      /**
       *  Get Updated List With set Filters
       */
      getUpdatedNotiListWithFilters(){
        if(this.filtersNotification===undefined || this.filtersNotification === null){
            this.filtersNotification= new FiltersNotification();
            // Set Initial Value of FiltersNotification 
            this.filtersNotification.isSortBySearchKeyword = false
            this.filtersNotification.isSortBySenderList = false
            this.filtersNotification.isSortByDateSpan = false
            this.filtersNotification.isSortByReadNotification = false
        }
       
        // If you filtered By sender and result comes only one then we reset sender filter
        if(this.isLastNotification){
            console.log("This is last notification")
            this.filtersNotification.sortBySender=[];  
        }

        this.filterNotificationList(this.filtersNotification);
        
      }



    // /**
    //  * Get mails by label
    //  */
    // getMailsByLabel(label: string, page: string = '1'): Observable<any>
    // {
    //     // Execute the mails loading with true
    //     this._mailsLoading.next(true);

    //     return this._httpClient.get<Mail[]>('api/apps/mailbox/mails', {
    //         params: {
    //             label,
    //             page
    //         }
    //     }).pipe(
    //         tap((response: any) => {
    //             this._category.next({
    //                 type: 'label',
    //                 name: label
    //             });
    //             this._mails.next(response.mails);
    //             this._pagination.next(response.pagination);
    //             this._mailsLoading.next(false);
    //         }),
    //         switchMap((response) => {

    //             if ( response.mails === null )
    //             {
    //                 return throwError({
    //                     message   : 'Requested page is not available!',
    //                     pagination: response.pagination
    //                 });
    //             }

    //             return of(response);
    //         })
    //     );
    // }

    /**
     * Get mail by id
     */
    getMailById(id: number): Observable<any> {
        return this._mails.pipe(
            take(1),
            map((mails) => {

                // Find the mail
                const mail = mails.find(item => item.id === id) || null;

                // Update the mail
                this._mail.next(mail);

                // Return the mail
                return mail;
            }),
            switchMap((mail) => {

                if (!mail) {
                    return throwError('Could not found mail with id of ' + id + '!');
                }

                return of(mail);
            })
        );
    }

    /**
     * Update mail
     *
     * @param id
     * @param mail
     */
    updateMail(id: string, mail: Mail): Observable<any> {
        return this._httpClient.patch('api/apps/mailbox/mail', {
            id,
            mail
        }).pipe(
            tap(() => {

                // Re-fetch the folders on mail update
                // to get the updated counts on the sidebar
                this.getFolders().subscribe();
            })
        );
    }

    updateNotificationReadStatus(id: string, readStatus:number): Observable<any> {
        return this._httpClient.post(environment.baseHref + '/updateNotificationReadStatus', {
            id,
            readStatus
        }).pipe(
            tap(() => {

                // Re-fetch the folders on mail update
                // to get the updated counts on the sidebar
                this.getFolders().subscribe();
            })
        );
    }

    /**
     * Reset the current mail
     */
    resetMail(): Observable<boolean> {
        return of(true).pipe(
            take(1),
            tap(() => {
                this._mail.next(null);
            })
        );
    }

    /**
     * Add label
     *
     * @param label
     */
    addLabel(label: MailLabel): Observable<any> {
        return this.labels$.pipe(
            take(1),
            switchMap(labels => this._httpClient.post<MailLabel>('api/apps/mailbox/label', { label }).pipe(
                map((newLabel) => {

                    // Update the labels with the new label
                    this._labels.next([...labels, newLabel]);

                    // Return the new label
                    return newLabel;
                })
            ))
        );
    }

    /**
     * Update label
     *
     * @param id
     * @param label
     */
    updateLabel(id: string, label: MailLabel): Observable<any> {
        return this.labels$.pipe(
            take(1),
            switchMap(labels => this._httpClient.patch<MailLabel>('api/apps/mailbox/label', {
                id,
                label
            }).pipe(
                map((updatedLabel: any) => {

                    // Find the index of the updated label within the labels
                    const index = labels.findIndex(item => item.id === id);

                    // Update the label
                    labels[index] = updatedLabel;

                    // Update the labels
                    this._labels.next(labels);

                    // Return the updated label
                    return updatedLabel;
                })
            ))
        );
    }

    /**
     * Delete label
     *
     * @param id
     */
    deleteLabel(id: string): Observable<any> {
        return this.labels$.pipe(
            take(1),
            switchMap(labels => this._httpClient.delete('api/apps/mailbox/label', { params: { id } }).pipe(
                map((isDeleted: any) => {

                    // Find the index of the deleted label within the labels
                    const index = labels.findIndex(item => item.id === id);

                    // Delete the label
                    labels.splice(index, 1);

                    // Update the labels
                    this._labels.next(labels);

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }
    

    updateBadgeTitle(itemId, navigationName, title): void
    { 
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(navigationName); 
        // Return if the navigation component does not exist
        if ( !navComponent )
        {
            return null;
        }

        // Get the navigation item, update the badge and refresh the component
        const navigation = navComponent.navigation;
        const item = this._fuseNavigationService.getItem(itemId, navigation);
        
        if(!item){
            return
        }
        if(title =='0' || title ==0 || title ==null || title ==undefined) {
            item.badge.title = title;
            item.badge.classes=''
        }else{
            item.badge.title = title;
            item.badge.classes='px-2 bg-red-600 text-white rounded-full'
        }
        
        // console.log("Badge Title 2 :",item.badge.title)
        navComponent.refresh();
    }
}
