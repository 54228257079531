<div class="header-general  items-center  flex justify-between">
    <div>
        <h3 class="h3 m-0 p-2  ml-4">
            {{ "CHEMICAL_DOCUMENT_LIST_COMPONENT.HEADER.CHEMICAL" | translate }}:
            {{ data["name"] }}
        </h3>
        <h4 class="h3 m-0 p-2  ml-4">
            {{
            "CHEMICAL_DOCUMENT_LIST_COMPONENT.HEADER.MANUFACTURER" | translate
            }}: {{ data["manufacturer"] }}
        </h4>
    </div>

    <div class="w3-large ml-4">
        <a (click)="close()">
            <mat-icon>close</mat-icon>
        </a>
    </div>

</div>

<div class="content">




    <div>
        <h4 class="h4">
            {{
            "CHEMICAL_DOCUMENT_LIST_COMPONENT.DOCUMENTS_TABLE.TITLE"
            | translate
            }}
        </h4>
        <table class="table table-striped tablefixed">
            <thead>
                <tr>
                    <th>
                        {{
                        "CHEMICAL_DOCUMENT_LIST_COMPONENT.DOCUMENTS_TABLE.TABLE_HEADER.NAME"
                        | translate
                        }}
                    </th>
                    <th>
                        {{
                        "CHEMICAL_DOCUMENT_LIST_COMPONENT.DOCUMENTS_TABLE.TABLE_HEADER.TYPE"
                        | translate
                        }}
                    </th>
                    <th>
                        {{
                        "CHEMICAL_DOCUMENT_LIST_COMPONENT.DOCUMENTS_TABLE.TABLE_HEADER.ISSUE"
                        | translate
                        }}
                    </th>

                    <th>
                        {{
                        "CHEMICAL_DOCUMENT_LIST_COMPONENT.DOCUMENTS_TABLE.TABLE_HEADER.COMMENT"
                        | translate
                        }}
                    </th>


                    <th *ngIf="isVerficationDoc">
                        {{
                        "CHEMICAL_DOCUMENT_LIST_COMPONENT.DOCUMENTS_TABLE.TABLE_HEADER.STATUS"
                        | translate
                        }}
                    </th>
                    <th *ngIf="isVerficationDoc">
                        Bluwin {{
                        "CHEMICAL_DOCUMENT_LIST_COMPONENT.DOCUMENTS_TABLE.TABLE_HEADER.COMMENT"
                        | translate
                        }}
                    </th>

                    <th class="grid justify-center">
                        {{
                        "CHEMICAL_DOCUMENT_LIST_COMPONENT.DOCUMENTS_TABLE.TABLE_HEADER.ACTION"
                        | translate
                        }}
                    </th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let document of documents">
                    <td>{{ document["name"] }}</td>

                    <td>{{ translateDocumentType(document["category"]) }}</td>
                    <td>{{ document["issue"] | date }}</td>
                    <!-- <td>{{ document["expiry"] | date }}</td> -->
                    <td>{{ document["comment"] }}</td>
                    <!-- <td *ngIf="isVerficationDoc">
                        <span *ngIf=" document['documentVerificationStatus'] ==1">Under Review</span>
                        <span *ngIf=" document['documentVerificationStatus'] ==2" style="color: green;">Approved</span>
                        <span *ngIf=" document['documentVerificationStatus'] ==3" style="color: red;">Declined</span>
                    </td> -->

                    <td *ngIf="isVerficationDoc">
                        <span *ngIf=" [1, 5,7].includes(document['documentVerificationStatus'])  ">Under Review</span>
                        <span
                            *ngIf=" document['documentVerificationStatus'] ==2 && document['bluwinDocumentVerificationStatus']==null"
                            style="color: green;">Approved</span>
                        <span *ngIf=" document['documentVerificationStatus'] ==3 " style="color: red;">Declined
                        </span>
                        <span *ngIf="document['bhiveComment']"> ({{ document['bhiveComment']}} )</span>


                        <div *ngIf="document['documentVerificationStatus'] ==2">

                            <span *ngIf=" document['bluwinDocumentVerificationStatus'] ==1">Under Bluwin Review</span>
                            <span *ngIf=" document['bluwinDocumentVerificationStatus'] ==2" style="color: green;">SDS Screened</span>
                            <span *ngIf=" document['bluwinDocumentVerificationStatus'] ==3"
                            style="color: red;">
                                Bluwin Declined
                            </span>
                            <span *ngIf=" document['bluwinDocumentVerificationStatus'] ==4"
                            style="color: red;">
                                Bluwin Declined
                            </span>
                            <span *ngIf=" document['bluwinDocumentVerificationStatus'] ==5" style="color: red;"
                                class="flex  items-center">
                                On Hold
                                <!-- <mat-icon (click)="openSupportingDocumentUploadDialog(document)">upload</mat-icon> -->
                            </span>

                        </div>
                        <!-- <span *ngIf=" document['documentVerificationStatus'] ==4" style="color: red;">Declined-Invalid
                            Name</span> -->

                    </td>

                    <td *ngIf="isVerficationDoc">
                        {{document["bluwinComment"]!='null'? document["bluwinComment"]:'' }}
                    </td>

                    <td>
                        <a (click)="downloadDocument(document['id'], false)" class="grid justify-center">
                            <mat-icon class="table-icon">download</mat-icon>
                        </a>
                        &nbsp;
                        <a *ngIf="!this.data.readonly" (click)="deleteDocument(document['id'])"
                            class="grid justify-center">
                            <mat-icon class="table-icon">delete_outline</mat-icon>
                        </a>
                    </td>


                </tr>
            </tbody>
        </table>
        <div *ngIf="!this.data.readonly">
            <button mat-raised-button (click)="addDocument()">
                {{
                "CHEMICAL_DOCUMENT_LIST_COMPONENT.DOCUMENTS_TABLE.ADD_BUTTON"
                | translate
                }}
                <mat-icon class="add-icon">add</mat-icon>
            </button>
        </div>
    </div>
    <br />




    <ngx-loading [show]="loading"></ngx-loading>
</div>