<div class="  inset-0">
    <!-- Main -->
    <div class="flex flex-col flex-auto  h-full  ">

        <div *ngIf="!isChineseSystem()"
            class="mt-2 mb-2 sm:mt-2 text-xl md:text-xl font-extrabold tracking-tight leading-tight md:leading-none items-center flex justify-center">

            Welcome to the Dashboard Builder!
        </div>

        <div *ngIf="isChineseSystem()"
            class="mt-2 mb-2 sm:mt-2 text-xl md:text-xl font-extrabold tracking-tight leading-tight md:leading-none items-center flex justify-center">

            Welcome to the Dashboard Builder!
        </div>

        <br>
        <p *ngIf="!isChineseSystem()" class="pl-6 pr-6   tracking-tight ">
            You can now create Analytics Dashboards tailored to your goals and requirements.
            We’d be happy to support you in setting things up and customizing your first dashboard - reach out to us via
            <a href="mailto:keyaccounts@thebhive.net" class="contact-email">keyaccounts@thebhive.net</a> to request a
            meeting.
            <br>  <br>

            Please note that this module is currently in a Beta phase.
            That means, we’re now collecting feedback based on which we will further develop the feature - so feel free
            to let us know what you think!
        </p>


        <p *ngIf="isChineseSystem()" class="pl-6 pr-6  tracking-tight ">
            You can now create Analytics Dashboards tailored to your goals and requirements.
            We’d be happy to support you in setting things up and customizing your first dashboard - reach out to us via
            <a href="mailto:keyaccounts@thebhive.net" class="contact-email">keyaccounts@thebhive.net</a> to request a
            meeting.

            <br>  <br>

            Please note that this module is currently in a Beta phase.
            That means, we’re now collecting feedback based on which we will further develop the feature - so feel free
            to let us know what you think!
        </p>

        <br>

        <div class="flex" style="justify-content: center;">
            <button class="  mt-4   choose-button " (click)="dialogRef.close(true)">
                {{isChineseSystem()?'确认知悉！':'Got it! '}}
            </button>
            &nbsp; &nbsp;


            <button class="mt-4 btn btn-primary" (click)="dialogRef.close(false)">
                {{isChineseSystem()?'请再次提醒我。':'Remind me again'}}
            </button>
        </div>
    </div>