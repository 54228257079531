import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable()
export class PurchaseOrderService {

    poUrl = environment.poServiceUrl;
    baseHref = environment.baseHref;

    // poUrl = 'http://localhost:8060/po-services/api';

    constructor(
        private http: HttpClient
    ) { }

    createPurchaseOrder(data) {
        return this.http.put(this.poUrl + "/po/create", data);
    }


    getAllOpenPos(data) {
        return this.http.post(this.poUrl + "/po/getAllOpenPo", data);
    }
    getPOCountByStatus(data) {
        return this.http.post(this.poUrl + "/po/countByStatus", data);
    }
    updatePODelivery(data) {
        // return this.http.put(this.poUrl + "/po/update/delivery", data);
        return this.http.post(this.baseHref + "/createInventoryFromPO", data);
    }
    searchFormula(data) {
        return this.http.post(this.poUrl + "/po/search/formula", data);
    }
    searchManufacturer(data) {
        return this.http.post(this.poUrl + "/po/search/manufacturer", data);
    }
    updatePurchaseOrder(data) {
        return this.http.put(this.poUrl + "/po/update", data);
    }
    // deletePurchaseOrder(data) {
    //     return this.http.post(this.poUrl + "/po/delete", data);
    // }
    deletePOItem(data) {
        return this.http.post(this.poUrl + "/po/delete/poItem", data);
    }
    getPoDetails(data) {
        return this.http.post(this.poUrl + "/po/getPoDetails", data);
    }
    closeOrder(data) {
        return this.http.post(this.poUrl + "/po/close/order", data);
    }
    getClosedOrders(data) {
        return this.http.post(this.poUrl + "/po/pohistory", data);
    }


    savePoItemDelivery(data) {
        return this.http.post(this.poUrl + "/po/item/delivery/save", data);
    }
    fetchBatchDetails(data) {
        return this.http.post(this.poUrl + "/po/item/batch/list", data);
    }
    fetchDeliveries(data) {
        return this.http.post(this.poUrl + "/po/item/delivery/list", data);
    }
    getPoItems(data) {
        return this.http.post(this.poUrl + "/po/items", data);
    }
}

