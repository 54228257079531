<div class="flex flex-col flex-auto min-w-0">
  <!-- Main -->
  <div class="flex-auto p-6 sm:p-10">
    <!-- CONTENT GOES HERE -->
    <div>
      <div class="content">
        <h2  class="text-3xl font-semibold tracking-tight leading-8">{{"FACTORY_REPORTING_COMPONENT.HEADER" | translate}}</h2>
      </div>
    </div>
  </div>
</div>
