<div class="row" style="width: 100%;">
    <div class="" style="height: 90vh;">
        <bhive-dashboard-builder></bhive-dashboard-builder>
    </div>

    <br />
    <br />

    <div class="flex flex-col flex-auto min-w-0">
        <!-- Main -->
        <div class="flex-auto p-6 sm:p-10">
            <!-- CONTENT GOES HERE -->
            <div>

            </div>
        </div>
    </div>
</div>