import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { GlobalsService } from 'app/globals-services/globals.service';

@Component({
  selector: 'app-tox-services-resources',
  templateUrl: './tox-services-resources.component.html',
  styleUrls: ['./tox-services-resources.component.scss']
})
export class ToxServicesResourcesComponent implements OnInit {

  iframeURL: string;
  sanitizedURL: SafeUrl;

  constructor( private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.iframeURL ="https://www.screenedchemistry.com/certificates";
    this.sanitizedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeURL);
  }
}
