import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MakeStickyDirective } from './make-sticky.directive';



@NgModule({
  declarations: [
    MakeStickyDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
   MakeStickyDirective
  ]
})
export class MakeStickyModule { }
