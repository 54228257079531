<div class="mt-2">
    <div [ngClass]="{ content: !isSubComponent }">
        <ngx-loading [show]="loading"></ngx-loading>



        <h4 *ngIf="isSubComponent" i18n="MyDetails" class="text-lg font-bold">
            {{ "PROFILE_COMPONENT.PROFILE_DETAILS.TITLE" | translate }}
        </h4>

        <div class="grid grid-cols-2 gap-4 mt-4" *ngIf="isSubComponent">
            <div>
                <div *ngIf="
                        factoryActivites != null &&
                        factoryActivites.length > 0
                    ">
                    <div class="flex flex-row">
                        <strong i18n="">{{
                            "PROFILE_COMPONENT.FACTORY_ACTIVITY.TITLE"
                            | translate
                            }}
                            :</strong>

                        <div class="ml-2" *ngFor="let fa of factoryActivites">
                            {{ fa.activity }}
                        </div>
                    </div>
                </div>

                <div>
                    <strong>{{
                        "PROFILE_COMPONENT.PROFILE_DETAILS.LANGUAGE_SETTING"
                        | translate
                        }}
                    </strong>
                    <a #tooltip="matTooltip" matTooltip="{{
                            'PROFILE_COMPONENT.PROFILE_DETAILS.LANGUAGE_SETTING_TOOLTIP'
                                | translate
                        }}" matTooltipPosition="above" style="
                            text-decoration: none;
                            font-size: xx-small;
                        ">
                        <mat-icon class="info-icon">info_outline</mat-icon>
                    </a>

                    <mat-form-field class="ml-4" appearance="standard" style="width: 50%">
                        <mat-select matNativeControl [(ngModel)]="selectedLanguage"
                            (selectionChange)="saveProfile(true)">
                            <mat-option *ngFor="
                                    let language of languageService[
                                        'availableLanguages'
                                    ]
                                " [value]="language['id']">
                                {{ language["name"] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div>
                    <p i18n="Enter company details">
                        {{
                        "PROFILE_COMPONENT.PROFILE_DETAILS.COMPANY_DETAILS.SUBTITLE"
                        | translate
                        }}
                    </p>
                    <strong i18n="Company address">{{
                        "PROFILE_COMPONENT.PROFILE_DETAILS.COMPANY_DETAILS.COMPANY_ADDRESS"
                        | translate
                        }}</strong><br />
                    <textarea (change)="saveProfile(false)" [(ngModel)]="
                            globalsService.profile['companyAddress']
                        " style=""></textarea>
                    <br /><br />
                    <div *ngIf="!(isBrand || isImporter)">
                        <strong i18n="Factory address">{{
                            "PROFILE_COMPONENT.PROFILE_DETAILS.COMPANY_DETAILS.FACTORY_ADDRESS"
                            | translate
                            }}</strong><br />
                        <textarea (change)="saveProfile(false)" [(ngModel)]="
                                globalsService.profile['factoryAddress']
                            " style=""></textarea>
                        <br /><br />
                    </div>
                    <strong i18n="Phone">{{
                        "PROFILE_COMPONENT.PROFILE_DETAILS.COMPANY_DETAILS.PHONE"
                        | translate
                        }}</strong><br />
                    <input class="text-input" type="text" (change)="saveProfile(false)"
                        [(ngModel)]="globalsService.profile['phone']" style="" />
                    <br /><br />

                    <div *ngIf="!(isBrand || isImporter)">
                        <strong i18n="Factory mail address">{{
                            "PROFILE_COMPONENT.PROFILE_DETAILS.COMPANY_DETAILS.EMAIL"
                            | translate
                            }}</strong><br />
                        <input class="text-input" type="text" (change)="saveProfile(false)" [(ngModel)]="
                                globalsService.profile[
                                    'factoryMailAddress'
                                ]
                            " style="" />
                        <br /><br />
                    </div>
                </div>
            </div>

            <div *ngIf="!(isBrand || isImporter)">

                <!-- <div class="mb-4 font-normal text-md">
                    <mat-checkbox color="primary" [(ngModel)]="isVisibleProfileForBrandConnection"
                        (change)="isVisibleSearchForBrand()">
                        Allow the profile to be visible to the brand in connection requests
                    </mat-checkbox>

                </div> -->


                <strong>
                    {{
                    "PROFILE_COMPONENT.ZDHC_DETAILS.TITLE"
                    | translate
                    }} </strong><br />

                <div *ngIf="zdhcApply">
                    <!-- [routerLink]="'/gateway-subscription'" -->
                    <button (click)="goToGatewaySubscription()" class="btn btn-lg btn-primary btn-block">
                        {{
                        "PROFILE_COMPONENT.ZDHC_DETAILS.APPLY"
                        | translate
                        }}
                    </button>
                    <span style="max-width: 90%; display: block">
                        {{
                        "PROFILE_COMPONENT.ZDHC_DETAILS.INFO_LINE_ONE"
                        | translate
                        }}
                        <a target="_blank"
                            rel="https://knowledge-base.roadmaptozero.com/hc/en-gb/articles/4404775473553-Getting-a-ZDHC-Gateway-account-Supplier-">{{
                            "PROFILE_COMPONENT.ZDHC_DETAILS.INFO_LINE_TWO"
                            | translate
                            }}</a>
                        {{
                        "PROFILE_COMPONENT.ZDHC_DETAILS.INFO_LINE_THREE"
                        | translate
                        }}
                    </span>
                </div>

                <div *ngIf="zdhcSubscription" class="mt-4">
                    {{
                    "PROFILE_COMPONENT.ZDHC_DETAILS.ZDHC_ORGANIZATION"
                    | translate
                    }}: {{ organizationName }}
                    <br />
                    {{
                    "PROFILE_COMPONENT.ZDHC_DETAILS.STATUS"
                    | translate
                    }}: {{ zdhcSubscriptionStatus }}
                    <br />
                    {{
                    "PROFILE_COMPONENT.ZDHC_DETAILS.EXPIRATION"
                    | translate
                    }}:
                    {{
                    zdhcExpiration !== null
                    ? (zdhcExpiration | date : "yyyy-MM-dd")
                    : ("PROFILE_COMPONENT.ZDHC_DETAILS.NO_SUBSCRIPTION"
                    | translate)
                    }}
                </div>
                <div *ngIf="zdhcPending">
                    {{
                    "PROFILE_COMPONENT.ZDHC_DETAILS.ZDHC_ORGANIZATION"
                    | translate
                    }}: {{ organizationName }}
                    <br />
                    {{
                    "PROFILE_COMPONENT.ZDHC_DETAILS.STATUS"
                    | translate
                    }}: {{ zdhcSubscriptionStatus }}
                    <br />
                    {{
                    "PROFILE_COMPONENT.ZDHC_DETAILS.APPLICATION_DATE"
                    | translate
                    }}: {{ applicationDate | date : "yyyy-MM-dd" }}
                    <br />
                    {{
                    "PROFILE_COMPONENT.ZDHC_DETAILS.REFERENCE_NUMBER"
                    | translate
                    }}: {{ referenceNumber }}
                </div>

                <div class="mt-4">
                    <div *ngIf="!(isBrand || isImporter)">
                        <strong i18n="Higg ID">{{
                            "PROFILE_COMPONENT.PROFILE_DETAILS.HIGG_ID"
                            | translate
                            }} </strong><br />
                        <input class="text-input" type="text" (change)="saveProfile(false)" [(ngModel)]="
                                globalsService.profile['higgID']
                            " style="" />
                    </div>

                    <div class="mt-4">
                        <div *ngIf="!(isBrand || isImporter)">
                            <strong i18n="Zhhc AID">{{
                                "PROFILE_COMPONENT.PROFILE_DETAILS.ZDHC_AID"
                                | translate
                                }} </strong><br />
                            <input class="text-input" type="text" (change)="saveProfile(false)" [(ngModel)]="
                                    globalsService.profile['zdhcAID']
                                " style="" />
                        </div>

                        <br />

                        <div *ngIf="!(isBrand || isImporter)">
                            <strong i18n="Higg ID">{{
                                "PROFILE_COMPONENT.PROFILE_DETAILS.OAR_ID"
                                | translate
                                }} </strong><br />
                            <input class="text-input" type="text" (change)="saveProfile(false)" [(ngModel)]="
                                    globalsService.profile['oarID']
                                " />
                        </div>
                        <br />
                        <div *ngIf="!(isBrand || isImporter)">
                            <strong i18n="Higg ID">{{
                                "PROFILE_COMPONENT.PROFILE_DETAILS.FCC_ID"
                                | translate
                                }} </strong><br />
                            <input class="text-input" type="text" (change)="saveProfile(false)" [(ngModel)]="
                                    globalsService.profile['fccID']
                                " />
                        </div>
                    </div>

                    <div *ngIf="isWalmartConnected()">
                        <form class="mt-4" [formGroup]="walMartVendorIdForm">
                            <strong i18n="">{{
                                "PROFILE_COMPONENT.WALMART_VENDOR_NUMBER"
                                | translate
                                }}</strong><br />
                            <div formArrayName="walmartVendorIds">
                                <button class="btn btn-primary" (click)="addVendorId()">
                                    {{
                                    "PROFILE_COMPONENT.ADD"
                                    | translate
                                    }}
                                </button>
                                <div *ngFor="
                                        let idField of getVendorIdControl.controls;
                                        let i = index
                                    ">
                                    <div [formGroupName]="i">
                                        <mat-form-field appearance="outline">
                                            <input style="" type="text" (change)="
                                                    addUpdateVendorId(
                                                        walMartVendorIdForm.value
                                                    )
                                                " matInput formControlName="vendorIdValue" placeholder="{{
                                                    'PROFILE_COMPONENT.WALMART_VENDOR_NUMBER'
                                                        | translate
                                                }}" maxlength="6" (ngModelChange)="
                                                    checkWalmartVendorIdLength(
                                                        $event
                                                    )
                                                " />
                                        </mat-form-field>
                                        &nbsp;&nbsp;
                                        <mat-icon class="table-icon" (click)="
                                                deleteVendor(
                                                    idField,
                                                    i,
                                                    getVendorIdControl
                                                        .controls.length
                                                )
                                            ">
                                            delete_outline</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <!-- <form class="mt-4" [formGroup]="walMartBusinessIdForm">
                            <strong i18n="Higg ID">{{
                                "PROFILE_COMPONENT.PROFILE_DETAILS.BUSINESS_PARTNER_ID"
                                | translate
                                }}</strong><br />
                            <div formArrayName="walmartBusinessIds">
                                <button class="btn btn-primary" (click)="addBusinessId()">
                                    {{
                                    "PROFILE_COMPONENT.ADD"
                                    | translate
                                    }}
                                </button>
                                <div *ngFor="
                                        let idField of getBusinessIdControl.controls;
                                        let i = index
                                    ">
                                    <div [formGroupName]="i">
                                        <mat-form-field appearance="outline">
                                            <input style="" type="text" (change)="
                                                    addUpdateBusinessId(
                                                        walMartBusinessIdForm.value
                                                    )
                                                " matInput formControlName="partnerIdValue" placeholder="{{
                                                    'PROFILE_COMPONENT.PROFILE_DETAILS.BUSINESS_PARTNER_ID'
                                                        | translate
                                                }}" maxlength="8" (ngModelChange)="
                                                    checkWalmartIdLength(
                                                        $event
                                                    )
                                                " />
                                        </mat-form-field>
                                        &nbsp;&nbsp;
                                        <mat-icon class="table-icon" (click)="
                                                deleteBusinessPartner(
                                                    idField
                                                );
                                                deleteBusinessId(i)
                                            ">delete_outline
                                        </mat-icon>
                                    </div>
                                </div>
                            </div>
                        </form> -->
                    </div>
                </div>
            </div>

            <br />

            <div *ngIf="isFactory && isSubComponent">
                <strong i18n="">{{
                    "PROFILE_COMPONENT.BUSINESS_LICENSE.TITLE"
                    | translate
                    }} </strong><br />

                <div *ngIf="businessLicenseData == null">
                    <label>{{
                        "DOCUMENT_UPLOAD_COMPONENT.LABELS.FILE_SELECT"
                        | translate
                        }}*</label>
                    <input type="file" accept="application/pdf" (change)="addFile($event.target.files)" id="file"
                        required />

                    <div style="margin-top: 4px">
                        <mat-form-field class="input-field-wrapper">
                            <mat-label>{{
                                "DOCUMENT_UPLOAD_COMPONENT.LABELS.ISSUE_DATE"
                                | translate
                                }}&nbsp;*</mat-label>
                            <input matInput [matDatepicker]="picker1" [max]="expiryDate" [(ngModel)]="issueDate"
                                id="issue" />
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                        &nbsp;
                        <mat-form-field class="input-field-wrapper">
                            <mat-label>{{
                                "DOCUMENT_UPLOAD_COMPONENT.LABELS.EXPIRY_DATE"
                                | translate
                                }}</mat-label>
                            <input matInput [matDatepicker]="picker2" [min]="issueDate" [(ngModel)]="expiryDate"
                                id="expiry" />
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                        &nbsp;
                        <button class="btn btn-primary" (click)="uploadBusinessLicense()">
                            {{
                            "PROFILE_COMPONENT.BUSINESS_LICENSE.LABEL.UPLOAD_LICENSE"
                            | translate
                            }}
                        </button>
                    </div>
                </div>

                <div *ngIf="businessLicenseData != null">
                    <strong>{{ businessLicenseData.fileName }}</strong>
                    <mat-icon (click)="downloadBusinessLicense()" style="
                            color: #337ab7;
                            vertical-align: middle;
                            padding-left: 5px;
                        ">
                        cloud_download</mat-icon>

                    <mat-icon style="
                            color: #337ab7;
                            vertical-align: middle;
                            padding-left: 5px;
                        " (click)="deleteBusinessLicense()">
                        delete
                    </mat-icon>
                </div>
            </div>
            <br /><br />
        </div>
    </div>
</div>