
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
    FormArray,
    FormBuilder, FormGroup,
    Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MyConnectionListComponent } from 'app/brand-factory-conn/brand-factory-conn-common/my-connection-list/my-connection-list.component';
import { ToastService } from 'app/common/toast-alert/toast.service';
import { ComplianceCheckerService } from 'app/compliance-checker/compliance-checker.service';
import { OrganizationType } from 'app/globals-classes/OrganizationType';
import { Partners } from 'app/globals-interface/partners';
import { GlobalsService } from 'app/globals-services/globals.service';
import { LanguageService } from 'app/language/language.service';


@Component({
    selector: 'app-profile-sub',
    templateUrl: './profile-sub.component.html',
    styleUrls: ['./profile-sub.component.scss']
})
export class ProfileSubComponent implements OnInit {

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    @ViewChild('existingListChild') existingListChild: MyConnectionListComponent;



    userProfile =
        this.globalsService.profile['organization_type'] == 1 ||
        this.globalsService.profile['organization_type'] == 5;


    // mySearchPartnerControl = new FormControl();
    //  searchPartner = '';
    // potentialPartner = [];
    connectedPartner = new MatTableDataSource<Partners>();
    //  displayedColumns = [];
    // displayedMessageAndNotificationColumns = [];

    forms: FormGroup;

    // filteredDisplayOptions = this.mySearchPartnerControl.valueChanges.pipe(
    //     startWith(''),
    //     map((value) => this.formulaNames_filter(value))
    // );

    uploadUrl = '';

    isImporter: boolean;
    isBrand: boolean;
    isFactory: boolean;

    loading: boolean;

    selectedLanguage;
    //selectedBrands = [];
    //myDocuments: any[] = [];



    //waterSources = [];
    //wasteWaterTreatments = [];
    // energySources = [];

    zdhcSubscription: boolean;
    zdhcApply: boolean;
    zdhcPending: boolean;
    zdhcExpiration: string;
    zdhcSubscriptionStatus: string;
    organizationName: string;
    applicationDate: string;
    referenceNumber: string;
    partnerIdValue = [];
    vendorIdValue = [];
    isWalmart: boolean = false;
    walmartSupplierIdValid: boolean = false;
    walmartVendorIdValid: boolean = false;
    show: boolean = false;

    walMartIdForm: FormGroup;
    walMartBusinessIdForm: FormGroup;
    businessIds = [];



    walMartVendorIdForm: FormGroup;
    vendorPartnerIds = [];


    document: any;
    issueDate?: Date;
    expiryDate?: Date;
    alertMsgs = {};
    businessLicenseData: any = null;
    factoryActivites: any = null;
    @Input() isSubComponent: boolean = false;

    totalBrandRequestCount = 0;

    // isVisibleProfileForBrandConnection: boolean;
    // toggleStatusString:string;
    // toggleSearchVisibleColor:string;

    // options = this._formBuilder.group({
    // hideRequired: this.hideRequiredControl,
    // });

    constructor(
        public globalsService: GlobalsService,
        private http: HttpClient,
        private complianceChecker: ComplianceCheckerService,
        private translateService: TranslateService,
        public languageService: LanguageService,
        public dialog: MatDialog,
        private _formBuilder: FormBuilder,
        private toastService: ToastService,
        private router: Router,
        //   private brandFactoryConnectionService: BrandFactoryConnectionService
    ) {
        this.loading = true;

        // this.brandFactoryConnectionService.commonSubject$.subscribe(res => {
        //     if (res['isPotentialPartner'])
        //         this.potentialPartner = res['potentialPartner']

        // if (res['onConnect']) {
        //     this.searchPartner=''
        //     this.filteredDisplayOptions = this.mySearchPartnerControl.valueChanges.pipe(
        //         startWith(''), map((value) => this.formulaNames_filter(value))
        //     );
        // }
        // })

        this.forms = this._formBuilder.group({
            emailControl: ['', [Validators.required, Validators.email]],
        });

        this.fetchTranslations();
        this.translateService.onLangChange.subscribe(() => {
            // this.loading = true;
            this.fetchTranslations();
        });

    }

    ngOnInit() {
        this.refreshProfile();

    }

    // @ Tab Change Event Method
    //   onTabChanged(index) {
    //     switch (index) {
    //       case 0:
    //         this.existingListChild.updateLists()
    //         break;
    //       case 1:
    //         this.supplierConnectionRequestCount()
    //         break;
    //       case 2:

    //         break;
    //     }
    //   }

    // toggleChange(event: MatSlideToggleChange){
    //     // console.log(event.checked);
    //     this.isVisibleProfileForBrandConnection=event.checked
    //     this.isVisibleSearchForBrand()
    // }

    // isVisibleSearchForBrand() {
    //     //  console.log(this.isVisibleProfileForBrandConnection," Brand Connection Visible ");
    //     let data = {
    //         isAllowToProfileNameSearch: this.isVisibleProfileForBrandConnection
    //     }
    //     this.brandFactoryConnectionService.enableVisibilityToBrandInConnRequest(data).subscribe(
    //         res => {
    //             if(this.isVisibleProfileForBrandConnection){
    //                 this.toggleStatusString="You are visible."
    //                 // this.toggleSearchVisibleColor="primary"
    //             }else{
    //                 this.toggleStatusString="You are invisible."
    //                 // this.toggleSearchVisibleColor="accent"
    //             }
    //             this.toastService.success("Your changes save successfully")
    //         },
    //         error => {
    //             console.log(error)
    //         }
    //     )
    // }


    private methodCallsOnInit() {
        this.selectedLanguage = this.globalsService.profile['language']['id'];

        this.getZDHCSubscriptionStatus();
        this.isWalmartConnected();
        this.isImporter = this.globalsService.profile['organization_type'] == 5;
        this.isBrand =
            this.globalsService.profile['organization_type'] ==
            OrganizationType.BRAND;
        this.isFactory =
            this.globalsService.profile['organization_type'] ==
            OrganizationType.SUPPLIER;

        // if (this.isFactory) {
        //    // this.supplierConnectionRequestCount()
        //     this.isVisibleProfileForBrandConnection = this.globalsService.profile['isSearch']

        //     if(this.isVisibleProfileForBrandConnection){
        //         this.toggleStatusString="You are visible."
        //     }else{
        //         this.toggleStatusString="You are invisible."
        //     }
        // }


        //    this.updateLists();
        //    this.selectTableView();

        this.loading = false;

        this.initWalmartBusinessIdForm();

        this.initWalmartVendorForm();

        if (this.isFactory) {
            this.getBusinessLicenseDocument();
        }
    }
    // private formulaNames_filter(value: string): string[] {
    //     const filterValue = value.toLowerCase();

    //     return this.potentialPartner.filter(
    //         (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    //     );
    // }

    saveProfile(languageChanged: boolean) {
        let requestObj = {};

        this.loading = true;

        //   this.showProfileSaved = false;
        // this.showSuccessChange = false;
        //        this.showSuccessDisconnect = false;
        // this.showSuccessConnect = false;

        this.globalsService.profile['language'] = this.selectedLanguage;

        // this.globalsService.profile['energySource'] = this.selectedEnergySources.join(',');
        // this.globalsService.profile['wasteWaterTreatment'] = this.selectedWasteWaterTreatments.join(',');
        // this.globalsService.profile['waterSource'] = this.selectedWaterSources.join(',');

        requestObj = {
            companyAddress: this.globalsService.profile['companyAddress'],

            factoryAddress: this.globalsService.profile['factoryAddress'],

            phone: this.globalsService.profile['phone'],

            contactEmail: this.globalsService.profile['contactEmail'],

            factoryMailAddress:
                this.globalsService.profile['factoryMailAddress'],

            language: this.globalsService.profile['language'],

            higgID: this.globalsService.profile['higgID'],

            oarID: this.globalsService.profile['oarID'],

            fccID: this.globalsService.profile['fccID'],

            zdhcAID: this.globalsService.profile['zdhcAID'],
        };

        this.http
            .put(
                this.globalsService.baseHref + '/saveCompanyProfile',
                requestObj
            )
            .subscribe((response) => {
                this.globalsService.whoAmI().subscribe(
                    (response) => {
                        this.globalsService.profile = response;


                        let data: any = response;
                        this.factoryActivites = data.factoryActivities;


                        if (languageChanged) {
                            this.translateService.use(
                                this.globalsService.profile['language'][
                                'languageCode'
                                ]
                            );
                            this.languageService.selectedLanguage =
                                this.globalsService.profile['language']['id'];
                        }

                        this.loading = false;

                        this.toastService.success(
                            this.alertMsgs['PROFILE_SAVE_SUCCESSFUL_MESSAGE']
                        );
                        //  this.showProfileSaved = true;

                        // setTimeout(() => (this.showProfileSaved = false), 1000);
                    },

                    (err) => {
                        //console.log(err);
                        this.loading = false;
                    }
                );
            });
    }

    // filterRole(role: string) {
    //     return this.globalsService.brands
    //         .filter((item) => item['role'] === role)
    //         .sort(function (a, b) {
    //             let x = a.name;
    //             let y = b.name;
    //             return x < y ? -1 : x > y ? 1 : 0;
    //         });
    // }


    // isGoBlu(sharedWith: string) {
    //     if (sharedWith === 'GoBlu_Brand' || sharedWith === 'GoBlu Importeur') {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }

    // connect() {

    //     const partner = this.potentialPartner.filter(
    //         (option) => option.name === this.searchPartner
    //     )[0].id;

    //     let partnerIdValues = this.partnerIdValue;
    //     let vendorIdValues = this.vendorIdValue;

    //     this.connectHttpCallRequest(partner, partnerIdValues, vendorIdValues, false, null);

    // }






    // allow(partner: number) {
    //     //allow and refresh the list


    //     this.toastService.info(this.alertMsgs['CONNECTING_PARTNER_MESSAGE']);


    //     this.http
    //         .put(this.globalsService.baseHref + '/allow', { id: partner })
    //         .subscribe(
    //             (res) => {
    //                 //        //console.log(res);
    //                 this.globalsService
    //                     .getAllPartners()
    //                     .subscribe((response) => {
    //                         //          //console.log(this.globalsService.brands);
    //                         this.globalsService.brands = response;
    //                         this.complianceChecker.setConnectedEntities(
    //                             this.globalsService.brands,
    //                             this.globalsService.profile['id']
    //                         );

    //                         this.updateLists();

    //                         this.toastService.success(
    //                             this.alertMsgs['CONNECTED_SUCCESSFUL_MESSAGE']
    //                         );
    //                     });
    //             },
    //             (err) => {
    //                 //console.log(err);
    //                 this.toastService.error(
    //                     this.alertMsgs['CONNECTION_NOT_ESTABLISHED']
    //                 );
    //             }
    //         );
    // }

    // updateLists() {
    //     this.potentialPartner = [];

    //     this.connectedPartner = new MatTableDataSource<Partners>();

    //     for (const brand of this.globalsService.brands) {
    //         if (brand['sharedWith']) {
    //             let ownerID = '';
    //             let brandID = '';

    //             if (!(this.isBrand || this.isImporter)) {
    //                 // if factory

    //                 ownerID = this.globalsService.profile['id'];
    //                 //ownerID = this.globalsService.profile['organization_id']

    //                 brandID = brand['id'];
    //             } else {
    //                 //if brand logged in

    //                 ownerID = brand['id']; //logged in Brand

    //                 brandID = this.globalsService.profile['id'];

    //                 // brandID = this.globalsService.profile['organization_id'];
    //             }

    //             this.http
    //                 .post<any>(
    //                     this.globalsService.baseHref + '/user/getSupplierID',

    //                     { brand: brandID, owner: ownerID }
    //                 )
    //                 .subscribe((object) => {
    //                     if (object['idValue'] !== undefined) {
    //                         brand.idName = object['idName'];
    //                         brand.idValue = object['idValue'];
    //                     } else {
    //                         brand.idValue = '';
    //                     }
    //                 });

    //             if (this.isBrand || this.isImporter) {
    //                 this.http
    //                     .post<any>(
    //                         this.globalsService.baseHref +
    //                         '/user/getSupplierID',
    //                         {
    //                             brand: 'Higg',
    //                             owner: ownerID,
    //                         }
    //                     )
    //                     .subscribe((object) => {
    //                         if (object['idValue'] !== undefined) {
    //                             brand.higgID = object['idValue'];
    //                         } else {
    //                             brand.higgID = '';
    //                         }
    //                     });
    //                 this.http
    //                     .post<any>(
    //                         this.globalsService.baseHref +
    //                         '/user/getSupplierID',
    //                         {
    //                             brand: 'OAR',
    //                             owner: ownerID,
    //                         }
    //                     )
    //                     .subscribe((object) => {
    //                         if (object['idValue'] !== undefined) {
    //                             brand.oarID = object['idValue'];
    //                         } else {
    //                             brand.oarID = '';
    //                         }
    //                     });

    //                 this.http
    //                     .post<any>(
    //                         this.globalsService.baseHref +
    //                         '/user/getSupplierID',
    //                         {
    //                             brand: 'FCC',
    //                             owner: ownerID,
    //                         }
    //                     )
    //                     .subscribe((object) => {
    //                         if (object['idValue'] !== undefined) {
    //                             brand.fccID = object['idValue'];
    //                         } else {
    //                             brand.fccID = '';
    //                         }
    //                     });
    //             }

    //             if (!(brand.id == this.globalsService.profile['id'])) {
    //                 this.connectedPartner.data.push(brand);
    //             }
    //             // this.connectedPartner.data.push(brand);

    //             this.selectedBrands.push(brandID);
    //             this.selectTableView();
    //         } else {
    //             if (brand.name != 'ASOS')
    //                 this.potentialPartner.push(brand);
    //         }
    //     }
    //     //    //console.log(this.connectedPartner.data);
    //     this.connectedPartner.data.sort(function (a, b) {
    //         var x = a.name.toLowerCase();
    //         var y = b.name.toLowerCase();
    //         return x < y ? -1 : x > y ? 1 : 0;
    //     });

    //     this.potentialPartner.sort(function (a, b) {
    //         var x = a.name.toLowerCase();
    //         var y = b.name.toLowerCase();
    //         return x < y ? -1 : x > y ? 1 : 0;
    //     });
    //     this.connectedPartner.paginator = this.paginator;
    // }

    // checkValidity() {
    //     const result =
    //         this.potentialPartner.filter(
    //             (item) => item.name === this.searchPartner
    //         ).length < 1;
    //     return result;
    // }

    changeSupplierIDWalmart(brandID: String, idName: String, idValue: String) {
        if (this.isWalmartIDPatternValid(idValue)) {
            this.changeSupplierID(brandID, idName, idValue);
        } else {
            this.toastService.error(this.alertMsgs['ENTER_VALID_WALMART_ID']);
        }
    }

    changeSupplierID(brandID: String, idName: String, idValue: String) {
        // this.showSuccessChange = false;
        //        this.showSuccessDisconnect = false;
        //this.showSuccessConnect = false;
        // this.showProfileSaved = false;

        if (!this.checkNullorEmpty(idValue)) {
            this.http
                .post<any>(this.globalsService.baseHref + '/user/supplierID', {
                    brandID: brandID,
                    idName: idName,
                    idValue: idValue,
                })
                .subscribe(
                    (object) => {
                        this.toastService.success(
                            this.alertMsgs['ID_SAVE_SUCCESSFUL_MESSAGE']
                        );
                        // this.showSuccessChange = true;
                    },
                    (err) => {
                        //console.log(err);
                        this.toastService.error(this.alertMsgs['ERROR_COULD_NOT_SAVE_ID'])
                    }
                );
        } else if (this.checkNullorEmpty(idValue)) {
            //If ID value is Null
            this.http
                .post<any>(
                    this.globalsService.baseHref + '/user/deleteSupplierID',
                    {
                        brandID: brandID,
                        idName: idName,
                        idValue: idValue,
                    }
                )
                .subscribe(
                    (object) => {
                        this.toastService.success(
                            this.alertMsgs['ID_SAVE_SUCCESSFUL_MESSAGE']
                        );
                        // this.showSuccessChange = true;
                    },
                    (err) => {
                        //console.log(err);
                        this.toastService.error(this.alertMsgs['ERROR_COULD_NOT_SAVE_ID'])
                    }
                );
        } else {
            this.toastService.error(this.alertMsgs['USE_PATTERN'])
        }
    }

    isWalmartIDPatternValid(idValue) {
        //return (idValue.match("^[a-zA-Z]{2}[0-9]{5}$"));

        if (idValue.match('^[a-zA-Z]{2}[0-9]{5}$')) {
            return true;
        } else {
            return false;
        }
    }

    private checkNullorEmpty(arg: any) {
        if (arg === null || arg === undefined || arg === '') {
            return true;
        } else {
            return false;
        }
    }

    // selectTableView() {
    //     // if ((this.isBrand || this.isImporter) && !this.currentBrandWalmart()) {

    //     // } else if (
    //     //     (this.isBrand || this.isImporter) &&
    //     //     this.currentBrandWalmart()
    //     // ) {


    //     // } else
    //     if (this.isWalmartConnected()) {
    //         // factory is connected to walmart

    //         this.displayedMessageAndNotificationColumns = [
    //             'Name',
    //             'Role',
    //             'Status',
    //             'ID',
    //             'walmartMillId',
    //             'Actions',
    //         ]
    //     } else {

    //         this.displayedMessageAndNotificationColumns = [
    //             'Name',
    //             'Role',
    //             'Status',
    //             'ID',
    //             'Actions',
    //         ]
    //     }
    // }

    getZDHCSubscriptionStatus() {
        const data = { userId: this.globalsService.profile['id'] };
        // const data = {userId: this.globalsService.profile['organization_id']};

        this.http
            .post<any>(
                this.globalsService.bhiveIncheckServiceUrl +
                '/subscription/getStatus',
                data
            )
            .subscribe(
                (status) => {
                    this.zdhcSubscription = status['status'] === 'Valid';
                    this.zdhcPending = status['status'] === 'Pending';
                    this.zdhcApply = status['status'] === 'None';
                    this.zdhcSubscriptionStatus = status['status'];
                    if (status['status'] === 1) {
                        this.zdhcSubscriptionStatus = 'Pending';
                        this.zdhcPending = true;
                    } else if (status['status'] === 2) {
                        this.zdhcSubscriptionStatus = 'Active';
                        this.zdhcSubscription = true;
                    } else if (status['status'] === 3) {
                        this.zdhcSubscriptionStatus =
                            'Expired. (Please contact us to apply for renewal)';
                        this.zdhcSubscription = true;
                    } else if (status['status'] === 4) {
                        this.zdhcSubscriptionStatus =
                            'Declined. (Please contact BHive, if you want to apply)';
                        this.zdhcSubscription = true;
                    } else {
                        this.zdhcApply = true;
                    }

                    this.zdhcExpiration = status['endDate'];
                    this.organizationName = status['gatewayOrgName'];
                    this.applicationDate = status['createdOn'];
                    this.referenceNumber = status['referenceNumber'];
                },
                (err) => {
                    if (err['status'] == '404') {
                        this.zdhcApply = true;
                    }
                    //console.log(err);
                }
            );
    }






    // initWalMartIdForm() {
    //   return this._formBuilder.group({
    //     partnerIdValue: ['', Validators.nullValidator],

    //   })
    // }
    // get getWalmartIdControl(): FormArray {
    //   return this.walMartIdForm.get("walmartIds") as FormArray
    // }
    // addItem() {

    //   const ctrl = this.walMartIdForm.get('walmartIds') as FormArray;
    //   ctrl.push(this.initWalMartIdForm());
    // }
    // deleteItem(index, formValues) {

    //   let idExist = this.partnerIdValue.find(idValue => idValue.partnerIdValue === formValues.value.partnerIdValue);
    //   const index1 = this.partnerIdValue.indexOf(formValues.value);

    //   if (index1 !== -1) {
    //     this.partnerIdValue.splice(index1, 1);
    //   }

    //   const ctrl = this.walMartIdForm.get('walmartIds') as FormArray;
    //   ctrl.removeAt(index);

    // }

    // valueChange(formValue) {
    //   formValue.walmartIds.forEach(element => {

    //     let idExist = this.partnerIdValue.find(idValue => idValue.partnerIdValue === element.partnerIdValue);

    //     if (!idExist) {
    //       this.partnerIdValue.push(element)
    //     }

    //   }
    //   )
    //   //console.log(this.partnerIdValue)
    // }

    initWalmartBusinessIdForm() {
        this.walMartBusinessIdForm = this._formBuilder.group({
            walmartBusinessIds: this._formBuilder.array([
                this.initWalBusinessIdForm(),
            ]),
        });
        (
            this.walMartBusinessIdForm.controls.walmartBusinessIds as FormArray
        ).reset(); //reset the FormArray

        this.businessIds = this.globalsService.profile['bussinessPartnerIds'];
        //console.log(this.globalsService.profile['bussinessPartnerIds']);

        this.deleteBusinessId(0);

        if (this.businessIds != null) {
            this.businessIds.forEach((x) => {
                (
                    this.walMartBusinessIdForm.controls
                        .walmartBusinessIds as FormArray
                ).push(
                    this._formBuilder.group({
                        id: [x.id, Validators.nullValidator],
                        partnerIdValue: [
                            x.partnerIdValue,
                            Validators.nullValidator,
                        ],
                    })
                );
            });
        }
    }

    initWalmartVendorForm() {
        this.walMartVendorIdForm = this._formBuilder.group({
            walmartVendorIds: this._formBuilder.array([
                this.initWalmartVendorIdForm(),
            ]),
        });

        (
            this.walMartVendorIdForm.controls.walmartVendorIds as FormArray
        ).reset(); //reset the FormArray

        this.vendorPartnerIds = this.globalsService.profile['vendorPartnerIds'];
        //console.log(this.globalsService.profile['vendorPartnerIds']);
        this.deleteVendorId(0);

        if (this.vendorPartnerIds != null) {
            this.vendorPartnerIds.forEach((x) => {
                (
                    this.walMartVendorIdForm.controls
                        .walmartVendorIds as FormArray
                ).push(
                    this._formBuilder.group({
                        id: [x.id, Validators.nullValidator],
                        vendorIdValue: [
                            x.partnerIdValue,
                            Validators.nullValidator,
                        ],
                    })
                );
            });
        }
    }

    initWalBusinessIdForm() {
        return this._formBuilder.group({
            id: [, Validators.nullValidator],
            partnerIdValue: ['', Validators.nullValidator],
        });
    }

    initWalmartVendorIdForm() {
        return this._formBuilder.group({
            id: [, Validators.nullValidator],
            vendorIdValue: ['', Validators.nullValidator],
        });
    }

    get getBusinessIdControl(): FormArray {
        return this.walMartBusinessIdForm.get(
            'walmartBusinessIds'
        ) as FormArray;
    }

    get getVendorIdControl(): FormArray {
        return this.walMartVendorIdForm.get('walmartVendorIds') as FormArray;
    }

    addBusinessId() {
        const ctrl = this.walMartBusinessIdForm.get(
            'walmartBusinessIds'
        ) as FormArray;
        ctrl.push(this.initWalBusinessIdForm());
    }

    addVendorId() {
        const ctrl = this.walMartVendorIdForm.get(
            'walmartVendorIds'
        ) as FormArray;
        ctrl.push(this.initWalmartVendorIdForm());
    }

    deleteBusinessId(index) {
        const ctrl = this.walMartBusinessIdForm.get(
            'walmartBusinessIds'
        ) as FormArray;
        ctrl.removeAt(index);
    }

    deleteVendorId(index) {
        const ctrl = this.walMartVendorIdForm.get(
            'walmartVendorIds'
        ) as FormArray;
        ctrl.removeAt(index);
    }

    deleteBusinessPartner(businessPartner) {
        if (businessPartner.value.id != null) {
            this.http
                .get(
                    this.globalsService.baseHref +
                    '/deleteBusinessPartnerId/' +
                    businessPartner.value.id
                )
                .subscribe(
                    (response) => {
                        // this.deleteBusinessIdMessage = true;
                        this.toastService.success(
                            this.alertMsgs['BUSINESS_ID_DELETED_SUCCESSFULLY']
                        );
                        this.refreshProfile();
                        // setTimeout(() => {
                        //     this.deleteBusinessIdMessage = false;
                        // }, 3000);
                    },
                    (error) => {
                        // if (
                        //     error['status'] == '404' ||
                        //     error['status'] == '500' ||
                        //     error['status'] == '401'
                        // ) {
                        //     alert(
                        //         'The action could not be completed. Please try again later.If the problem persists please contact Bhive team.'
                        //     );
                        // }
                    }
                );
        }
    }

    deleteVendor(vendorID, index, size) {
        if (size > 1) {
            if (vendorID.value.id != null) {
                this.http
                    .get(
                        this.globalsService.baseHref +
                        '/deleteVendorId/' +
                        vendorID.value.id
                    )
                    .subscribe(
                        (response) => {
                            //     this.deleteVendorIdMessage = true;

                            this.toastService.success(
                                this.alertMsgs['VENDOR_ID_DELETED_SUCCESSFULLY']
                            );
                            this.deleteVendorId(index);
                            this.refreshProfile();
                            // setTimeout(() => {
                            //     this.deleteVendorIdMessage = false;
                            // }, 3000);
                        },
                        (error) => {
                            // if (
                            //     error['status'] == '404' ||
                            //     error['status'] == '500' ||
                            //     error['status'] == '401'
                            // ) {
                            //     alert(
                            //         'The action could not be completed. Please try again later.If the problem persists please contact Bhive team.'
                            //     );
                            // }
                        }
                    );
            }
        } else {
            // this.deleteLastVendorIdMessage = true;
            this.toastService.success(
                this.alertMsgs['LAST_VENDOR_ID_DELETE_MESSAGE']
            );
            this.refreshProfile();
            // setTimeout(() => {
            //     this.deleteLastVendorIdMessage = false;
            // }, 3000);
        }
    }

    addUpdateBusinessId(formValues) {
        let data = {};
        let isDataEmpty: boolean = true;

        formValues.walmartBusinessIds.forEach((element) => {
            if (this.businessIds == null) {
             //   console.info(element)
                if (element.partnerIdValue.length != 8) {
                    this.toastService.error(this.alertMsgs['PLEASE_ENTER_BUSINESS_PARTNER_ID'])
                    return
                }
                data = element;
                isDataEmpty = false;
            } else {
                let idExist = this.businessIds.find(
                    (idValue) =>
                        idValue.partnerIdValue === element.partnerIdValue
                );

                if (!idExist && element.partnerIdValue.length == 8) {
                    data = element;
                    isDataEmpty = false;
                } else if (!idExist && element.partnerIdValue.length != 8) {
                    this.toastService.error(this.alertMsgs['PLEASE_ENTER_BUSINESS_PARTNER_ID'])
                }
            }
        });

        if (!isDataEmpty) {
            this.http
                .post<any>(
                    this.globalsService.baseHref +
                    '/addUpdateBusinessPartnerId',
                    data
                )
                .subscribe(
                    (response) => {
                        if (response) {
                            data = null;
                        }
                        //  this.businessIdAddedMessage = true;
                        this.toastService.success(
                            this.alertMsgs['BUSINESS_ID_ADDED_SUCCESSFULLY']
                        );
                        this.refreshProfile();
                        // setTimeout(() => {
                        //     this.businessIdAddedMessage = false;
                        // }, 3000);
                    },
                    (err) => {
                        //console.log(err);
                        this.toastService.error('Error: Could not save the new ID.');
                    }
                );
        }
    }

    addUpdateVendorId(formValues) {
        //console.log(formValues);
        let data = {};
        let isDataEmpty: boolean = true;

        formValues.walmartVendorIds.forEach((element) => {
            if (this.vendorPartnerIds == null) {
                data = element;
                isDataEmpty = false;
            } else {
                let idExist = this.vendorPartnerIds.find(
                    (idValue) => idValue.vendorIdValue === element.vendorIdValue
                );

                if (!idExist && element.vendorIdValue.length == 6) {
                    data = element;
                    isDataEmpty = false;
                } else if (!idExist && element.vendorIdValue.length != 6) {
                    this.toastService.error(this.alertMsgs['PLEASE_ENTER_VENDOR_PARTNER_ID'])
                    //  alert('Please enter 6 digit vendor partner ID');
                }
            }
        });

        if (!isDataEmpty) {
            this.http
                .post<any>(
                    this.globalsService.baseHref + '/addUpdateVendorId',
                    data
                )
                .subscribe(
                    (response) => {
                        if (response) {
                            data = null;
                        }
                        //this.vendorIdAddedMessage = true;
                        this.toastService.success(
                            this.alertMsgs['VENDOR_ID_ADDED_SUCCESSFULLY']
                        );
                        this.refreshProfile();
                        // setTimeout(() => {
                        //     this.vendorIdAddedMessage = false;
                        // }, 3000);
                    },
                    (err) => {
                        //console.log(err);
                        alert('Error: Could not save the new vendor ID.');
                    }
                );
        }
    }

    checkWalmartVendorIdLength(value) {
        if (!/^[0-9]+$/.test(value)) {
            this.toastService.error(this.alertMsgs['PLEASE_6_DIGIT_VENDOR_ID'])
        }
    }

    checkWalmartIdLength(value) {
        if (!/^[0-9]+$/.test(value)) {
            this.toastService.error('Please enter 8 digit business partner ID');
        }
    }

    checkWalmartId(value) {
        this.walmartSupplierIdValid = false;
        if (!/^[0-9]+$/.test(value)) {
            this.toastService.error(
                'Please enter 8 digit business partner ID (Allowed input:0-9)'
            );
        }
        if (value.toString().length > 7) {
            this.walmartSupplierIdValid = true;
        }
    }

    checkWalmartVendorId(value) {
        this.walmartVendorIdValid = false;
        if (!/^[0-9]+$/.test(value)) {
            this.toastService.error(this.alertMsgs['PLEASE_ENTER_BUSINESS_PARTNER_ID_0_TO_9'])
        }
        if (value.toString().length > 5) {
            this.walmartVendorIdValid = true;
        }
    }

    validateEmail() {
        if (this.forms.get('emailControl').valid) {
            const value = this.forms.get('emailControl').value;
            this.http
                .post(this.globalsService.baseHref + '/checkMail', {
                    mail: value,
                })
                .subscribe((res) => {
                    if (res['status'] !== 'SUCCESS') {
                        this.forms.get('emailControl').setErrors({
                            emailTaken: true,
                        });
                    }
                });
        }
    }

    getErrorMessage() {
        if (this.forms.controls['emailControl'].hasError('required')) {
            return 'You must enter a value';
        }

        return this.forms.controls['emailControl'].hasError('email')
            ? 'Not a valid email'
            : '';
    }


    isWalmartConnected(): Boolean {
        // let exist = this.connectedPartner.data.find(
        //     (el) =>
        //         el.name.toLocaleLowerCase() === 'Walmart'.toLocaleLowerCase()
        // );

        // if (exist) {
        //     return true;
        // }

        // return false;
        return GlobalsService.iswalmartSupplier;
    }

    refreshProfile() {
        //console.log(this.globalsService.profile);
        this.globalsService.whoAmI().subscribe(
            (response) => {
                this.globalsService.profile = response;

                this.userProfile =
                    this.globalsService.profile['organization_type'] == 1 ||
                    this.globalsService.profile['organization_type'] == 5;

                GlobalsService.iswalmartSupplier = this.globalsService.profile['supplierOfWalmart'];
                let data: any = response;
                this.factoryActivites = data.factoryActivities;
                this.methodCallsOnInit();
                //  this.initWalmartBusinessIdForm();
                //  this.initWalmartVendorForm();
                //  //console.log(this.globalsService.profile);
            },

            (err) => {
                //console.log(err);
                this.loading = false;
            }
        );
    }

    currentBrandWalmart(): boolean {
        if (
            this.globalsService.profile[
                'organization_name'
            ].toLocaleLowerCase() === 'walmart'
        ) {
            return true;
        } else {
            return false;
        }
    }

    addWalmartVendorIds(formValue) {
        let idExist = this.vendorIdValue.find(
            (idValue) => idValue.vendorIdValue === formValue.vendorIdValue
        );
        //console.log(formValue);
        if (!idExist) {
            this.vendorIdValue.push(formValue);
            this.walmartVendorIdValid = true;
        }

        //console.log(this.vendorIdValue);
        this.walMartIdForm.reset();
        // Add walmart id and then reset form automatically and add another value to array
    }

    addWalmartIds(formValue) {
        let idExist = this.partnerIdValue.find(
            (idValue) => idValue.partnerIdValue === formValue.partnerIdValue
        );
        //console.log(formValue);
        if (!idExist) {
            this.partnerIdValue.push(formValue);
            this.walmartSupplierIdValid = true;
        }

        //console.log(this.partnerIdValue);
        this.walMartIdForm.reset();
        // Add walmart id and then reset form automatically and add another value to array
    }

    removeWalmartVendorId(formValue) {
        //console.log(formValue);
        const index1 = this.vendorIdValue.indexOf(formValue);
        //console.log(index1);
        if (index1 !== -1) {
            this.vendorIdValue.splice(index1, 1);
        }
        if (this.vendorIdValue.length == 0) {
            this.walmartVendorIdValid = false;
        }
        //console.log(this.vendorIdValue);
    }

    removeWalmartId(formValue) {
        //console.log(formValue);
        const index1 = this.partnerIdValue.indexOf(formValue);
        //console.log(index1);
        if (index1 !== -1) {
            this.partnerIdValue.splice(index1, 1);
        }
        if (this.partnerIdValue.length == 0) {
            this.walmartSupplierIdValid = false;
        }
        //console.log(this.partnerIdValue);
    }

    addFile(files: FileList) {
        ////console.log(files);
        let file: File = files[0];
        //console.info(file);
        let uploadTypes = ['pdf'];

        let nameArray = file.name.split('.');
        let type = nameArray[nameArray.length - 1];

        this.document = file;
    }

    getBusinessLicenseDocument() {
        let formData = {};
        this.http
            .post<any>(
                this.globalsService.baseHref + '/getBusinessLicenseDocument',
                formData
            )
            .subscribe(
                (response) => {
                    if (response) {
                        this.businessLicenseData = response.data[0];
                    }
                },
                (err) => {
                    //  this.isLoading = false;
                    // alert(
                    //   "There was an error while getting business license. Please try again later."
                    // );
                }
            );
    }
    deleteBusinessLicense() {
        let formData = {
            documentID: this.businessLicenseData.id,
        };
        this.http
            .post<any>(
                this.globalsService.baseHref + '/deleteDocument',
                formData
            )
            .subscribe(
                (response) => {
                    if (response) {
                        this.businessLicenseData = null;
                    }
                },
                (err) => {
                    //  this.isLoading = false;
                    this.toastService.error(this.alertMsgs['ERROR_DELETE_BUSINESS_LICENSE'])
                }
            );
    }

    downloadBusinessLicense() {
        this.http
            .get(
                this.globalsService.baseHref +
                '/getDocument/' +
                this.businessLicenseData.id,
                { responseType: 'blob' }
            )
            .subscribe(
                (response) => {
                    let blob = new Blob([response], {
                        type: 'application/pdf',
                    });

                    var downloadURL = window.URL.createObjectURL(blob);
                    window.open(
                        downloadURL,
                        'Document',
                        'toolbar=0,location=0,menubar=0'
                    );
                },
                (err) => {
                    //console.log(err);
                    alert('Error: Could not download document!');
                }
            );
    }
    fetchTranslations() {
        this.translateService
            .get('PROFILE_COMPONENT.MESSAGES')
            .subscribe((response) => {
                this.alertMsgs = response;
                //console.log(this.alertMsgs);
            });
    }

    uploadBusinessLicense() {
        // else if (!this.issueDate) {
        //   alert(this.alertMsgs["ISSUE_DATE"]);
        // } else if (!this.expiryDate) {
        //   alert(this.alertMsgs["EXPIRY_DATE"]);
        // }

        if (this.document == null || this.document == undefined) {
            alert(this.alertMsgs['FILE_SELECT']);
        } else if (this.document.name == '') {
            alert(this.alertMsgs['DOCUMENT_NAME']);
        } else {
            // this.isLoading = true;
            let formData = new FormData();

            formData.append('file', this.document);
            formData.append('name', this.document.name);
            formData.append(
                'issue',
                this.issueDate == null ? null : this.issueDate.getTime() + ''
            );
            formData.append(
                'expiry',
                this.issueDate == null ? null : this.expiryDate.getTime() + ''
            );

            this.http
                .post<any>(
                    this.globalsService.baseHref +
                    '/uploadBusinessLicenseDocument',
                    formData
                )
                .subscribe(
                    (response) => {
                        if (response) {
                            this.toastService.success(this.alertMsgs['BUSINESS_LICENSE_UPLOAD_SUCCESS'])

                            this.getBusinessLicenseDocument();
                        }
                    },
                    (err) => {
                        //  this.isLoading = false;
                        this.toastService.error(this.alertMsgs['ERROR_WHILE_UPLOAD'])
                    }
                );
        }
    }

    goToGatewaySubscription() {
        this.router.navigateByUrl('/gateway-subscription')
    }

    // onAcceptDeclineConnectEvent(brandConnectionRow) {

    //     //Accept -Add connection
    //     if (brandConnectionRow.isAccept) {
    //         this.vendorPartnerIds = []


    //         if (brandConnectionRow.brandUniqueId === 'WALMART_BHIVE_ID') {
    //             if (brandConnectionRow.vendorList == null || brandConnectionRow.vendorList == undefined || brandConnectionRow.vendorList.length == 0) {
    //                 this.toastService.error("Please provide walmart vendor ID")
    //                 return
    //             } else {
    //                 brandConnectionRow.vendorList.forEach(element => {
    //                     this.vendorPartnerIds.push({ "vendorIdValue": element })
    //                 });
    //             }
    //         }
    //         this.connectHttpCallRequest(brandConnectionRow.requestFromId, this.partnerIdValue, this.vendorPartnerIds, true, brandConnectionRow)
    //     }
    //     else {
    //         //Decline connection
    //         this.brandConnectAccept(brandConnectionRow)
    //     }
    // }






    // brandConnectAccept(data) {
    //     this.brandFactoryConnectionService.brandConnectionRequestUpdate(data).subscribe(
    //         (response) => {
    //             this.toastService.success("Connection request updated successfully.")
    //             this.supplierConnectionRequestCount()

    //         },
    //         (error) => {

    //         }
    //     );
    // }




     


    // @ Count the Supplier Connection Request Method

    supplierList: any = [];
    page = 0;
    pageSize = 10;
    suppliers;
    displayedBrandConnColumns: string[] = [
        'BrandName',
        // 'Role',
        'Supplier ID',
        'Walmart Mill ID',
        'vendorId',
        'Date Of Request',
        'Action'
    ];
    dataSource: MatTableDataSource<any> = null;

    //@ViewChild(MatPaginator) paginatorBrandConn: MatPaginator;
    // @ViewChild('paginatorBrandConn') paginatorBrandConn: MatPaginator;
    // @ViewChild(MatSort) sort: MatSort;


    // supplierConnectionRequestList() {
    //     let data = {
    //         requestTo: this.globalsService.profile['id'],
    //         page: this.page,
    //         pageSize: this.pageSize
    //     }
    //     this.brandFactoryConnectionService.receivedConnectionRequestList(data).subscribe(
    //         (response) => {
    //             this.suppliers = response;
    //             this.dataSource = new MatTableDataSource(this.suppliers);
    //             //  this.dataSource.paginator = this.paginatorBrandConn
    //         },
    //         (error) => {
    //             return 0;
    //         }
    //     );
    // }

    // onPageChange(event) {
    //     this.page = this.paginatorBrandConn.pageIndex,
    //         this.pageSize = this.paginatorBrandConn.pageSize
    //     this.supplierConnectionRequestList();
    // }







    addVendor(row) {
        if (row.vendorList == null || row.vendorList == undefined)
            row.vendorList = [];
        row.vendorList.push(row.vendorIdInput)
    }

    removeVendor(row, vendorNumber) {
        row.vendorList = row.vendorList.filter(v => v != vendorNumber)
    }







    // updateLists() {
    //     this.brandFactoryConnectionService.commonSubject$.next({ reLoadConnection: true })
    // }
}
